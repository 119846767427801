<template>
  <div class="mt-4">
    <v-row no-gutters>
      <v-col cols="12" md="3" class="mr-4"
        ><Prd-text-field
          v-model="model.MinRange"
          :title="$t('TXT_MIN_RANGE')"
          :type="'number'"
          v-mask="'#'"
          hide-spin-buttons
      /></v-col>
      <v-col cols="12" md="3" class="mr-4"
        ><Prd-text-field
          v-model="model.MaxRange"
          :title="$t('TXT_MAX_RANGE')"
          :type="'number'"
          v-mask="'#'"
          hide-spin-buttons
      /></v-col>
      <v-col cols="12" md="3" class="mr-4"
        ><Prd-text-field
          v-model="model.SetValue"
          :title="$t('TXT_VALUE')"
          :type="'number'"
          v-mask="'#'"
          hide-spin-buttons
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  props: ["values"],
  components: { PrdTextField },
  data() {
    return {
      model: {
        MinRange: 0,
        MaxRange: 0,
        SetValue: 0,
      },
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "SetValue",
          decimalValue: inputs.SetValue ? parseFloat(inputs.SetValue) : 0,
          booleanValue: false,
        },
        {
          stringValue: "MinRange",
          decimalValue: inputs.MinRange ? parseFloat(inputs.MinRange) : 0,
          booleanValue: false,
        },
        {
          stringValue: "MaxRange",
          decimalValue: inputs.MaxRange ? parseFloat(inputs.MaxRange) : 0,
          booleanValue: false,
        },
      ];

      this.$emit("updateConditionValues", { formatedValue, conditionType: 13 });
    },

    setInputs(conditionList) {
      const SetValue = conditionList.find(
        (condition) => condition.stringValue == "SetValue"
      ).decimalValue;
      this.model.SetValue = SetValue;

      const MinRange = conditionList.find(
        (condition) => condition.stringValue == "MinRange"
      ).decimalValue;
      this.model.MinRange = MinRange;

      const MaxRange = conditionList.find(
        (condition) => condition.stringValue == "MaxRange"
      ).decimalValue;
      this.model.MaxRange = MaxRange;
    },
  },

  created() {
    if (this.values.length > 0) this.setInputs(this.values);
  },
};
</script>

<style></style>
