import { render, staticRenderFns } from "./search-main.vue?vue&type=template&id=637bcdf4&scoped=true"
import script from "./search-main.vue?vue&type=script&lang=js"
export * from "./search-main.vue?vue&type=script&lang=js"
import style0 from "./search-main.vue?vue&type=style&index=0&id=637bcdf4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637bcdf4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VRow})
