import { render, staticRenderFns } from "./carousel-item.vue?vue&type=template&id=20b76c67&scoped=true"
import script from "./carousel-item.vue?vue&type=script&lang=js"
export * from "./carousel-item.vue?vue&type=script&lang=js"
import style0 from "./carousel-item.vue?vue&type=style&index=0&id=20b76c67&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b76c67",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCarousel,VCarouselItem,VRow,VSpacer})
