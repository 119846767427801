import { render, staticRenderFns } from "./password-recovery-main.vue?vue&type=template&id=2b004191&scoped=true"
import script from "./password-recovery-main.vue?vue&type=script&lang=js"
export * from "./password-recovery-main.vue?vue&type=script&lang=js"
import style0 from "./password-recovery-main.vue?vue&type=style&index=0&id=2b004191&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b004191",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
