<template>
  <v-dialog v-model="isOpen" width="600">
    <v-card class="pa-4">
      <v-row no-gutters justify="center">
        <v-icon size="50" :color="$prdStyles('color-primary')">
          mdi-email-fast-outline
        </v-icon>
      </v-row>
      <h3 class="mb-4 text-center">{{ $t(titleKey) }}</h3>
      <p class="mb-2">
        {{ $t("TXT_LARGE_DATA_ALERT") }}
      </p>
      <Prd-text-field
        :title="$t('TXT_CONFIRM_EMAIL')"
        v-model="email"
        class="mb-2"
      />
      <v-row no-gutters justify="end" class="gap-4">
        <Prd-btn
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          @click="isOpen = false"
        />
        <Prd-btn
          :disabled="email == null"
          :title="$t('TXT_CONFIRM')"
          @click="hadleSend"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  components: {
    PrdTextField,
    PrdBtn,
  },
  data() {
    return {
      isOpen: false,
      email: null,
      type: null,
    };
  },
  computed: {
    loggedEmail() {
      return this.$store?.getters?.userData?.email ?? null;
    },
    titleKey() {
      return this.type == "product"
        ? "TXT_PRODUCT_REPORT"
        : "TXT_PRODUCT_HISTORY";
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        this.email = value ? this.loggedEmail : null;
        if (!value) this.type = null;
      },
      immediate: true,
    },
  },
  methods: {
    open(type) {
      this.isOpen = true;
      this.type = type;
    },
    hadleSend() {
      this.isOpen = false;
      this.$emit("getFile", { type: this.type, email: this.email });
    },
  },
};
</script>
