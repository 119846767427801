<template>
  <div class="pa-4">
    <v-row no-gutters align="center" class="mb-4">
      <h4 class="mr-1">{{ $t("TXT_HISTORY_COMPETITIVENESS") }}</h4>
      <Prd-tooltip :text="$t('TXT_HISTORY_GRAPHIC_COMPETITIVENESS')" />
    </v-row>

    <Filter-main
      :isLoading="isLoading"
      :rawData="rawData"
      @changedTab="activeView = $event"
      @selectedProduct="handleSelectedProduct"
      class="mb-4"
    />
    <Chart-main
      v-show="activeView == 0"
      :isLoading="isLoading"
      :rawData="rawDataForChart"
    />
    <Table-main ref="table" v-show="activeView == 1" :isLoading="isLoading" />
  </div>
</template>

<script>
import PrdTooltip from "@/components/common/prd-tooltip.vue";
import FilterMain from "./components/filter-main.vue";
import TableMain from "./components/table-main.vue";
import ChartMain from "./components/chart-main.vue";
export default {
  props: {
    isLoading: { type: Boolean, default: false },
    rawData: { type: Object, default: () => ({}) },
  },
  components: {
    PrdTooltip,
    FilterMain,
    TableMain,
    ChartMain,
  },
  data() {
    return {
      activeView: 0,
      rawDataForChart: null,
    };
  },
  watch: {
    rawData: {
      handler(value) {
        this.rawDataForChart = structuredClone(value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleSelectedProduct(name) {
      this.$emit("selectedProduct", name);

      if (this.$refs.table) this.$refs.table.fillItems(name);
      this.filterRawDataForChart(name);
    },
    filterRawDataForChart(name) {
      if (!this.rawData) return;
      if (!name) this.rawDataForChart = structuredClone(this.rawData);
      else {
        const copyModifiedRawData = structuredClone(this.rawData);

        copyModifiedRawData.competitivenessIndexHistory.products =
          copyModifiedRawData.competitivenessIndexHistory.products.filter(
            (item) => item.name == name
          );
        this.rawDataForChart = copyModifiedRawData;
      }
    },
  },
};
</script>
