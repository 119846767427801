<template>
  <v-container fluid>
    <v-row no-gutters justify="space-between" align="center">
      <Title
        :title="$t('TXT_SYSTEM_GENERAL_SETTINGS').toUpperCase()"
        :breadCrumbs="breadCrumbs"
        :hasTooltip="false"
      />
      <Prd-btn
        v-if="false"
        :leftIcon="true"
        :icon="'mdi-arrow-left'"
        :title="$t('TXT_BACK')"
      />
    </v-row>
    <Select-company @selectedCompany="selectedCompany = $event" />
    <v-card class="pa-4 mt-4" v-if="selectedCompany != null">
      <v-tabs
        v-model="tab"
        color="black"
        :slider-color="$prdStyles('color-secundary')"
        height="30px"
        show-arrows
        center-active
      >
        <v-tab class="group-filters">{{ $t("TXT_GENERAL_CONFIGS") }}</v-tab>
        <v-tab class="group-filters">Predimonitor</v-tab>
        <v-tab class="group-filters" disabled>Pricer</v-tab>
      </v-tabs>

      <general-configs-main
        v-if="tab == 0"
        :selectedCompany="selectedCompany"
        @setNewLastPlan="selectedCompany.ultimoPlano = $event"
      />
      <predimonitor-configs-main
        v-if="tab == 1"
        :selectedCompany="selectedCompany"
      />
    </v-card>
  </v-container>
</template>

<script>
import Title from "@/Design_System/common/title.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import SelectCompany from "./components/select-company.vue";
import GeneralConfigsMain from "./components/general-configs/general-configs-main.vue";
import PredimonitorConfigsMain from "./components/predimonitor/predimonitor-configs-main.vue";
export default {
  components: {
    Title,
    PrdBtn,
    SelectCompany,
    GeneralConfigsMain,
    PredimonitorConfigsMain,
  },
  name: "GeneralSystemConfigs",

  data() {
    return {
      breadCrumbs: [{ text: this.$i18n.t("TXT_SYSTEM_GENERAL_SETTINGS") }],
      selectedCompany: null,
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}
</style>
