<template>
  <v-card class="pa-4" :disabled="isLoading">
    <h3 class="mb-4">{{ $t("TXT_CONFIGURATIONS").toUpperCase() }}</h3>
    <Prd-combo-box
      @input="handlerSelectedCompany"
      :title="$t('TXT_SELECT_COMPANY_2')"
      :placeholder="$t('TXT_SELECT')"
      :items="companyList"
      :loading="isLoading"
      :disabled="isLoading"
    />
  </v-card>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import GeneralSystemCongisService from "@/service/general-system-configs/index";
export default {
  components: { PrdComboBox },
  data() {
    return {
      companyList: [],
      service: new GeneralSystemCongisService(),
      isLoading: false,
      selectedCompany: {},
    };
  },
  methods: {
    async getAllCompanies() {
      this.isLoading = true;
      try {
        const response = await this.service.getAllCompanies();
        const mappedResponse =
          response?.data?.map((company) => {
            return {
              ...company,
              text: `${company.idEmpresa} - ${company.nomeRazaoSocial}`,
            };
          }) ?? [];
        this.companyList = mappedResponse;
      } catch (error) {
        this.$handleError(error, "Erro ao buscar empresas");
      } finally {
        this.isLoading = false;
      }
    },

    handlerSelectedCompany(data) {
      if (data.idEmpresa) this.$emit("selectedCompany", data);
    },
  },
  mounted() {
    this.getAllCompanies();
  },
};
</script>
