import ProductTableExternalPaginationStore from "./components/product-table-external-pagination-store";
import ProductTableInternalPaginationStore from "./components/product-table-internal-pagination-store";
import RequestViewerModalStore from "./components/request-viewer-modal-store";

const PredimonitorV3Store = {
  state: {
    lastRequest: null,
    refreshComponentsFlag: false,
  },
  getters: {},
  mutations: {
    setLastRequest(state, lastRequest) {
      state.lastRequest = lastRequest ?? null;
    },
    refreshComponents(state) {
      state.refreshComponentsFlag = !state.refreshComponentsFlag;
    },
  },
  actions: {},
  modules: {
    ProductTableExternalPaginationStore,
    ProductTableInternalPaginationStore,
    RequestViewerModalStore,
  },
};

export default PredimonitorV3Store;
