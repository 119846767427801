<template>
  <div id="title">
    <v-row class="view-title">{{ title }}</v-row>
    <v-breadcrumbs :items="breadCrumbs" class="pa-0 mx-0 mt-0 breadcrumb">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "titleBreadCrumbsComponent",
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      requiired: true,
    },
    breadCrumbs: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  #title *{
    font-family: "Ubuntu", sans-serif;
  }
.v-application ul {
  padding: 0 !important;
}
.v-breadcrumbs {
  padding: 0 !important;
  opacity: 0.5;
}
.v-breadcrumbs__item, .v-breadcrumbs li{
  font-size: $font-size-xxxs !important;
  color: $neutral-color-low-light !important;
}
.view-title {
  font-size: $font-size-md;
  font-weight: $font-weight-regular;
  color: $neutral-color-low-pure;
  margin: 24px 0px 0px 0px;
}
.breadcrumb {
  font-size: $font-size-xxxs;
  font-weight: $font-weight-regular;
  @extend .mb-xxs;
}
</style>