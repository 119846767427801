<template>
  <div>
    <v-row no-gutters v-if="hasMachineryResource">
      <v-col cols="4">
        <Prd-combo-box
          :items="yearList"
          :title="$t('TXT_YEARS')"
          :placeholder="$t('TXT_SELECT_ONE_OR_MORE_YEARS')"
          :label="$t('TXT_SELECT_ONE_OR_MORE_YEARS')"
          :loading="isLoading"
          :disabled="isLoading"
          multiple
          deletable-chips
          small-chips
          clearable
          @change="handleYears"
        />
      </v-col>
    </v-row>
    <v-row justify="space-between" align="center">
      <v-col cols="auto" v-for="tab in tabs" :key="tab.variable">
        <Prd-tabs
          :configs="tab"
          :isLoading="isLoading"
          @changedTab="
            $emit('changedTab', { variable: tab.variable, value: $event })
          "
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTabs from "@/components/common/prd-tabs.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
export default {
  props: {
    isLoading: { type: Boolean, default: false },
    rawData: { type: Object, default: () => ({}) },
  },
  components: {
    PrdTabs,
    PrdComboBox,
  },
  data() {
    return {
      tabs: [
        {
          title: this.$i18n.t("TXT_GROUP_BY"),
          centerActive: true,
          showArrows: true,
          tabs: [
            {
              name: this.$t("TXT_PRODUCT"),
              tooltip: this.$t("TXT_TEMPORAL_TOOLTIP_PRODUCT"),
            },
            {
              name: this.$t("TXT_ORIGIN"),
              tooltip: this.$t("TXT_TEMPORAL_TOOLTIP_ORIGIN"),
            },
            {
              name: this.$t("TXT_NETWORK"),
              tooltip: this.$t("TXT_TEMPORAL_TOOLTIP_RETAILER_2"),
            },
          ],
          variable: "activeGroup",
        },
        {
          title: this.$i18n.t("TXT_VISUALIZATION"),
          centerActive: true,
          showArrows: true,
          tabs: [
            { name: this.$t("TXT_LIST") },
            { name: this.$t("TXT_GRAPHIC") },
          ],
          variable: "activeView",
        },
      ],
      timer: null,
    };
  },
  computed: {
    yearList() {
      return this.rawData?.years ?? [];
    },
    hasMachineryResource() {
      return this.$store.getters.hasResource("marketplace.machinery");
    },
  },
  methods: {
    handleYears(years) {
      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.$emit("changeYears", years);
      }, 2000);
    },
  },
};
</script>
