<template>
  <Prd-btn
    :title="$t('TXT_SAVE')"
    :loading="isLoading"
    :disabled="!hasChanges"
    @click="setDisregardItem"
  />
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
export default {
  props: {
    isDisregardedType: { type: Boolean, default: true },
    list: { type: Array, default: () => [] },
  },
  components: {
    PrdBtn,
  },
  data() {
    return {
      isLoading: false,
      service: new PredimonitorService(),
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    changeList() {
      return this.list.filter(
        (item) => item.disregarded == this.isDisregardedType
      );
    },
    hasChanges() {
      return this.changeList.length > 0;
    },
  },
  methods: {
    async setDisregardItem() {
      this.isLoading = true;
      this.$emit("disableCheckbox", true);

      await Promise.all(
        this.changeList.map(({ priceIds, disregarded }) => {
          return Promise.all(
            priceIds.map((priceId) => {
              return this.callService(disregarded, priceId);
            })
          );
        })
      );

      this.$emit("refreshList");
      this.isLoading = false;
      this.$emit("disableCheckbox", false);
    },
    async callService(disregarded, priceId) {
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Produtos",
        name: "Atualizar Desconsiderado/Considerado",
        payload: `${this.idCompany} - ${disregarded} - ${priceId}`,
      });

      try {
        const response = await this.service.setDisregardItem(
          disregarded,
          this.idCompany,
          priceId
        );
        this.$store.commit("endRequest", { id: requestId, response });
        this.$store.commit("snackbarV2/set", {
          message: this.isDisregardedType
            ? this.$i18n.t("TXT_DISREGARDED_PRICES_SUCCESS")
            : this.$i18n.t("TXT_CONSIDERED_PRICES_SUCCESS"),
          type: "success",
        });
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(
          error,
          this.isDisregardedType
            ? this.$i18n.t("TXT_DISREGARDED_PRICES_ERROR")
            : this.$i18n.t("TXT_CONSIDERED_PRICES_ERROR")
        );
      }
    },
  },
};
</script>
