<template>
  <v-container fluid>
    <v-row
      v-if="isCreateRule"
      no-gutters
      align="center"
      justify="space-between"
    >
      <titleBreadCrumbsComponent
        :title="$t('TXT_DINAMIC_PRICING_RULE').toUpperCase()"
        :hasTooltip="false"
      />
      <router-link to="/main/pricing-rules">
        <Prd-btn :title="$t('TXT_BACK')" :icon="'mdi-arrow-left'" smallIcon />
      </router-link>
    </v-row>

    <v-card
      :disabled="createRuleLoading"
      :flat="!isCreateRule"
      :class="isCreateRule ? 'pa-4  ' : ''"
    >
      <Prd-loading-msg
        v-if="isLoading"
        :text="$t('TXT_LOADING_DATA_RULE_GROUP')"
        class="mt-4"
      />
      <div v-else>
        <h4 v-if="isCreateRule" class="mb-6">{{ $i18n.t("TXT_ADD_RULE") }}</h4>
        <Prd-Text-Field
          v-model="localRule.description"
          :title="$t('TXT_DESCRIPTION')"
          :placeholder="$t('TXT_INSERT_HERE_RULE')"
        />

        <div class="d-flex gap-4">
          <v-checkbox
            v-model="localRule.isAllProducts"
            :label="$t('TXT_SELECT_ALL_PRODUCTS_AND_CATEGORIES')"
            class="ma-0 pa-0 mt-4"
            hide-details
          />
          <v-checkbox
            v-model="localRule.enableMultGroups"
            :label="$t('TXT_ALLOW_TO_MULTIPLE_BRANCHS')"
            :disabled="ruleFiltersLoading || disableEnableMultGroups"
            @click="cleanChildrenList"
            class="ma-0 pa-0 mt-4"
            hide-details
          />
        </div>

        <Rule-filters
          :rule="localRule"
          :isCreateRule="isCreateRule"
          ref="ruleFilters"
        />

        <Rule-items-main
          :rule="localRule"
          :ruleList="ruleList"
          @handleChooseBranchs="handleChooseBranchs"
          @addRule="addRuleToConditions"
          @updateConditionValues="updateConditionsValue"
          @removeRuleFromList="removeRuleFromList"
          @switchRule="switchRule"
          @handleChangeRulePosition="handleChangeRulePosition"
          @removeBranch="removeBranch"
          @replicateBranch="replicateBranch"
        />

        <v-row no-gutters justify="end">
          <Prd-btn
            :disabled="createRuleLoading"
            :title="$t('TXT_CANCEL')"
            @click="setFieldOfRule(rule)"
            outlined
            class="mr-4"
          />
          <Prd-btn
            :disabled="createRuleLoading"
            :loading="createRuleLoading"
            :title="$t('TXT_CONFIRM')"
            @click="saveRule"
          />
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import RuleFilters from "./rule-filters.vue";
import service from "@/service/pricing-rules/create-rules.js";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import RuleItemsMain from "./rule-items/rule-items-main.vue";
import {
  ruleList,
  buildRequest,
  findConditionIndex,
  findBranchInRule,
} from "./rule-engine";
const Service = new service();

export default {
  components: {
    PrdTextField,
    titleBreadCrumbsComponent,
    RuleFilters,
    PrdBtn,
    PrdLoadingMsg,
    RuleItemsMain,
  },

  props: {
    rule: {
      type: Object,
    },
  },

  data: () => ({
    localRule: {
      description: "",
      isAllProducts: false,
      enableMultGroups: false,
      filters: [],
      children: [],
      conditions: [],
    },
    isLoading: false,
    createRuleLoading: false,
  }),

  computed: {
    isCreateRule() {
      return this.rule ? false : true;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    resourceList() {
      return this.$store.getters.resources;
    },

    ruleFiltersLoading() {
      return this.$store.state.pricingRuleV2.ruleFiltersLoading;
    },

    ruleList() {
      const filteredList = ruleList.filter((item) => {
        if (item.showWithResource === "all" || !item.showWithResource) {
          return true;
        } else {
          const requiredResource = item.showWithResource;
          return this.resourceList.some((res) =>
            res.includes(requiredResource)
          );
        }
      });

      return filteredList.map((el) => ({
        ...el,
        description: el.text,
        value: el.conditionType,
        values: [],
      }));
    },

    disableEnableMultGroups() {
      if (this.rule?.enableMultGroups && this.localRule?.idRule != 0)
        return true;
      return false;
    },
  },

  watch: {
    rule: {
      handler(value) {
        if (value) {
          this.setFieldOfRule(value);
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    buildRequest,
    findConditionIndex,
    findBranchInRule,

    async getRule() {
      this.isLoading = true;

      try {
        let response = await Service.getRule(this.idCompany, this.rule?.idRule);
        this.$store.commit("updateRuleWithData", response?.data?.answer);
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_GET_RULES_ERROR"));
      }

      this.isLoading = false;
    },

    async saveRule() {
      this.createRuleLoading = true;

      const childFilters = this.$refs.ruleFilters.returnFilters();
      let request = buildRequest(childFilters, this.localRule, this.idCompany);

      try {
        await Service.saveRule(request);

        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SAVED_RULES_SUCCESS"),
          type: "success",
        });

        if (this.isCreateRule) this.$router.push("/main/pricing-rules");
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_SAVED_RULES_ERROR"));
      }

      this.createRuleLoading = false;
    },

    setFieldOfRule(value) {
      this.localRule = structuredClone(value);
      this.addTitleToRuleConditions(this.localRule?.conditions);
      this.addTitleToRuleChildrens(this.localRule?.children);
    },

    replicateBranch(data) {
      const branchConditions = data.branch.conditions;
      const branchsToReplicate = data.selectedBranchs.map((el) => el.value);

      branchsToReplicate.forEach((el) => {
        const existingChild = findBranchInRule(el, this.localRule.children);

        if (existingChild) {
          existingChild.conditions = JSON.parse(
            JSON.stringify(branchConditions)
          );
        } else {
          this.localRule.children.push({
            conditions: JSON.parse(JSON.stringify(branchConditions)),
            description: `${this.localRule.description}-${el}`,
            filters: [{ idEnterprisePriceGroup_DefaultFilter: 298, value: el }],
            idRule: 0,
          });
        }
      });
    },

    addRuleToConditions(ruleCondition, branch) {
      let newRuleCondition = {
        ...ruleCondition,
        idCondition: ruleCondition.idCondition || 0,
        numberOrder: this.localRule?.conditions?.length + 1,
        isActive: true,
        values: ruleCondition.values,
      };

      if (branch) this.addConditionsToChildren(newRuleCondition, branch);
      else this.localRule.conditions.push(newRuleCondition);
    },

    addConditionsToChildren(newRuleCondition, branch) {
      this.localRule.children.forEach((el) => {
        if (el.filters[0].value == branch.filters[0].value) {
          el.conditions.push(newRuleCondition);
        }
      });
    },

    updateConditionsValue(newConditions) {
      if (newConditions.branch) {
        this.updateBranchConditionsValue(newConditions);
      } else {
        let foundedRule = this.localRule.conditions.find(
          (el) => el.conditionType == newConditions.conditionType
        );

        if (foundedRule) {
          foundedRule.decimals = newConditions?.decimalValue ?? 2;
          foundedRule.values = newConditions.formatedValue;
        }
      }
    },

    updateBranchConditionsValue(newConditions) {
      const currentBranch = newConditions?.branch?.filters[0].value;

      let foundedBranch = findBranchInRule(
        currentBranch,
        this.localRule.children
      );

      let foundedCondition = foundedBranch.conditions.find(
        (el) => el.conditionType == newConditions.conditionType
      );

      foundedCondition.decimals = newConditions?.decimalValue ?? 2;

      foundedCondition.values = newConditions.formatedValue;
    },

    removeRuleFromList(rule, branch) {
      if (!branch) {
        let index = findConditionIndex(rule, this.localRule.conditions);

        if (index !== -1) this.localRule.conditions.splice(index, 1);
      } else {
        let foundedChildren = this.localRule.children.find(
          (el) =>
            el.filters.length === branch.filters.length &&
            el.filters.every(
              (filter, i) => filter.value === branch.filters[i].value
            )
        );

        if (foundedChildren) {
          let index = findConditionIndex(rule, foundedChildren.conditions);

          if (index !== -1) foundedChildren.conditions.splice(index, 1);
        }
      }
    },

    switchRule(rule, branch) {
      if (!branch) {
        let foundedRule = this.localRule.conditions.find(
          (el) => el.conditionType === rule.conditionType
        );

        if (foundedRule) {
          foundedRule.isActive = !foundedRule.isActive;
        }
      } else {
        let foundedChildren = findBranchInRule(
          branch.filters[0].value,
          this.localRule.children
        );

        let foundedRule = foundedChildren.conditions.find(
          (el) => el.conditionType === rule.conditionType
        );

        if (foundedRule) {
          foundedRule.isActive = !foundedRule.isActive;
        }
      }
    },

    handleChangeRulePosition(rule, branch) {
      if (!branch) this.localRule.conditions = rule;
    },

    removeBranch(branch) {
      let foundedBranchIndex = this.localRule.children.findIndex(
        (el) => el.filters[0].value == branch?.filters[0].value
      );

      if (foundedBranchIndex !== -1) {
        this.localRule.children.splice(foundedBranchIndex, 1);
      }
    },

    // Branchs Control =====
    handleChooseBranchs(selectedBranches) {
      this.addNewBranches(selectedBranches);

      this.removeObsoleteBranches(selectedBranches);
    },
    addNewBranches(selectedBranches) {
      selectedBranches.forEach((branch) => {
        if (!this.branchExistsInChildren(branch)) {
          this.localRule.children.push(this.createChildFromBranch(branch));
        }
      });
    },
    removeObsoleteBranches(selectedBranches) {
      this.localRule.children = this.localRule.children.filter((child) =>
        selectedBranches.some((branch) => this.isBranchInChild(branch, child))
      );
    },
    branchExistsInChildren(branch) {
      return this.localRule.children.some((child) =>
        this.isBranchInChild(branch, child)
      );
    },
    isBranchInChild(branch, child) {
      return child.filters.some(
        (filter) =>
          filter.value === branch.value &&
          filter.idEnterprisePriceGroup_DefaultFilter === 298
      );
    },
    createChildFromBranch(branch) {
      return {
        conditions: [],
        description: `${this.localRule.description}-${branch.value}`,
        filters: [
          { idEnterprisePriceGroup_DefaultFilter: 298, value: branch.value },
        ],
        idRule: 0,
      };
    },

    addTitleToRuleConditions(conditions) {
      if (!conditions) return;

      conditions.forEach((el) => {
        let foundedRule = this.ruleList.filter(
          (rule) => rule.conditionType == el.conditionType
        );
        el.description = foundedRule[0].text;
      });
    },
    addTitleToRuleChildrens(children) {
      if (!children) return;

      children.forEach((el) => {
        el.conditions.forEach((con) => {
          let foundedRule = this.ruleList.filter(
            (rule) => rule.conditionType == con.conditionType
          );
          con.description = foundedRule[0].text;
        });
      });
    },

    cleanChildrenList() {
      this.localRule.children = [];
    },
  },

  created() {
    if (!this.isCreateRule) this.getRule();
    else this.$store.dispatch("getFilters");
  },
};
</script>
