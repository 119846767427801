<template>
  <v-row no-gutters class="mb-6">
    <v-col cols="auto">
      <Prd-combo-box
        v-model="selectedValues"
        multiple
        deletable-chips
        small-chips
        clearable
        :title="$t('TXT_VALUE')"
        :items="items"
        :loading="isLoading"
        :disabled="isLoading"
      />
    </v-col>
  </v-row>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import ConfigFiltersPredimonitorService from "@/service/predimonitor-v3/config-filters-predimonitor-service-v3";
export default {
  props: {
    companyId: { type: Number, default: 0 },
    crawlerId: { type: Number, default: 0 },
    filter: { type: Object, default: null },
    customFilters: { type: Array, default: () => [] },
  },
  components: { PrdComboBox },
  data() {
    return {
      isLoading: false,
      service: new ConfigFiltersPredimonitorService(),
      items: [],
      selectedValues: [],
    };
  },
  computed: {
    filterType() {
      return this.filter?.value?.toLowerCase() ?? null;
    },
  },
  watch: {
    filter: {
      handler() {
        this.getCompanyFilterOptions();
        this.fillSelectedValues();
      },
      immediate: true,
      deep: true,
    },
    selectedValues: {
      handler(value) {
        this.$emit("handleSimpleFilter", value);
      },
    },
  },
  methods: {
    fillSelectedValues() {
      this.selectedValues =
        this.customFilters
          .filter((filter) => filter.field === this.filter.value)
          .flatMap((filter) => filter.values) ?? [];
    },
    async getCompanyFilterOptions() {
      this.items = [];
      this.selectedValues = [];
      this.isLoading = true;
      try {
        const response = await this.service.getCompanyFilterOptions(
          this.companyId,
          this.crawlerId,
          this.filterType
        );
        this.handleResponse(response);
      } catch (error) {
        this.$handleError(
          error,
          this.$i18n.t("TXT_FETCH_SIMPLE_FILTERS_ERROR")
        );
      } finally {
        this.isLoading = false;
      }
    },
    handleResponse(response) {
      if (response.status != 204) {
        this.items = response.data.map((item) => item.name);
        this.selectedValues = response.data
          .filter((item) => item.selected)
          .map((item) => item.name);
      } else {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
          type: "info",
        });
      }
    },
  },
};
</script>
