<template>
  <Prd-btn
    :title="$t(buttonTitleKey)"
    :disabled="isDisabled"
    :loading="isLoading || externalLoading"
    @click.stop="checkSize"
  />
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
export default {
  props: {
    type: { type: String, default: "product" },
    externalLoading: { type: Boolean, default: false },
  },
  components: { PrdBtn },
  data() {
    return {
      service: new PredimonitorService(),
      isDisabled: true,
      isLoading: false,
    };
  },
  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
    buttonTitleKey() {
      return this.type == "product"
        ? "TXT_EXTRACT_REPORT"
        : "TXT_EXTRACT_HISTORY";
    },
    checkSizeServiceMethod() {
      return this.type == "product"
        ? "checkProductReportSize"
        : "checkProductPriceHistoryReportSize";
    },
  },
  watch: {
    lastRequest: {
      handler(value) {
        this.isDisabled = !value;
      },
      deep: true,
    },
  },
  methods: {
    async checkSize() {
      this.isLoading = true;
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Produtos",
        name: `Verificar tamanho / ${this.checkSizeServiceMethod}`,
        payload: this.lastRequest,
      });
      try {
        const response = await this.service[this.checkSizeServiceMethod](
          this.lastRequest
        );
        this.$store.commit("endRequest", { id: requestId, response });
        this.$emit(
          response?.data?.sendByEmail == true ? "openModal" : "getFile"
        );
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, this.$i18n.t("TXT_CHECK_NEEDS_SEND_EMAIL"));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
