<template>
  <v-row no-gutters justify="center">
    <div
      class="numbers"
      :style="`height: ${height + 25}px; gap: ${
        height / (scale.length * 1.66)
      }px;`"
    >
      <span
        :style="`font-size: ${height / 15}px`"
        v-for="number in scale"
        :key="number"
      >
        {{ number + "%" }} -
      </span>
    </div>
    <div
      class="background-thermometer"
      :style="`width: ${height / 8}px; height: ${height + 2}px; margin-top: ${
        height / 20
      }px`"
    ></div>
    <div
      :style="`backgroud-color: light-blue; position: relative; top: ${
        ci <= 75
          ? '-' + height / 50 + 'px'
          : ci >= 125
          ? height - height / 60 + 'px'
          : (ci - min) * proportion + 'px'
      }`"
    >
      <span
        class="primary-color font-weight-bold"
        :style="`font-size: ${height / 12}px`"
      >
        <v-icon
          :size="height / 10"
          class="primary-color"
          style="transform: rotate(180deg)"
        >
          mdi-play
        </v-icon>
        {{ ci.toFixed(2) + "%" }}
      </span>
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    ci: { type: Number, default: 0 },
    scale: { type: Array, default: () => [75, 85, 95, 105, 115, 125] },
    height: { type: Number, default: 310 },
  },
  computed: {
    min() {
      return Math.min(...this.scale) ?? 0;
    },
    max() {
      return Math.max(...this.scale) ?? 0;
    },
    proportion() {
      const interval = this.max - this.min;
      return this.height / interval;
    },
  },
};
</script>
<style lang="scss" scoped>
.primary-color {
  color: $brand-color-primary-pure;
}
.numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: bold;
}
.background-thermometer {
  background: linear-gradient(
    180deg,
    #b00020 0%,
    #fedf01 20.83%,
    #fedf01 35.94%,
    #009b3a 51.04%,
    #fedf01 66.67%,
    #fedf01 80.73%,
    #b00020 100%
  );
  border-left: 2px solid black;
}
</style>
