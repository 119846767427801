<template>
  <v-row no-gutters justify="end" class="gap-4">
    <Prd-btn
      :outlined="true"
      :title="$t('TXT_CANCEL')"
      :disabled="notHasChanges"
      @click="$emit('cancelEditions')"
    />
    <Save-changes-dialog
      :updatedConfigs="updatedConfigs"
      :notHasChanges="notHasChanges"
      :companyId="companyId"
      @confirmChanges="$emit('confirmChanges')"
    />
  </v-row>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import SaveChangesDialog from "./save-changes-dialog.vue";

export default {
  props: {
    updatedConfigs: { type: Array, default: () => [] },
    companyId: { type: Number, default: 0 },
  },
  components: {
    PrdBtn,
    SaveChangesDialog,
  },
  computed: {
    notHasChanges() {
      return !this.updatedConfigs?.length ?? true;
    },
  },
};
</script>
