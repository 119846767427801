<template>
  <v-btn depressed @click="$emit('click', $event)" :disabled="disabled">
    <v-icon
      v-if="leftIcon"
      :color="leftIconColor"
      :size="leftIconSize"
      class="mr-2"
      >{{ leftIcon }}
    </v-icon>
    <span :style="`color: ${disabled ? '#BAB8B8': color}`">{{ title }}</span>
    <v-icon
      v-if="rightIcon"
      :color="rightIconColor"
      :size="rightIconSize"
      class="ml-2"
      >{{ rightIcon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: function () {
        return this.$prdStyles("color-primary");
      },
    },
    leftIcon: {
      type: String,
    },
    leftIconColor: {
      type: String,
      default: function () {
        return this.$prdStyles("color-primary");
      },
    },
    leftIconSize: {
      type: Number,
      default: 16,
    },
    rightIcon: {
      type: String,
    },
    rightIconColor: {
      type: String,
      default: function () {
        return this.$prdStyles("color-primary");
      },
    },
    rightIconSize: {
      type: Number,
      default: 16,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
