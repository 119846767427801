<template>
  <v-row no-gutters class="mb-6">
    <v-col cols="3">
      <Prd-combo-box
        v-model="crawlerSelected"
        :title="'Crawler'"
        :items="items"
        :loading="isLoading"
      />
    </v-col>
  </v-row>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import CrawlerManagementService from "@/service/crawlers/crawler-management";
export default {
  props: { companyId: { type: Number, default: 0 } },
  components: {
    PrdComboBox,
  },
  data() {
    return {
      isLoading: false,
      service: new CrawlerManagementService(),
      items: [],
      crawlerSelected: null,
    };
  },
  watch: {
    crawlerSelected: {
      handler(value) {
        this.$emit("updateCrawlerId", value?.crawlerId);
      },
      immediate: true,
      deep: true,
    },
    companyId: {
      handler() {
        this.getCrawlerByCompany();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async getCrawlerByCompany() {
      this.items = [];
      this.crawlerSelected = null;
      this.isLoading = true;
      try {
        const response = await this.service.getCrawlerByCompany(this.companyId);
        const mappedResonse =
          response?.data?.result?.crawlers?.map((crawler) => {
            return {
              crawlerId: crawler.idCrawler,
              text: `${crawler.idCrawler} - ${crawler.description}`,
            };
          }) ?? [];
        this.items = mappedResonse;
        this.$emit("crawlerList", mappedResonse);
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_FETCH_CRAWLERS_ERROR"));
      } finally {
        this.isLoading = false;
      }
    },
    resetValues() {
      this.crawlerSelected = null;
    },
  },
};
</script>
