<template>
  <v-dialog v-model="isOpen" width="fit-content">
    <v-card class="pa-4">
      <v-row no-gutters class="gap-4">
        <Prd-combo-box v-model="section" :title="'Seção'" :items="sections" />

        <Prd-combo-box v-model="name" :title="'End-point'" :items="names" />
      </v-row>
      <p class="my-2">
        <v-icon
          v-if="currentUrl && currentUrl != '-'"
          :color="$prdStyles('color-primary')"
          dense
          @click.stop="copyText(currentUrl)"
          class="mr-2"
        >
          mdi-content-copy
        </v-icon>
        <span class="font-weight-bold">Url: </span>
        {{ currentUrl }}
      </p>
      <v-expansion-panels v-model="panels">
        <v-expansion-panel v-for="item in list" :key="item.id">
          <v-expansion-panel-header>
            <v-row no-gutters align="center" class="gap-2">
              <v-col cols="auto">
                <span class="font-weight-bold">
                  {{ formatDate(item.startTime, "dd/MM/yyyy - hh:mm:ss") }}
                </span>
              </v-col>
              <v-col cols="auto">
                <Prd-loading-circular
                  v-if="item.isLoading"
                  :justify="'start'"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="my-2">
              <span class="font-weight-bold">Status: </span>
              {{ item.code }}
            </p>
            <p class="my-2">
              <span class="font-weight-bold">Tempo de resposta: </span>
              {{ item.duration }}
            </p>
            <p class="my-2">
              <v-icon
                :color="$prdStyles('color-primary')"
                dense
                @click.stop="copyText(item.payload)"
                class="mr-2"
              >
                mdi-content-copy
              </v-icon>
              <span class="font-weight-bold">Request: </span>
            </p>
            <pre>{{ JSON.stringify(item.payload, null, 2) }}</pre>

            <p class="my-2">
              <v-icon
                :color="$prdStyles('color-primary')"
                dense
                @click.stop="copyText(item.data)"
                class="mr-2"
              >
                mdi-content-copy
              </v-icon>
              <span class="font-weight-bold">Response: </span>
            </p>
            <pre>{{ JSON.stringify(item.data, null, 2) }}</pre>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import { formatDate } from "@/utils/format-date";
export default {
  components: { PrdComboBox, PrdLoadingCircular },
  data() {
    return {
      isOpen: false,
      section: null,
      name: null,
      panels: [],
    };
  },
  computed: {
    list() {
      return this.$store.getters.getAllRequestOfSelection(
        this.section,
        this.name
      );
    },
    currentUrl() {
      return this.list[0]?.url ?? "-";
    },
    sections() {
      return this.$store.getters.getSectionList;
    },
    names() {
      return this.$store.getters.getRequestNamesOfSection(this.section);
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (!value) this.panels = [];
      },
    },
    section: {
      handler() {
        if (this.names.length == 1) this.name = this.names[0] ?? null;
      },
      immediate: true,
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    reStartComponent() {
      this.isOpen = false;
      this.section = null;
      this.name = null;
      this.panels = [];
      this.$store.commit("cleanEndPointList");
    },
    formatDate,
    copyText(text) {
      const realText = typeof text != "object" ? text : JSON.stringify(text);
      navigator.clipboard.writeText(realText).then(() => {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_COPY"),
          type: "info",
        });
      });
    },
  },
};
</script>
