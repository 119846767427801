<template>
  <div>
    <PrdTooltip
      :icon="'mdi-thermometer'"
      :sizeIcon="24"
      :colorIcon="$prdStyles('color-primary')"
      :useExternalContent="true"
      :externalDisabled="externalIsDisabled"
    >
      <template #external-content>
        <Thermometer-average :ci="ci" :useInToolTip="true" />
      </template>
    </PrdTooltip>
  </div>
</template>

<script>
import PrdTooltip from "@/components/common/prd-tooltip.vue";
import ThermometerAverage from "@/views/main/predimonitor-v3/components/competitiveness-index/components/thermometer/thermometer-average-main.vue";

export default {
  props: {
    ci: { type: Number, default: 0 },
    externalIsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PrdTooltip,
    ThermometerAverage,
  },
};
</script>
