<template>
  <div class="mt-4">
    <v-row no-gutters align="end">
      <v-col cols="12" md="4" class="mr-4">
        <Prd-combo-box
          v-model="model.reference"
          :title="$t('TXT_REFERENCE')"
          :items="referenceItems"
        />
      </v-col>
      <v-col cols="12" md="2" class="mr-4" v-if="model.reference.value != 3">
        <Prd-text-field
          v-model="model.value"
          :title="$t('TXT_VARIATION')"
          :type="'number'"
          hide-spin-buttons
          :min="0"
        />
      </v-col>
      <v-col cols="auto" v-else class="mr-4">
        <v-row no-gutters class="gap-4">
          <Prd-text-field
            v-model="model.min"
            :title="$t('TXT_MIN_2')"
            :type="'number'"
            hide-spin-buttons
            :min="0"
          />
          <Prd-text-field
            v-model="model.max"
            :title="$t('TXT_MAX_2')"
            :type="'number'"
            hide-spin-buttons
            :min="0"
          />
        </v-row>
      </v-col>

      <v-checkbox
        v-model="model.isPercentage"
        :label="$t('TXT_IS_PERCENTAGE')"
        dense
        hide-details
        class="ma-0 pa-0"
      ></v-checkbox>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";

export default {
  props: ["values"],
  components: { PrdTextField, PrdComboBox },
  data() {
    return {
      referenceItems: [
        {
          text: `${this.$i18n.t("TXT_DEMAND")} ${this.$i18n
            .t("TXT_BIGGER_THAN")
            .toLowerCase()}`,
          value: 0,
        },
        {
          text: `${this.$i18n.t("TXT_DEMAND")} ${this.$i18n
            .t("TXT_LESS_THAN")
            .toLowerCase()}`,
          value: 1,
        },
        {
          text: `${this.$i18n.t("TXT_DEMAND")} ${this.$i18n
            .t("TXT_EQUAL_TO")
            .toLowerCase()}`,
          value: 2,
        },
        {
          text: `${this.$i18n.t("TXT_DEMAND")} ${this.$i18n
            .t("TXT_BETWEEN")
            .toLowerCase()}`,
          value: 3,
        },
      ],
      model: {
        reference: null,
        value: 0,
        min: 0,
        max: 0,
        isPercentage: false,
      },
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      if (inputs.reference.value == 3) {
        this.model.value = null;
      } else {
        this.model.max = null;
        this.model.min = null;
      }

      let formatedValue = [
        {
          stringValue: "VariationValue",
          decimalValue: parseFloat(inputs.value ?? 0),
          booleanValue: false,
        },
        {
          stringValue: "ComparisonType",
          decimalValue: inputs.reference.value,
          booleanValue: false,
        },
        {
          stringValue: "MinRange",
          decimalValue: parseFloat(inputs.min ?? 0),
          booleanValue: false,
        },
        {
          stringValue: "MaxRange",
          decimalValue: parseFloat(inputs.max ?? 0),
          booleanValue: false,
        },
        {
          stringValue: "IsPercentage",
          decimalValue: 0,
          booleanValue: inputs.isPercentage,
        },
      ];

      this.$emit("updateConditionValues", { formatedValue, conditionType: 17 });
    },
    setInputs(conditionList) {
      const reference = conditionList.find(
        (condition) => condition.stringValue == "ComparisonType"
      );
      this.model.reference = this.referenceItems.find(
        (item) => item.value == reference.decimalValue
      );

      const variationValue = conditionList.find(
        (condition) => condition.stringValue == "VariationValue"
      ).decimalValue;
      this.model.value = variationValue;

      const minRange = conditionList.find(
        (condition) => condition.stringValue == "MinRange"
      ).decimalValue;
      this.model.min = minRange;

      const maxRange = conditionList.find(
        (condition) => condition.stringValue == "MaxRange"
      ).decimalValue;
      this.model.max = maxRange;

      const isPercentage = conditionList.find(
        (condition) => condition.stringValue == "IsPercentage"
      ).booleanValue;
      this.model.isPercentage = isPercentage;
    },
  },

  created() {
    if (this.values.length > 0) this.setInputs(this.values);
    else
      this.model.reference = {
        text: `${this.$i18n.t("TXT_DEMAND")} ${this.$i18n
          .t("TXT_BIGGER_THAN")
          .toLowerCase()}`,
        value: 0,
      };
  },
};
</script>
