<template>
  <Section-card :title="$t('TXT_MANAGE_BASES')">
    <template slot="content">
      <Handle-config
        ref="handleConfig"
        class="my-4"
        :companyId="companyId"
        @updatedConfigs="updatedConfigs = $event"
      />

      <Handle-actions
        :companyId="companyId"
        :updatedConfigs="updatedConfigs"
        @cancelEditions="cancelEditions"
        @confirmChanges="confirmChanges"
      />
    </template>
  </Section-card>
</template>

<script>
import SectionCard from "@/views/main/general-system-configs/common-use-components/section-card.vue";
import HandleConfig from "./components/handle-config.vue";
import HandleActions from "./components/actions/handle-actions.vue";
export default {
  props: { companyId: { type: Number, default: 0 } },
  components: {
    SectionCard,
    HandleConfig,
    HandleActions,
  },
  data() {
    return {
      updatedConfigs: [],
    };
  },
  watch: {
    companyId: {
      handler() {
        this.updatedConfigs = [];
      },
    },
  },
  methods: {
    cancelEditions() {
      if (this.$refs.handleConfig) this.$refs.handleConfig.cancelEditions();
    },
    confirmChanges(){
      if (this.$refs.handleConfig) this.$refs.handleConfig.confirmChanges();
    }
  },
};
</script>
