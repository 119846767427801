<template>
  <v-row no-gutters align="center">
    <v-icon
      :color="$prdStyles('color-primary')"
      :disabled="isLoading"
      dense
      @click.stop="copyText(name)"
      class="mr-2"
    >
      mdi-content-copy
    </v-icon>

    <v-skeleton-loader v-if="isLoading" type="button" height="20" />
    <v-tooltip top v-else>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="font-weight-bold truncated-text">
          {{ name }}
        </span>
      </template>
      <span>{{ name }}</span>
    </v-tooltip>
  </v-row>
</template>

<script>
import { copyText } from "@/utils/copy-text";
export default {
  props: {
    name: { type: String, require: true },
    isLoading: { type: Boolean, default: false },
  },

  methods: {
    copyText,
  },
};
</script>

<style lang="scss" scoped>
.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
  width: fit-content;
}
</style>
