<template>
  <div>
    <prd-btn
      @click="isModalOpen = true"
      :loading="isLoading"
      :disabled="isLoading"
      :title="$t('TXT_CRAWLERS_ROUTINE')"
      class="mt-10 mr-4"
    />

    <v-dialog v-model="isModalOpen" :width="700">
      <v-card class="pa-4">
        <h4>{{ $t("TXT_CRAWLERS_ROUTINE") }}</h4>

        <v-row no-gutters class="mt-4">
          <v-col cols="12" md="4" class="mr-4">
            <span>
              <label v-t="'TXT_REFERENCE_PERIOD'"></label>
            </span>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              ref="menu"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  :label="$t('TXT_SELECT_PERIOD')"
                  :placeholder="$t('TXT_SELECT_PERIOD')"
                  hide-details
                  outlined
                  single-line
                  dense
                  readonly
                  class="input-datapicker"
                >
                  <template v-slot:append>
                    <v-icon class="append-calendar" color="white" small>
                      mdi-calendar</v-icon
                    >
                  </template>
                </v-text-field>
              </template>
              <!-- TODO - properly use user's locale -->
              <v-date-picker
                v-model="date"
                no-title
                scrollable
                range
                :min="minDay"
                :max="maxDay"
                :locale="localeForDatePicker"
              >
                <v-spacer></v-spacer>

                <v-btn
                  text
                  color="error"
                  @click="menu = false && dateRangeText == null"
                  v-t="'TXT_CANCEL'"
                ></v-btn>
                <v-btn outlined color="primary" @click="setDate">Reset</v-btn>

                <v-btn
                  color="primary"
                  @click="$refs.menu.save(date)"
                  v-t="'TXT_OK'"
                ></v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="3" class="mr-4">
            <label>{{ $t("TXT_REFERENCE_PERIOD") }}</label>
            <v-menu
              ref="menuRefDate"
              v-model="menuRefDate"
              :close-on-content-click="false"
              :return-value.sync="refDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="refDate"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="refDate"
                no-title
                scrollable
                :locale="localeForDatePicker"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuRefDate = false">
                  {{ $t("TXT_CANCEL") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuRefDate.save(refDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="3">
            <label>Skip</label>
            <v-text-field
              v-model="skipItems"
              outlined
              dense
              hide-details
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters align="center" class="mt-4">
          <v-col cols="12" md="4" class="mr-4">
            <label>{{ $t("TXT_ID_COMPANY") }}</label>
            <v-text-field
              v-model="idCompany"
              type="number"
              hide-spin-buttons
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-checkbox
            v-model="removeOlds"
            hide-details
            :label="$t('TXT_REMOVE_OLDS')"
            class="mr-4"
          ></v-checkbox>
          <v-checkbox
            v-model="removeOutliers"
            hide-details
            :label="$t('TXT_REMOVE_OUTLIERS')"
          ></v-checkbox>
        </v-row>

        <v-row no-gutters class="mt-6">
          <v-spacer></v-spacer>
          <prd-btn
            :title="$t('TXT_CANCEL')"
            @click="isModalOpen = false"
            :outlined="true"
            class="mr-4"
          />
          <prd-btn :title="$t('TXT_EXECUTE')" @click="executeCrawler" />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/predimonitor-v3/predimonitor-service-v3";
const Service = new service();
export default {
  components: { prdBtn },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      removeOlds: false,
      removeOutliers: false,
      skipItems: 0,

      menu: false,
      menuRefDate: false,
      date: [],
      minDay: null,
      maxDay: null,

      refDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },

  computed: {
    dateRangeText() {
      return this.date.join("  ~  ");
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    localeForDatePicker() {
      return localStorage.getItem("lang") ?? "pt-BR";
    },
  },

  methods: {
    setDate() {
      this.minDay = null;
      this.maxDay = null;
      var startDay = new Date();
      startDay.setDate(startDay.getDate() - 7);
      this.minRange = startDay.toISOString().slice(0, 10);
      this.maxDay = new Date().toISOString().slice(0, 10);

      this.date[0] = this.minRange;
      this.date[1] = new Date().toISOString().slice(0, 10);
    },

    getCurrentDay() {},

    executeCrawler() {
      this.isLoading = true;
      this.isModalOpen = false;

      let request = {
        startDate: this.date[0],
        endDate: this.pushOneMoreDay(this.date[1]),
        idCompany: this.idCompany,
        refDate: this.refDate,
        removeOlds: this.removeOlds,
        removeOutliers: this.removeOutliers,
        skipItems: Number(this.skipItems),
      };
      Service.setCrawlerRotine(request)
        .then(() => {
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_FINISHED_CRAWLER_ROUTINE"),
            type: "success",
          });
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao gerar rotina", error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_FINISHED_CRAWLER_ROUTINE_ERROR"),
            type: "error",
          });
          this.isLoading = false;
        });
    },

    pushOneMoreDay(dataString) {
      const data = new Date(dataString);
      data.setDate(data.getDate() + 2);
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const dia = String(data.getDate()).padStart(2, "0");
      return `${ano}-${mes}-${dia}`;
    },
  },

  created() {
    this.setDate();
  },
};
</script>

<style lang="scss" scoped>
.append-pencil {
  background: #3c5ca7;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-minus {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
</style>