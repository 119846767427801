<template>
  <v-row no-gutters justify="space-between" align="end">
    <v-col cols="auto">
      <v-row no-gutters class="gap-4">
        <Prd-combo-box
          :label="$t('TXT_ALL_PRODUCTS')"
          :title="$t('TXT_PRODUCTS')"
          :loading="isLoading"
          :disabled="isLoading"
          :items="productList"
          @input="
            $emit(
              'setSelectedProduct',
              $event == $t('TXT_ALL_PRODUCTS').toUpperCase() ? null : $event
            )
          "
        />
        <Prd-combo-box
          :label="$t('TXT_ALL_COMPETITOR')"
          :title="$t('TXT_COMPETITORS')"
          :loading="isLoading"
          :disabled="isLoading"
          :items="sellerList"
          @input="
            $emit(
              'setSelectedSeller',
              $event == $t('TXT_ALL_COMPETITOR').toUpperCase() ? null : $event
            )
          "
        />
      </v-row>
    </v-col>
    <v-col cols="auto">
      <Prd-btn
        v-if="selectedState"
        :title="$t('TXT_BACK')"
        :disabled="isLoading"
        @click="$emit('setSelectedState')"
      />
    </v-col>
  </v-row>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    rawData: {
      type: Object,
      default: () => ({}),
    },
    selectedState: {
      type: String,
      default: null,
    },
  },
  components: {
    PrdComboBox,
    PrdBtn,
  },
  computed: {
    productList() {
      const list = [this.$i18n.t("TXT_ALL_PRODUCTS").toUpperCase()];
      list.push(...(this.rawData?.products?.map((item) => item.name) ?? []));
      return list;
    },
    sellerList() {
      const list = [this.$i18n.t("TXT_ALL_COMPETITOR").toUpperCase()];
      list.push(...(this.rawData?.sellers ?? []));
      return list;
    },
  },
};
</script>
