<template>
  <Expansive-panel
    :title="$t('TXT_PRODUCT_COMPARISON_UPPERCASE')"
    :tooltipText="$t('TXT_PRODUCT_COMPARISON_TOOLTIP')"
    :disabled="isDisabled"
  >
    <template v-slot:content>
      <Tabs
        :rawData="rawData"
        :isLoading="isLoading"
        @changedTab="handleChangeTab"
        @changeYears="selectedYears = $event"
        class="mb-4"
      />
      <h4 v-if="!filteredData.length && !isLoading">
        {{ $t("TXT_NOT_FOUND_DATA_FOR_THIS_AGROUPMENT") }}
      </h4>

      <div v-else>
        <Table
          v-if="activeView == 0"
          :items="filteredData"
          :isLoading="isLoading"
        />

        <Chart v-else :data="filteredData" :isLoading="isLoading" />
      </div>
    </template>
  </Expansive-panel>
</template>

<script>
import ExpansivePanel from "../common/expansive-panel.vue";
import Tabs from "./components/tabs-main.vue";
import Table from "./components/table-main.vue";
import Chart from "./components/chart-main.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";

export default {
  props: {
    isOpen: { type: Boolean, default: false },
  },
  components: {
    ExpansivePanel,
    Tabs,
    Table,
    Chart,
  },

  data() {
    return {
      service: new PredimonitorService(),
      isDisabled: true,
      isLoading: false,
      activeGroup: 0,
      activeView: 0,
      rawData: null,
      selectedYears: [],
      history: [],
    };
  },

  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
    refreshComponentsFlag() {
      return this.$store.state.PredimonitorStore.refreshComponentsFlag;
    },
    filteredData() {
      const keys = ["product", "origin", "network"];
      const agroupmentType = keys[this.activeGroup];
      return this.rawData?.[agroupmentType] ?? [];
    },
  },
  watch: {
    lastRequest: {
      handler(value) {
        if (!value) return;
        this.isDisabled = false;
        this.refreshData();
      },
      deep: true,
    },
    refreshComponentsFlag() {
      this.refreshData();
    },

    isOpen() {
      this.fetchData();
    },
    selectedYears() {
      this.handleSelectedYears();
    },
  },
  methods: {
    async fetchData() {
      if (!this.isOpen || this.rawData != null || this.isLoading) return;
      this.isLoading = true;
      const payload = this.prepareRequest();
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Comparação de Produto",
        name: "searchProductComparisonData",
        payload,
      });

      try {
        const response = await this.service.searchProductComparisonData(
          payload
        );
        this.$store.commit("endRequest", { id: requestId, response });
        const data = response?.data ?? null;
        this.rawData = data;
        this.history.push({ request: payload, data });
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, this.$i18n.t("TXT_ERROR_PRODUCT_COMPARISION"));
      } finally {
        this.isLoading = false;
      }
    },

    prepareRequest() {
      const copyRequest = structuredClone(this.lastRequest);

      if (this.selectedYears.length > 0) copyRequest.years = this.selectedYears;

      return copyRequest;
    },

    handleChangeTab({ variable, value }) {
      this[variable] = value;
    },

    reStartComponent() {
      this.isDisabled = true;
      this.isLoading = false;
      this.activeGroup = 0;
      this.activeView = 0;
      this.rawData = null;
      this.history = [];
    },

    handleSelectedYears() {
      const currentRequest = JSON.stringify(this.prepareRequest());
      const existentHistory = this.history.find(
        (item) => JSON.stringify(item.request) == currentRequest
      );

      if (existentHistory) this.rawData = structuredClone(existentHistory.data);
      else this.refreshData();
    },

    refreshData() {
      this.rawData = null;
      this.fetchData();
    },
  },
};
</script>
