<template>
  <v-row no-gutters justify="space-between" align="center">
    <v-col cols="auto">
      <span class="blueText">{{
        $t("TXT_PAGINATION")
          .replace("${result}", localCurrentAmountShowItems)
          .replace("${total}", localTotalItems)
      }}</span>
    </v-col>
    <v-col cols="auto">
      <v-pagination
        v-model="currentPage"
        :length="localLength"
        :total-visible="totalVisibleNumbers"
        :disabled="isDisabled || isLoading"
        @input="$emit('currentPage', $event)"
      />
    </v-col>
    <v-col cols="auto">
      <v-row no-gutters align="center">
        <span class="blueText mr-1">{{ $t("TXT_PAGINA") }}</span>
        <Prd-text-field
          v-model="currentPage"
          :type="'number'"
          :min="1"
          :max="localLength"
          :disabled="localLength <= 1 || isDisabled || isLoading"
          @input="handleCurrentPage"
          class="fieldSize"
        />
        <span class="blueText ml-1"> {{ $t("TXT_OF") }} {{ localLength }}</span>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { PrdTextField },
  props: {
    totalVisibleNumbers: {
      type: Number,
      default: 5,
    },
    totalItems: {
      type: Number,
      default: 1,
    },
    currentAmountShowItems: {
      type: Number,
      default: 1,
    },
    take: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    delayTextField: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
      timer: null,
    };
  },
  computed: {
    localLength() {
      if (!this.totalItems || !this.currentAmountShowItems) return 1;
      const realQuantity = Math.ceil(this.totalItems / this.divider);

      return realQuantity <= 0 ? 1 : realQuantity;
    },
    divider() {
      return this.take || this.currentAmountShowItems;
    },
    localCurrentAmountShowItems() {
      if (this.isLoading) return 0;
      return this.currentAmountShowItems > this.totalItems
        ? this.totalItems
        : this.currentAmountShowItems;
    },
    localTotalItems() {
      return this.isLoading ? 0 : this.totalItems;
    },
  },

  methods: {
    reStartCurrentPage() {
      this.currentPage = 1;
    },
    handleCurrentPage(value) {
      if (!value) {
        this.currentPage = null;
        return;
      }
      this.currentPage = parseInt(value);
      if (this.currentPage > this.localLength) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_NON_EXISTENT_PAGE"),
          type: "info",
        });
        return;
      }

      if (!this.currentPage) this.currentPage = 1;
      else this.currentPage = parseInt(value);

      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit("currentPage", this.currentPage);
      }, this.delayTextField);
    },
  },
};
</script>

<style lang="scss" scoped>
.blueText {
  color: $brand-color-primary-pure !important;
}
.fieldSize {
  width: 75px;
}
</style>
