<template>
  <High-charts-main
    :type="'spline'"
    :isLoading="isLoading"
    :personalizedOptions="personalizedOptions"
  />
</template>

<script>
import HighChartsMain from "../../../../common/high-charts-main.vue";

export default {
  props: {
    isLoading: { type: Boolean, default: false },
    rawData: { type: Object, default: () => ({}) },
  },
  components: {
    HighChartsMain,
  },
  data() {
    return {
      personalizedOptions: {
        yAxis: {
          title: {
            text: `${this.$i18n.t(
              "TXT_COMPETITIVENESS_INDEX"
            )} - ${this.$i18n.t("TXT_CI_UPPERCASE")} (%)`,
          },
          crosshair: true,
          startOnTick: true,
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },
        xAxis: {
          title: {
            text: this.$i18n.t("TXT_TIME"),
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.series.name}</b>
                    <br/>
                    <br/>
                    <b>${this.x}</b>
                    <br/>
                    <span style="color:${this.point.color}">●</span> IC: <b>${this.y}%</b>`;
          },
        },
      },
    };
  },

  watch: {
    rawData: {
      handler(value) {
        this.fillChart(value);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    fillChart(value) {
      if (!value) return;
      const categories = value?.competitivenessIndexHistory?.dates ?? [];
      const series =
        value?.competitivenessIndexHistory?.products.map(
          ({ name, competitivenessIndex }) => ({
            name,
            data: competitivenessIndex,
          })
        ) ?? [];

      this.personalizedOptions = {
        ...this.personalizedOptions,
        xAxis: {
          ...this.personalizedOptions.xAxis,
          categories,
        },
        series,
      };
    },
  },
};
</script>
