<template>
  <Expansive-panel
    :title="$t('TXT_ANNOUNCEMENT_MAP')"
    :tooltipText="$t('TXT_ANNOUNCEMENT_MAP_TOOLTIP')"
    :disabled="isDisabled"
  >
    <template v-slot:content>
      <Filter-main
        :isLoading="isLoading"
        :rawData="rawData"
        :selectedState="selectedState"
        @setSelectedState="selectedState = null"
        @setSelectedProduct="selectedProduct = $event"
        @setSelectedSeller="selectedSeller = $event"
        class="mb-4"
      />
      <v-row no-gutters>
        <v-col cols="6" class="pr-2">
          <Map-main
            :isLoading="isLoading"
            :rawData="rawData"
            :selectedState="selectedState"
          />
        </v-col>
        <v-col cols="6" class="pl-2">
          <Table-main
            :isLoading="isLoading"
            :rawData="rawData"
            :selectedState="selectedState"
            @setSelectedState="selectedState = $event"
          />
        </v-col>
      </v-row>
    </template>
  </Expansive-panel>
</template>

<script>
import ExpansivePanel from "../common/expansive-panel.vue";
import FilterMain from "./components/filter-main.vue";
import MapMain from "./components/map/map-main.vue";
import TableMain from "./components/table/table-main.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: { ExpansivePanel, FilterMain, MapMain, TableMain },
  data() {
    return {
      isDisabled: true,
      isLoading: false,
      rawData: null,
      selectedState: null,
      selectedProduct: null,
      selectedSeller: null,
      history: [],
      service: new PredimonitorService(),
    };
  },
  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
    refreshComponentsFlag() {
      return this.$store.state.PredimonitorStore.refreshComponentsFlag;
    },
  },
  watch: {
    lastRequest: {
      handler(value) {
        if (!value) return;
        this.isDisabled = false;
        this.selectedState = null;
        this.selectedProduct = null;
        this.selectedSeller = null;
        this.refreshData();
      },
      deep: true,
    },
    refreshComponentsFlag() {
      this.history = [];
      this.refreshData();
    },
    isOpen() {
      this.fetchData();
    },
    selectedState() {
      this.refreshData();
    },
    selectedProduct() {
      this.refreshData();
    },
    selectedSeller() {
      this.refreshData();
    },
  },
  methods: {
    refreshData() {
      this.rawData = null;
      this.fetchData();
    },
    async fetchData() {
      if (!this.isOpen || this.rawData != null || this.isLoading) return;
      const payload = this.prepareRequest();
      if (this.checkHistory(payload)) {
        this.fillRawData(payload);
        return;
      }
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Mapa de anúncios",
        name: "searchMapData",
        payload,
      });
      this.isLoading = true;

      try {
        const response = await this.service.searchMapData(payload);
        const data = response?.data ?? null;
        this.rawData = data;
        this.history.push({ request: payload, data });
        this.$store.commit("endRequest", { id: requestId, response });
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, "Erro ao  buscar dados");
      } finally {
        this.isLoading = false;
      }
    },
    prepareRequest() {
      return {
        ...this.lastRequest,
        states: this.selectedState
          ? [this.selectedState]
          : this.lastRequest.states,
        productNames: this.selectedProduct
          ? [this.selectedProduct]
          : this.lastRequest.productNames,
        sellers: this.selectedSeller
          ? [this.selectedSeller]
          : this.lastRequest.sellers,
      };
    },
    checkHistory(request) {
      return this.history.some(
        (item) => JSON.stringify(item.request) == JSON.stringify(request)
      );
    },
    fillRawData(request) {
      this.rawData =
        this.history.find(
          (item) => JSON.stringify(item.request) == JSON.stringify(request)
        )?.data ?? null;
    },
    reStartComponent() {
      this.isDisabled = true;
      this.isLoading = false;
      this.rawData = null;
      this.selectedState = null;
      this.selectedProduct = null;
      this.selectedSeller = null;
      this.history = [];
    },
  },
};
</script>
