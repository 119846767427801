<template>
  <v-row no-gutters :justify="justify">
    <v-progress-circular
      indeterminate
      :color="color"
      v-bind="$attrs"
    ></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#3c5ca7",
    },
    justify: {
      type: String,
      default: "center",
    },
  },
};
</script>
