<template>
  <v-row no-gutters align="center" @click.stop>
    <v-col cols="auto">
      <span class="mr-2">{{ $t(currentPriceLabel) }}:</span>
    </v-col>
    <v-col cols="auto">
      <v-skeleton-loader
        v-if="externalIsLoading || isLoading"
        type="button"
        height="20"
      />
      <div v-else>
        <v-row v-if="isEditing" no-gutters align="center">
          <Prd-currency-field
            v-model="currentPrice"
            :width="'120'"
            @update="currentPrice = $event"
            class="mr-1"
          />
          <v-icon
            :color="$prdStyles('color-success')"
            :disabled="!hasChange"
            @click.stop="setCurrentPrice"
          >
            mdi-checkbox-marked
          </v-icon>
          <v-icon
            :color="$prdStyles('color-warning')"
            @click.stop="exitOfEditingMode"
          >
            mdi-alpha-x-box
          </v-icon>
        </v-row>
        <v-row v-else no-gutters align="center">
          <span class="font-weight-bold mr-1">{{
            formatMonetary(currentPrice)
          }}</span>
          <v-icon
            :color="$prdStyles('color-primary')"
            @click.stop="isEditing = true"
            >mdi-pencil-box</v-icon
          >
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PrdCurrencyField from "@/components/common/prd-currency-field.vue";
import { formatMonetary } from "@/utils/format-toMonetary";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
export default {
  props: {
    externalIsLoading: { type: Boolean, default: false },
    price: { type: Number, default: 0 },
    productIds: { type: Array, default: () => [] },
  },
  components: {
    PrdCurrencyField,
  },
  data() {
    return {
      isEditing: false,
      isLoading: false,
      currentPrice: 0,
      service: new PredimonitorService(),
    };
  },
  computed: {
    currentPriceLabel() {
      return `TXT_CURRENT_${
        this.$route.query.type?.includes("cost") ? "COST" : "PRICE"
      }`;
    },
    hasChange() {
      return this.currentPrice != this.price;
    },
  },
  watch: {
    price: {
      handler(value) {
        this.currentPrice = value;
      },
      immediate: true,
    },
  },
  methods: {
    formatMonetary,
    async setCurrentPrice() {
      this.isLoading = true;
      const payload = this.prepareRequest();
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Produtos",
        name: "Atualizar Preço Atual",
        payload,
      });
      try {
        const response = await this.service.setCurrentPrice(payload);
        this.$store.commit("endRequest", { id: requestId, response });
        this.$store.commit("updateLastPrice", {
          price: payload.price,
          productIds: payload.productIds,
        });
        this.isEditing = false;
        this.$store.commit("toogleRefreshCIFlag");
        this.$emit("refreshHeader");
        this.$store.commit("snackbarV2/set", {
          type: "success",
          message: `${this.$i18n.t("TXT_PRICE")} ${this.$i18n
            .t("TXT_UPDATED_SUCCESS")
            .toLowerCase()}`,
        });
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(
          error,
          `${this.$i18n.t("TXT_UPDATED_ERROR")} ${this.currentPrice} `
        );
      } finally {
        this.isLoading = false;
      }
    },
    prepareRequest() {
      return {
        companyId: this.$store.getters.userData.idCompany,
        productIds: this.productIds,
        price: this.currentPrice,
      };
    },
    exitOfEditingMode() {
      this.currentPrice = this.price;
      this.isEditing = false;
    },
  },
};
</script>
