<template>
  <v-expansion-panel
    :disabled="data.isLoading"
    @change="isOpen = !isOpen"
    class="mb-4"
  >
    <Panel-header ref="panelHeader" :data="data" />

    <Panel-content ref="panelContent" :notHavePrices="notHavePrices" @refreshHeader="refreshHeader" />
  </v-expansion-panel>
</template>

<script>
import PanelContent from "./components/panel-content/panel-content-main.vue";
import PanelHeader from "./components/panel-header/panel-header-main.vue";

export default {
  components: { PanelHeader, PanelContent },

  props: {
    data: {
      type: Object,
      default: () => ({
        name: "",
        minPrice: 0,
        avgPrice: 0,
        maxPrice: 0,
        latestPrice: 0,
        competitivenessIndex: 0,
        productIds: [],
        isLoading: false,
      }),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    notHavePrices() {
      return (
        this.data?.minPrice == 0 &&
        this.data?.avgPrice == 0 &&
        this.data?.maxPrice == 0
      );
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (value) this.$refs.panelContent.getInitialPage(this.data.productIds);
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$refs.panelContent.cleanData(true);
    },
    refreshHeader() {
      if (this.$refs.panelHeader) this.$refs.panelHeader.refreshHeader();
    },
  },
};
</script>
