<template>
  <v-dialog v-model="isOpen" transition="dialog-top-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <Prd-btn
        v-bind="attrs"
        v-on="on"
        :outlined="true"
        :title="$t('TXT_SEE_ALL')"
        :small="true"
        :disabled="isLoading"
        :loading="isLoading"
      />
    </template>

    <v-card class="pa-4">
      <v-row no-gutters justify="center" class="mb-3">
        <h4>{{ $t("TXT_ALL_CUSTOMIZATIONS") }}</h4>
        <v-icon
          :color="$prdStyles('color-primary')"
          @click="isOpen = false"
          class="close-button"
        >
          mdi-close
        </v-icon>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="validFilters"
        hide-default-footer
      />
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  props: {
    customFilters: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
  },
  components: {
    PrdBtn,
  },
  data() {
    return {
      isOpen: false,
      headers: [
        {
          text: "Filtro",
          align: "center",
          value: "text",
        },
        {
          text: "Operador",
          align: "center",
          value: "operator",
        },
        {
          text: "Valores",
          align: "center",
          value: "values",
        },
      ],
    };
  },
  computed: {
    validFilters() {
      return this.customFilters.filter(
        (filter) => filter.operator && filter.values.length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  position: absolute;
  right: 16px;
}
</style>
