<template>
  <div>
    <Check-size-main
      :type="'product'"
      :externalLoading="isLoadingProduct"
      class="mr-4"
      @openModal="$refs.emailModal.open('product')"
      @getFile="getFile('product', null)"
    />
    <Check-size-main
      :type="'history'"
      :externalLoading="isLoadingHistory"
      @openModal="$refs.emailModal.open('history')"
      @getFile="getFile('history', null)"
    />
    <Email-modal
      ref="emailModal"
      @getFile="getFile($event.type, $event.email)"
    />
  </div>
</template>

<script>
import CheckSizeMain from "./components/check-size-main.vue";
import EmailModal from "./components/email-modal.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
import { downloadXlsx } from "@/utils/downloadData";
export default {
  components: { CheckSizeMain, EmailModal },
  data() {
    return {
      isLoadingProduct: false,
      isLoadingHistory: false,
      service: new PredimonitorService(),
    };
  },
  computed: {
    lang() {
      const langByLocalStorage = localStorage.getItem("lang");
      const lang =
        langByLocalStorage != "undefined" ? langByLocalStorage : "pt-BR";
      return lang;
    },
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
  },
  methods: {
    async getFile(type, email) {
      this.setLoading(type, true);
      const payload = this.prepareRequest(email);
      const method = this.getFileMethod(type);
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Produtos",
        name: `Relatório / ${method}`,
        payload,
      });
      try {
        const response = await this.service[method](payload);
        this.$store.commit("endRequest", { id: requestId, response });
        if (!email) this.handleDownload(method, response?.data ?? null);
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, this.$i18n.t("TXT_GET_REPORT_ERROR"));
      } finally {
        this.setLoading(type, false);
      }
    },

    setLoading(type, value) {
      if (type == "product") this.isLoadingProduct = value;
      else if (type == "history") this.isLoadingHistory = value;
    },

    getFileMethod(type) {
      return type == "product"
        ? "searchProductReport"
        : "searchPriceHistoryReport";
    },

    prepareRequest(email) {
      const request = structuredClone(this.lastRequest);
      request.language = this.lang;
      request.sendEmail = email != null;
      if (email) request.email = email;
      return request;
    },

    handleDownload(method, data) {
      if (!data) return;

      const key =
        method == "searchProductReport"
          ? "TXT_PRODUCT_REPORT"
          : "TXT_PRODUCT_HISTORY";

      downloadXlsx(data, key);
    },
  },
};
</script>
