<template>
  <Section-card :title="$t('TXT_MANAGE_FILTERS')">
    <template slot="content">
      <Crawler-select
        :companyId="companyId"
        @updateCrawlerId="crawlerId = $event"
        @crawlerList="crawlerList = $event"
        class="my-4"
      />
      <Handle-filter
        :companyId="companyId"
        :crawlerId="crawlerId"
        :customFilters="customFilters"
        :externalLoading="isLoading"
      />
      <Filters-table
        :isLoading="isLoading"
        :items="customFilters"
        @remove="remove"
        @edit="edit"
        class="mb-4"
      />

      <Handle-actions
        :notHasChanges="notHasChanges"
        :crawlerList="crawlerList"
        :crawlerId="crawlerId"
        :companyId="companyId"
        :changedFilterList="changedFilterList"
        :customFilters="customFilters"
        @cancelEditions="cancelEditions"
        @parOriginalCustomFilter="parOriginalCustomFilter"
      />
    </template>
  </Section-card>
</template>

<script>
import SectionCard from "@/views/main/general-system-configs/common-use-components/section-card.vue";
import FiltersTable from "./components/filters-table.vue";
import ConfigFiltersPredimonitorService from "@/service/predimonitor-v3/config-filters-predimonitor-service-v3";
import CrawlerSelect from "./components/crawler-select.vue";
import HandleFilter from "./components/handle-filter/handle-filter-main.vue";
import HandleActions from "./components/actions/handle-actions.vue";
export default {
  props: {
    companyId: { type: Number, default: 0 },
  },
  components: {
    SectionCard,
    FiltersTable,
    CrawlerSelect,
    HandleFilter,
    HandleActions,
  },
  data() {
    return {
      service: new ConfigFiltersPredimonitorService(),
      isLoading: false,
      crawlerList: [],
      crawlerId: null,
      customFilters: [],
      originalCustomFilters: [],
      removedFilters: [],
      currentFilter: null
    };
  },
  watch: {
    crawlerId: {
      handler(value) {
        if (value) this.getCompanyCustomFilters();
      },
      immediate: true,
    },
    companyId: {
      handler(value) {
        if (value) this.resetValues();
      },
    },
  },
  computed: {
    notHasChanges() {
      return (
        JSON.stringify(this.customFilters) ==
        JSON.stringify(this.originalCustomFilters)
      );
    },
    changedFilterList() {
      const changedFilters = this.customFilters.filter((modified) => {
        const original = this.originalCustomFilters.find(
          (original) => original.field === modified.field
        );
        return (
          modified.operator !== original.operator ||
          JSON.stringify(modified.values) !== JSON.stringify(original.values)
        );
      });

      return [...changedFilters, ...this.removedFilters];
    },
  },
  methods: {
    async getCompanyCustomFilters() {
      this.isLoading = true;
      try {
        const response = await this.service.getCompanyCustomFilters(
          this.companyId,
          this.crawlerId,
          this.lang
        );
        const filters = response?.data ?? [];
        this.customFilters = structuredClone(filters);
        this.originalCustomFilters = structuredClone(filters);
      } catch (error) {
        this.$handleError(
          error,
          this.$i18n.t("TXT_FETCH_CUSTOM_FILTERS_ERROR")
        );
      } finally {
        this.isLoading = false;
      }
    },
    resetValues() {
      this.crawlerId = null;
      this.customFilters = [];
      this.originalCustomFilters = [];
      this.removedFilters = [];
    },
    remove(currentFilter) {
      const index = this.customFilters.findIndex(
        (filter) => JSON.stringify(filter) == JSON.stringify(currentFilter)
      );
      this.customFilters.splice(index, 1);
      this.removedFilters.push({ ...currentFilter, action: "remove" });
    },
    cancelEditions() {
      this.customFilters = structuredClone(this.originalCustomFilters);
      this.removedFilters = [];
    },
    parOriginalCustomFilter() {
      this.originalCustomFilters = structuredClone(this.customFilters);
      this.removedFilters = [];
    },
    edit(filter) {
      console.log(filter);
    },
  },
};
</script>
