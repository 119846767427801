import { render, staticRenderFns } from "./base-card.vue?vue&type=template&id=efb5c436&scoped=true"
import script from "./base-card.vue?vue&type=script&lang=js"
export * from "./base-card.vue?vue&type=script&lang=js"
import style0 from "./base-card.vue?vue&type=style&index=0&id=efb5c436&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efb5c436",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VProgressCircular,VRow})
