<template>
  <v-skeleton-loader v-if="isLoading" type="table-thead,table-tbody" />
  <v-data-table v-else :headers="headers" :items="formattedData" />
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t("TXT_PRODUCT"), value: "description", align: "center" },
        { text: this.$t("TXT_MAXIMUM_PRICE"), value: "max", align: "center" },
        { text: this.$t("TXT_UPPER_QUARTILE"), value: "q3", align: "center" },
        { text: this.$t("TXT_MEDIAN"), value: "median", align: "center" },
        { text: this.$t("TXT_LOWER_QUARTILE"), value: "q1", align: "center" },
        { text: this.$t("TXT_MIN_PRICE"), value: "min", align: "center" },
        { text: this.$t("TXT_AVERAGE_PRICE"), value: "avg", align: "center" },
        {
          text: this.$t("TXT_MOST_FREQUENT_PRICE"),
          value: "mostFrequently",
          align: "center",
        },
      ],
    };
  },
  computed: {
    formattedData() {
      return this.items.map((item) => ({
        description: item.description,
        max: formatMonetary(item.max),
        q3: formatMonetary(item.q3),
        median: formatMonetary(item.median),
        q1: formatMonetary(item.q1),
        min: formatMonetary(item.min),
        avg: formatMonetary(item.avg),
        mostFrequently: formatMonetary(item.mostFrequently),
      }));
    },
  },
};
</script>
