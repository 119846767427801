import { render, staticRenderFns } from "./capture-card.vue?vue&type=template&id=075d3d43"
import script from "./capture-card.vue?vue&type=script&lang=js"
export * from "./capture-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VRow})
