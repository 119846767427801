<template>
  <Table-loading v-if="isLoading" />
  <v-data-table
    v-else
    :headers="headers"
    :items="items"
    :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
  >
    <template v-slot:[`item.uf`]="{ item }">
      <span class="clickable" @click="$emit('setSelectedState', item.uf)">{{
        item.uf ? item.uf.toUpperCase() : $t("TXT_UNKNOWN")
      }}</span>
    </template>

    <template v-slot:[`item.city`]="{ item }">
      {{ item.city ? item.city.toUpperCase() : $t("TXT_UNKNOWN") }}
    </template>

    <template v-slot:[`item.avgPrice`]="{ item }">
      {{ formatMonetary(item.avgPrice) }}
    </template>
  </v-data-table>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
import TableLoading from "./components/table-loading.vue";
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    rawData: {
      type: Object,
      default: () => ({}),
    },
    selectedState: {
      type: String,
      default: null,
    },
  },
  components: {
    TableLoading,
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t("TXT_STATE"), value: "uf", align: "center" },
        {
          text: this.$i18n.t("TXT_AVERAGE_PRICE"),
          value: "avgPrice",
          align: "center",
        },
        {
          text: this.$i18n.t("TXT_AMOUNT_STORES"),
          value: "sellerQuantity",
          align: "center",
        },
        {
          text: this.$i18n.t("TXT_AMOUNT_ADS"),
          value: "adsQuantity",
          align: "center",
        },
      ],
    };
  },
  computed: {
    items() {
      if (this.selectedState)
        return this.rawData?.states.flatMap((item) => item.cities) ?? [];
      else return this.rawData?.states ?? [];
    },
  },
  watch: {
    selectedState: {
      handler(value) {
        this.headers[0].text = this.$i18n.t(value ? "TXT_CITY" : "TXT_STATE");
        this.headers[0].value = value ? "city" : "uf";
      },
    },
  },
  methods: {
    formatMonetary,
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  color: #3c5ca7;
  &:hover {
    font-size: large;
    font-weight: bold;
    transition: 0.2s;
  }
}
</style>
