<template>
  <Expansive-panel :title="$t('TXT_PRODUCTS_UPPERCASE')" :disabled="isDisabled">
    <template v-slot:header-right-actions>
      <Reports />
    </template>

    <template v-slot:content>
      <Product-list ref="productList" />
    </template>
  </Expansive-panel>
</template>

<script>
import ExpansivePanel from "../common/expansive-panel.vue";
import ProductList from "./components/product-list-main.vue";
import Reports from "./components/reports/reports-main.vue";

export default {
  components: { ExpansivePanel, ProductList, Reports },
  data() {
    return {
      isDisabled: true,
    };
  },
  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
  },
  watch: {
    lastRequest: {
      handler(value) {
        if (value) this.fetchInitialData();
      },
      deep: true,
    },
  },
  methods: {
    fetchInitialData() {
      if (this.$refs.productList) {
        this.isDisabled = false;
        this.$refs.productList.getInitialPage();
      }
    },
    reStartComponent() {
      this.isDisabled = true;
      this.$store.commit("cleanDataInt");
      this.$store.commit("cleanDatasExt");
    },
  },
};
</script>
