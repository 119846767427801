import axiosInstance, { API_URL_PREDIMONITOR } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class PredimonitorService extends ServiceBase {
  async setCrawlerRoutine(request) {
    return await axiosInstance.get(
      `${API_URL_PREDIMONITOR}api/DynamicFilter/GenerateCache`,
      {
        params: {
          companyId: request.idCompany,
          startDate: request.startDate,
          endDate: request.endDate,
          refDate: request.refDate,
          skip: request.skipItems,
          removeOlds: request.removeOlds,
          removeOutliers: request.removeOutliers,
        },
      }
    );
  }

  // Todo: Colocar codumentação
  async getDisregardedList(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/ProductPrice/SearchIgnoredPrices`,
      request
    );
  }

  // Todo: Colocar codumentação
  async getFilters(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/filter`,
      request
    );
  }

  // Todo: Colocar codumentação
  async getProductListPaginated(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/product`,
      request
    );
  }

  // Todo: Colocar codumentação
  async getSellerListPaginated(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/productseller`,
      request
    );
  }

  async searchProductComparisonData(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/product/searchproductcomparisondata`,
      request
    );
  }

  async searchPriceTrend(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/product/searchpricetrend`,
      request
    );
  }

  // Todo: Colocar codumentação
  async setDisregardItem(ignore, companyId, priceId) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/ProductPrice/Ignore?ignore=${ignore}&companyId=${companyId}&priceId=${priceId}`
    );
  }

  // Todo: Colocar codumentação
  async setCurrentPrice(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/Product/SaveCompanyProductPrice`,
      request
    );
  }

  // Todo: Colocar codumentação
  async searchMapData(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/Product/SearchMapData`,
      request
    );
  }

  // Todo: Colocar codumentação
  async checkProductReportSize(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/Product/CheckProductReportSize`,
      request
    );
  }

  // Todo: Colocar codumentação
  async checkProductPriceHistoryReportSize(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/Product/CheckProductPriceHistoryReportSize`,
      request
    );
  }

  // Todo: Colocar codumentação
  async searchPriceHistoryReport(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/Product/SearchPriceHistoryReport`,
      request,
      { responseType: "blob" }
    );
  }

  // Todo: Colocar codumentação
  async searchProductReport(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/Product/SearchProductReport`,
      request,
      { responseType: "blob" }
    );
  }

   // Todo: Colocar codumentação
   async searchCompetitivenessIndexData(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/Product/SearchCompetitivenessIndexData`,
      request
    );
  }
}
