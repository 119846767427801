<template>
  <v-card flat color="transparent" class="pa-4">
    <v-row no-gutters justify="center" align="center" class="mb-4">
      <h4 class="mr-1">{{ $t(titleKey) }}</h4>
      <Prd-tooltip
        v-if="!useInToolTip"
        :text="$t('TXT_COMPETITIVENESS_CARD')"
      />
    </v-row>
    <Loading-main v-if="isLoading" />
    <div v-else>
      <Chart-main :ci="ci" :height="heightThermometer" class="mb-4" />
      <Description-main :ci="ci" />
    </div>
  </v-card>
</template>

<script>
import LoadingMain from "./components/loading-main.vue";
import ChartMain from "./components/chart-main.vue";
import PrdTooltip from "@/components/common/prd-tooltip.vue";
import DescriptionMain from "./components/description-main.vue";

export default {
  props: {
    isLoading: { type: Boolean, default: false },
    ci: { type: Number, default: 0 },
    useInToolTip: { type: Boolean, default: false },
  },
  components: {
    LoadingMain,
    ChartMain,
    PrdTooltip,
    DescriptionMain,
  },

  computed: {
    titleKey() {
      return this.useInToolTip
        ? "TXT_CI_UPPERCASE"
        : "TXT_AVERAGE_COMPETITIVENESS";
    },
    heightThermometer() {
      return this.useInToolTip ? 220 : 310;
    },
  },
};
</script>
