import axiosInstance, { API_URL_PREDIMONITOR } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class ConfigFiltersPredimonitorService extends ServiceBase {
  async getFilterFields(language) {
    return await axiosInstance.get(
      `${API_URL_PREDIMONITOR}api/filter/GetFilterFields`,
      {
        params: {
          language: language,
        },
      }
    );
  }

  async getCompanyFilterOptions(companyId, filterType, sourceId) {
    return await axiosInstance.get(
      `${API_URL_PREDIMONITOR}api/filter/GetCompanyFilterOptions`,
      {
        params: {
          companyId,
          filterType,
          sourceId,
        },
      }
    );
  }

  async getCompanyCustomFilters(companyId, sourceId, language) {
    return await axiosInstance.get(
      `${API_URL_PREDIMONITOR}api/filter/GetCompanycustomFilters`,
      {
        params: {
          companyId,
          sourceId,
          language,
        },
      }
    );
  }

  async saveCompanyCrawlerFilters(request) {
    return await axiosInstance.post(
      `${API_URL_PREDIMONITOR}api/filter/SaveCompanyCrawlerFilters`,
      request
    );
  }
}
