import { render, staticRenderFns } from "./table-result.vue?vue&type=template&id=4c6b036d&scoped=true"
import script from "./table-result.vue?vue&type=script&lang=js"
export * from "./table-result.vue?vue&type=script&lang=js"
import style0 from "./table-result.vue?vue&type=style&index=0&id=4c6b036d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c6b036d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCol,VCombobox,VExpansionPanels,VRow,VSkeletonLoader})
