import { render, staticRenderFns } from "./mix-products-table.vue?vue&type=template&id=e5f5e7c2&scoped=true"
import script from "./mix-products-table.vue?vue&type=script&lang=js"
export * from "./mix-products-table.vue?vue&type=script&lang=js"
import style0 from "./mix-products-table.vue?vue&type=style&index=0&id=e5f5e7c2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5f5e7c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
