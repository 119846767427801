<template>
  <v-row no-gutters justify="space-between">
    <v-col cols="auto">
      <Prd-tabs
        :configs="tabsConfig"
        :isLoading="isLoading"
        @changedTab="$emit('changedTab', $event)"
      />
    </v-col>
    <v-col cols="auto">
      <Prd-combo-box
        v-model="selectedProduct"
        :label="$t('TXT_ALL_PRODUCTS')"
        :loading="isLoading"
        :disabled="isLoading"
        :items="products"
      />
    </v-col>
  </v-row>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdTabs from "@/components/common/prd-tabs.vue";

export default {
  props: {
    isLoading: { type: Boolean, default: false },
    rawData: { type: Object, default: () => ({}) },
  },
  components: {
    PrdTabs,
    PrdComboBox,
  },
  data() {
    return {
      tabsConfig: {
        title: this.$i18n.t("TXT_VISUALIZATION"),
        centerActive: true,
        showArrows: true,
        tabs: [
          {
            name: this.$i18n.t("TXT_GRAPHIC"),
          },
          {
            name: this.$i18n.t("TXT_LIST"),
          },
        ],
      },
      selectedProduct: this.$i18n.t("TXT_ALL_PRODUCTS"),
    };
  },
  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
    products() {
      const list = [this.$i18n.t("TXT_ALL_PRODUCTS")];
      const foundedProducts =
        this.$store?.getters?.listOfProductsHaveLatestPrice.map(
          (item) => item.name
        ) ?? [];
      list.push(...foundedProducts);
      return list;
    },
  },
  watch: {
    selectedProduct: {
      handler(value) {
        this.$emit(
          "selectedProduct",
          value == this.$i18n.t("TXT_ALL_PRODUCTS") ? null : value
        );
      },
      immediate: true,
    },
    lastRequest: {
      handler() {
        this.selectedProduct = this.$i18n.t("TXT_ALL_PRODUCTS");
      },
      deep: true,
    },
  },
};
</script>
