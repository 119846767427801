<template>
  <div>
    <v-skeleton-loader
      v-show="isLoading"
      type="image,image,table-row,table-row"
    />
    <div v-show="!isLoading">
      <v-row
        v-if="showSelectMapType"
        no-gutters
        align="center"
        class="mb-2 gap-2"
      >
        <span class="font-weight-bold">Openlayers</span>
        <v-switch v-model="useMapBox" hide-details class="pa-0 ma-0" />
        <span class="font-weight-bold">MapBox</span>
      </v-row>
      <Map-mapbox
        v-if="useMapBox"
        :products="products"
        :selectedState="selectedState"
        :sellerTypes="sellerTypes"
        :randomColors="randomColors"
      />
      <Map-openlayers
        v-else
        :products="products"
        :selectedState="selectedState"
        :sellerTypes="sellerTypes"
        :randomColors="randomColors"
      />
      <Legend
        :sellerTypes="sellerTypes"
        :randomColors="randomColors"
        class="mt-2"
      />
    </div>
  </div>
</template>

<script>
import MapOpenlayers from "./components/map-openlayers.vue";
import Legend from "./components/legend-main.vue";
import MapMapbox from "./components/map-mapbox.vue";
import chroma from "chroma-js";
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    rawData: {
      type: Object,
      default: () => ({}),
    },
    selectedState: {
      type: String,
      default: null,
    },
  },
  components: {
    Legend,
    MapOpenlayers,
    MapMapbox,
  },

  data() {
    return {
      useMapBox: true,
    };
  },

  computed: {
    products() {
      return this.rawData?.products ?? [];
    },
    sellerTypes() {
      const types = this.products.map((item) =>
        item.type && item.type != "" ? item.type : this.$i18n.t("TXT_UNKNOWN")
      );

      const uniqueTypes = Array.from(new Set(types));

      return uniqueTypes;
    },

    randomColors() {
      const quantity = this.sellerTypes.length;
      return quantity
        ? Array.from({ length: quantity }, () => chroma.random().hex())
        : [];
    },
    enableSelectMapType() {
      return process.env.VUE_APP_SHOW_SELECT_MAP_TYPE;
    },

    showSelectMapType() {
      return this.enableSelectMapType == "true";
    },
  },
};
</script>
