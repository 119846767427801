<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-top-transition"
    max-width="600"
    :persistent="isLoading"
  >
    <template v-slot:activator="{ on, attrs }">
      <Prd-btn
        v-bind="attrs"
        v-on="on"
        :title="$t('TXT_SAVE')"
        :disabled="notHasChanges"
      />
    </template>

    <v-card class="pa-4">
      <v-row no-gutters justify="center" class="mb-3">
        <v-icon :color="$prdStyles('color-primary')" size="50">
          mdi-information-outline
        </v-icon>
        <v-icon
          :color="$prdStyles('color-primary')"
          :disabled="isLoading"
          @click="isOpen = false"
          class="close-button"
        >
          mdi-close
        </v-icon>
      </v-row>

      <h2 class="text-center mb-6">{{ $t("TXT_CONFIRM_CHANGES") }}</h2>

      <div class="content">
        <Prd-tabs
          :configs="tabConfig"
          :isLoading="isLoading"
          @changedTab="tabIndex = $event"
        />
        <div class="mb-4 mt-2">
          <p class="font-weight-bold" v-if="isRemoveFilter">
            {{ $t("TXT_RESET_FILTER_ALERT") }}
          </p>
          <p v-else>
            {{ $t("TXT_VALUES") }}:
            <span class="font-weight-bold">{{ currentValues }}</span>
          </p>
        </div>

        <v-row no-gutters align="center" class="mb-1">
          <p class="my-0 mr-2">{{ $t("TXT_REPLY_CRAWLERS_QUESTION") }}</p>
          <v-simple-checkbox
            v-model="reply"
            :disabled="crawlerList.length <= 1 || isLoading"
          />
        </v-row>

        <Prd-combo-box
          v-model="replyCrawlerList"
          multiple
          deletable-chips
          small-chips
          clearable
          :title="'Crawlers'"
          :items="crawlerList"
          :loading="isLoading"
          :disabled="crawlerList.length <= 1 || isLoading || !reply"
        />
      </div>

      <v-row no-gutters justify="end" class="mt-6 gap-4">
        <Prd-btn
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          :disabled="isLoading"
          @click="isOpen = false"
        />
        <Prd-btn
          :title="$t('TXT_CONFIRM')"
          :loading="isLoading"
          @click="saveChanges"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdTabs from "@/components/common/prd-tabs.vue";
import ConfigFiltersPredimonitorService from "@/service/predimonitor-v3/config-filters-predimonitor-service-v3";
export default {
  props: {
    notHasChanges: { type: Boolean, default: false },
    crawlerList: { type: Array, default: () => [] },
    companyId: { type: Number, default: 0 },
    crawlerId: { type: Number, default: 0 },
    changedFilterList: { type: Array, default: () => [] },
    customFilters: { type: Array, default: () => [] },
  },
  components: {
    PrdBtn,
    PrdComboBox,
    PrdTabs,
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      reply: false,
      replyCrawlerList: [],
      service: new ConfigFiltersPredimonitorService(),
      tabIndex: 0,
      simpleFilters: ["Region", "Segments"],
    };
  },
  computed: {
    tabConfig() {
      return {
        title: this.$i18n.t("TXT_FILTER_2"),
        centerActive: true,
        showArrows: true,
        backgroundColor: "#F5F5F5",
        tabs: this.changedFilterList.map(({ text }) => ({ name: text })),
      };
    },
    currentValues() {
      return (
        this.changedFilterList[this.tabIndex]?.values
          .toString()
          .replace(/,/g, ", ") ?? "-"
      );
    },
    isRemoveFilter() {
      return this.changedFilterList[this.tabIndex]?.action == "remove" ?? false;
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (!value) {
          this.reply = false;
          this.replyCrawlerList = [];
          this.tabIndex = 0;
        }
      },
      immediate: true,
    },
    crawlerId: {
      handler(value) {
        this.setSelectedCrawler(value);
      },
      immediate: true,
    },
  },
  methods: {
    setSelectedCrawler(id) {
      this.reply = false;
      this.replyCrawlerList = [];
      if (!id) return;
      else {
        const founded = structuredClone(
          this.crawlerList.find((crawler) => crawler.crawlerId === id)
        );
        if (founded) this.replyCrawlerList = [founded];
      }
    },

    async saveChanges() {
      this.isLoading = true;

      try {
        const request = this.prepareRequest();
        await this.service.saveCompanyCrawlerFilters(request);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SAVE_CUSTOM_FILTERS_SUCESS"),
          type: "success",
        });
        this.$emit("parOriginalCustomFilter");
        this.isOpen = false;
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_SAVE_CUSTOM_FILTERS_ERROR"));
      } finally {
        this.isLoading = false;
      }
    },
    prepareRequest() {
      const list = [];
      const crawlerListId = this.crawlerList.map(
        (crawler) => crawler.crawlerId
      );
      crawlerListId.forEach((id) => {
        this.customFilters.forEach(({ field, operator, values }) => {
          list.push({
            companyId: this.companyId,
            crawlerId: id,
            field,
            values,
            operator: this.isSimpleFilter(field) ? "IN" : operator,
          });
        });
      });
      return list;
    },
    isSimpleFilter(field) {
      return this.simpleFilters.includes(field);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  position: absolute;
  right: 16px;
}
.content {
  background-color: $neutral-color-high-light;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
}
</style>
