<template>
  <div ref="mapContainer" class="mapContainer" />
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
mapboxgl.accessToken = process.env.VUE_APP_MAP_BOX_KEY;

import { formatMonetary } from "@/utils/format-toMonetary";
import { getBrazilianStateData } from "../../../utils/brazilian-states";
let map = null;
let markers = [];
export default {
  props: {
    products: { type: Array, default: () => [] },
    selectedState: {
      type: String,
      default: null,
    },
    sellerTypes: { type: Array, default: () => [] },
    randomColors: { type: Array, default: () => [] },
  },

  watch: {
    products: {
      handler() {
        this.updateMarkers();
      },
      deep: true,
    },
    selectedState: {
      handler(value) {
        this.setMapCenter(value);
      },
      immediate: true,
    },
  },
  methods: {
    initMap() {
      map = null;
      map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [-54.90069256788429, -13.925873723622928],
        zoom: 2.5,
        attributionControl: false,
      });

      map.on("load", () => {
        map.resize();
      });

      this.cleanMarkers();
    },
    updateMarkers() {
      this.cleanMarkers();
      if (!map) return;
      this.products.forEach((item) => {
        item.longitude = parseFloat(item.longitude);
        item.latitude = parseFloat(item.latitude);
        const marker = new mapboxgl.Marker({
          color: this.getColorByType(item.type),
        })
          .setLngLat([item.longitude, item.latitude])
          .setPopup(new mapboxgl.Popup().setHTML(this.getContentPopup(item)))
          .addTo(map);

        markers.push(marker);
      });
    },
    cleanMarkers() {
      markers.forEach((marker) => marker.remove());
      markers = [];
    },
    getColorByType(type) {
      const index = this.sellerTypes.findIndex((item) => item == type);
      return index != -1 ? this.randomColors[index] : "black";
    },
    getContentPopup(item) {
      return `<h3>${item.name}</h3>
              <br>
              <strong>${this.$i18n.t("TXT_TYPE")}:</strong> ${
        item?.type != "" ? item.type : this.$i18n.t("TXT_UNKNOWN")
      }
              <br>
              <strong>${this.$i18n.t("TXT_PRICE")}:</strong> ${formatMonetary(
        item.price
      )}
              <br>
              <strong>${this.$i18n.t("TXT_ADDRESS")}:</strong> ${item.address}
              <br>
        ${
          item.amountAnnouncement > 1
            ? `<strong>${this.$i18n.t("TXT_ANNOUCEMENT")}:</strong> ` +
              item.amountAnnouncement
            : ""
        }`;
    },
    setMapCenter(state) {
      if (!map) return;

      let coordinates = [-54.90069256788429, -13.925873723622928];
      let zoom = 2.5;

      if (state) {
        const { longitude, latitude } = getBrazilianStateData(state);
        coordinates = [longitude, latitude];
        zoom = 5.5;
      }

      map.setCenter(coordinates);
      map.setZoom(zoom);
    },
  },
  mounted() {
    this.initMap();
    this.updateMarkers();
    this.setMapCenter(this.selectedState);
  },
};
</script>

<style scoped>
.mapContainer {
  width: 100%;
  height: 50vh;
}
</style>
