const brazilianStates = [
  { name: "Acre", abbreviation: "AC", latitude: -9.0238, longitude: -70.812 },
  {
    name: "Alagoas",
    abbreviation: "AL",
    latitude: -9.5713,
    longitude: -36.782,
  },
  { name: "Amapá", abbreviation: "AP", latitude: 0.902, longitude: -52.003 },
  {
    name: "Amazonas",
    abbreviation: "AM",
    latitude: -3.4168,
    longitude: -65.8561,
  },
  {
    name: "Bahia",
    abbreviation: "BA",
    latitude: -12.5797,
    longitude: -41.7007,
  },
  { name: "Ceará", abbreviation: "CE", latitude: -5.4984, longitude: -39.3206 },
  {
    name: "Distrito Federal",
    abbreviation: "DF",
    latitude: -15.7939,
    longitude: -47.8828,
  },
  {
    name: "Espírito Santo",
    abbreviation: "ES",
    latitude: -19.1834,
    longitude: -40.3089,
  },
  { name: "Goiás", abbreviation: "GO", latitude: -15.827, longitude: -49.8362 },
  {
    name: "Maranhão",
    abbreviation: "MA",
    latitude: -4.9609,
    longitude: -45.2744,
  },
  {
    name: "Mato Grosso",
    abbreviation: "MT",
    latitude: -12.6819,
    longitude: -56.9211,
  },
  {
    name: "Mato Grosso do Sul",
    abbreviation: "MS",
    latitude: -20.7722,
    longitude: -54.7852,
  },
  {
    name: "Minas Gerais",
    abbreviation: "MG",
    latitude: -18.5122,
    longitude: -44.555,
  },
  { name: "Pará", abbreviation: "PA", latitude: -3.4168, longitude: -52.2468 },
  {
    name: "Paraíba",
    abbreviation: "PB",
    latitude: -7.2399,
    longitude: -36.7819,
  },
  {
    name: "Paraná",
    abbreviation: "PR",
    latitude: -24.8916,
    longitude: -51.6909,
  },
  {
    name: "Pernambuco",
    abbreviation: "PE",
    latitude: -8.8137,
    longitude: -36.9541,
  },
  { name: "Piauí", abbreviation: "PI", latitude: -7.7183, longitude: -42.7289 },
  {
    name: "Rio de Janeiro",
    abbreviation: "RJ",
    latitude: -22.9099,
    longitude: -43.2095,
  },
  {
    name: "Rio Grande do Norte",
    abbreviation: "RN",
    latitude: -5.7945,
    longitude: -36.3209,
  },
  {
    name: "Rio Grande do Sul",
    abbreviation: "RS",
    latitude: -30.0346,
    longitude: -51.2177,
  },
  {
    name: "Rondônia",
    abbreviation: "RO",
    latitude: -10.8253,
    longitude: -63.3374,
  },
  {
    name: "Roraima",
    abbreviation: "RR",
    latitude: 2.7376,
    longitude: -62.0751,
  },
  {
    name: "Santa Catarina",
    abbreviation: "SC",
    latitude: -27.2423,
    longitude: -50.2189,
  },
  {
    name: "São Paulo",
    abbreviation: "SP",
    latitude: -23.5505,
    longitude: -46.6333,
  },
  {
    name: "Sergipe",
    abbreviation: "SE",
    latitude: -10.5741,
    longitude: -37.3857,
  },
  {
    name: "Tocantins",
    abbreviation: "TO",
    latitude: -10.1753,
    longitude: -48.2982,
  },
];

export const getBrazilianStateData = (term) => {
  if (!term) return null;
  if (term.length > 2)
    return brazilianStates.find((state) => state.name == term);
  else return brazilianStates.find((state) => state.abbreviation == term);
};
