<template>
  <HighChartsMain
    :type="'boxplot'"
    :isLoading="isLoading"
    :personalizedOptions="personalizedOptions"
  />
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
import HighChartsMain from "../../common/high-charts-main.vue";

export default {
  props: {
    isLoading: { type: Boolean, default: false },
    data: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    HighChartsMain,
  },
  data() {
    return {
      personalizedOptions: {
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          title: {
            text: this.$i18n.t("TXT_PRODUCT"),
          },
        },
        yAxis: {
          title: {
            text: this.$i18n.t("TXT_PRICE"),
          },
          labels: {
            formatter: function () {
              return `${formatMonetary(this.value)}`;
            },
          },
        },
        series: [{ data: [] }],
      },
    };
  },
  watch: {
    data: {
      handler(value) {
        this.fillChart(value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fillChart(data) {
      this.cleanChart();
      this.personalizedOptions.xAxis.categories = data.map(
        (item) => item.description
      );
      data.forEach((item, index) => {
        const outlier = this.prepareOutlier(item.outliers, index);
        this.personalizedOptions.series.push(outlier);
        this.personalizedOptions.series[0].data.push([
          item.min,
          item.q1,
          item.median,
          item.q3,
          item.max,
        ]);
      });
    },
    cleanChart() {
      this.personalizedOptions.xAxis.categories = [];
      this.personalizedOptions.series = [{ data: [] }];
    },
    prepareOutlier(list, index) {
      return {
        type: "scatter",
        data: list.map((element) => [index, element]),
        marker: {
          fillColor: "white",
          lineWidth: 1,
          lineColor: "#6792f8",
          symbol: "circle",
        },
      };
    },
  },
};
</script>
