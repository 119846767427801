import i18n from "@/i18n";
import * as XLSX from "xlsx";

/**
 * @param {Array<object>} data
 * @param {String} fileName
 */
export function downloadData(data, fileName) {
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(wb, ws, fileName);

  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function downloadPDF(data, fileName) {
  var downloadLink = document.createElement("a");
  var blob = new Blob([data]);
  var url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `${fileName}.pdf`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

/**
 * @param {File} file
 * @param {String} fileName
 */
export function downloadXlsx(file, fileName) {
  const transformedName = `${
    fileName.startsWith("TXT_") ? i18n.t(fileName) : fileName
  }.xlsx`;
  const blob = new Blob([file], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", transformedName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
