<template>
  <div class="mt-4">
    <PersonalizedFiltersV2
      v-if="false"
      :companyId="selectedCompany.idEmpresa"
      class="mb-4"
    />
    <Base-manager :companyId="selectedCompany.idEmpresa" class="mb-4" />
    <Personalized-filters
      v-if="false"
      :selectedCompany="selectedCompany"
      class="mb-4"
    />
  </div>
</template>

<script>
import BaseManager from "./components/base-manager/base-manager-main.vue";
import PersonalizedFiltersV2 from "./components/personalized-filters-v2/personalized-filters-v2.vue";
import PersonalizedFilters from "./components/personalized-filters/personalized-filters-main.vue";

export default {
  props: {
    selectedCompany: { type: Object, default: () => ({}) },
  },
  components: {
    BaseManager,
    PersonalizedFilters,
    PersonalizedFiltersV2,
  },
};
</script>
