<template>
  <v-expansion-panel-header color="#f5f5f5">
    <div class="mr-4">
      <v-row no-gutters justify="space-between" align="center">
        <v-col cols="auto">
          <Name-item :name="data.name" :isLoading="data.isLoading" />
        </v-col>
        <v-col cols="auto">
          <v-row no-gutters align="center" class="gap-2">
            <v-col cols="auto" class="pa-0">
              <Current-price-item
                :externalIsLoading="data.isLoading || isLoading"
                :price="data.latestPrice"
                :productIds="data.productIds"
                @refreshHeader="refreshHeader"
              />
            </v-col>
            <v-col cols="auto" class="pa-0" v-if="showCiTooltip">
              <Competitiveness-index-tooltip
                :ci="ci"
                :externalIsDisabled="data.isLoading || isLoading"
              />
            </v-col>
            <v-col cols="auto" class="pa-0">
              <Price-item
                :price="data.maxPrice"
                :icon="'mdi-arrow-up-bold-outline'"
                :color="$prdStyles('color-success')"
                :isLoading="data.isLoading || isLoading"
              />
            </v-col>
            <v-col cols="auto" class="pa-0">
              <Price-item
                :price="data.avgPrice"
                :icon="'mdi-minus-circle-outline'"
                :color="$prdStyles('color-primary')"
                :isLoading="data.isLoading || isLoading"
              />
            </v-col>
            <v-col cols="auto" class="pa-0">
              <Price-item
                :price="data.minPrice"
                :icon="'mdi-arrow-down-bold-outline'"
                :color="$prdStyles('color-warning')"
                :isLoading="data.isLoading || isLoading"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <template v-slot:actions>
      <v-icon :color="$prdStyles('color-primary')"> $expand </v-icon>
    </template>
  </v-expansion-panel-header>
</template>

<script>
import NameItem from "./components/name-item.vue";
import CurrentPriceItem from "./components/current-price-item.vue";
import PriceItem from "./components/price-item.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
import CompetitivenessIndexTooltip from "./components/competitiveness-index-tooltip.vue";
export default {
  components: {
    NameItem,
    CurrentPriceItem,
    PriceItem,
    CompetitivenessIndexTooltip,
  },

  props: {
    data: { type: Object, require: true },
  },
  data() {
    return {
      service: new PredimonitorService(),
      isLoading: false,
    };
  },
  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
    showCiTooltip() {
      return this.data?.competitivenessIndex > 0;
    },
    ci() {
      return this.data?.competitivenessIndex ?? 0;
    },
  },
  methods: {
    async refreshHeader() {
      this.isLoading = true;
      const payload = this.prepareRequest();
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Produtos",
        name: "Refresh Header",
        payload,
      });
      try {
        const response = await this.service.getProductListPaginated(payload);
        this.$store.commit("endRequest", { id: requestId, response });
        const product = response?.data?.products?.[0] ?? null;
        this.$store.commit("updateProductExt", {
          ...product,
          productIds: this.data.productIds,
        });
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, this.$i18n.t("TXT_ERROR_UPDATE_PRODUCT_DATA"));
      } finally {
        this.isLoading = false;
      }
    },

    prepareRequest() {
      const request = structuredClone(this.lastRequest);
      request.take = 1;
      request.page = 1;
      request.productIds = this.data.productIds;
      delete request.productNames;
      return request;
    },
  },
};
</script>
