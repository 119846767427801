<template>
  <div>
    <Loading-produt-list v-if="isLoading" :quantity="10" />
    <v-expansion-panels
      v-else
      v-model="panels"
      multiple
      flat
      class="mb-4"
      loading
    >
      <h4 v-if="!currentPage.length">{{ $t("TXT_NO_DATA_AVAILABLE") }}</h4>
      <Product-panel
        v-for="data in currentPage"
        ref="panel"
        :data="data"
        :key="data.name"
      />
    </v-expansion-panels>
    <Prd-pagination
      ref="PrdPagination"
      :totalItems="totalItems"
      :currentAmountShowItems="currentAmountShowItems"
      :take="10"
      :isLoading="isLoading"
      :delayTextField="1500"
      @currentPage="handleProductList"
    />
    <Loading-products :isLoading="isOpenModalLoading" />
  </div>
</template>

<script>
import PrdPagination from "@/components/common/prd-pagination.vue";
import ProductPanel from "./product-panel/product-panel-main.vue";
import LoadingProdutList from "./product-panel/components/loading-produt-list.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
import LoadingProducts from "./loading-products.vue";
export default {
  components: {
    ProductPanel,
    PrdPagination,
    LoadingProdutList,
    LoadingProducts,
  },
  data() {
    return {
      panels: [],
      isLoading: false,
      isOpenModalLoading: false,
      service: new PredimonitorService(),
      totalItems: 0,
    };
  },
  computed: {
    currentAmountShowItems() {
      return this.currentPage?.length ?? 0;
    },

    lastRequest() {
      return this.$store.state.PredimonitorV3Store.lastRequest;
    },
    currentPage() {
      return (
        this.$store.state.PredimonitorV3Store
          .ProductTableExternalPaginationStore.current || []
      );
    },
  },
  methods: {
    getInitialPage() {
      this.closeAllPanels();
      this.$store.commit("updatePagesExt", null);
      this.$store.commit("setCurrentExt", null);
      if (this.$refs.PrdPagination)
        this.$refs.PrdPagination.reStartCurrentPage();
      this.fetchProducts(1);
    },
    handleProductList(page) {
      this.closeAllPanels();
      const isExistsPage = this.$store.getters.checkExistenceOfPageExt(page);
      if (isExistsPage) this.$store.commit("setExistentPageExt", page);
      else {
        this.fetchProducts(page);
      }
    },
    closeAllPanels() {
      if (this.$refs.panel)
        this.$refs.panel.forEach((panel) => {
          if (panel && typeof panel.close === "function") {
            panel.close();
          }
        });
      this.panels = [];
    },
    async fetchProducts(page) {
      this[page == 1 ? "isOpenModalLoading" : "isLoading"] = true;
      const payload = this.prepareRequest(page);
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Produtos",
        name: "Paginação Externa",
        payload,
      });
      try {
        const response = await this.service.getProductListPaginated(payload);
        this.$store.commit("endRequest", { id: requestId, response });
        const products = response.data.products ?? [];
        this.$store.commit("updatePagesExt", {
          page,
          data: products,
        });
        this.$store.commit("setCurrentExt", products);
        this.setTotalItems(payload.page, response.data);
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, this.$i18n.t("TXT_ERROR_SEARCH_PRODUCT_LIST"));
      } finally {
        this[page == 1 ? "isOpenModalLoading" : "isLoading"] = false;
      }
    },

    prepareRequest(page) {
      const request = structuredClone(this.lastRequest) ?? {};
      request.take = 10;
      request.page = page;
      return request;
    },

    setTotalItems(page, result) {
      if (result == "") this.totalItems = 0;
      if (page == 1) this.totalItems = result.totalCount;
    },
  },
};
</script>
