import { render, staticRenderFns } from "./granularity-item.vue?vue&type=template&id=380721da&scoped=true"
import script from "./granularity-item.vue?vue&type=script&lang=js"
export * from "./granularity-item.vue?vue&type=script&lang=js"
import style0 from "./granularity-item.vue?vue&type=style&index=0&id=380721da&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "380721da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel})
