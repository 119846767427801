<template>
  <Expansive-panel
    :title="$t('TXT_COMPETITIVENESS_INDEX')"
    :disabled="isDisabled || showDisclaimer"
  >
    <template v-slot:second-line>
      <Disclaimer-main v-if="showDisclaimer" />
    </template>
    <template v-slot:content>
      <v-row no-gutters>
        <v-col cols="3">
          <Thermometer-average :isLoading="isLoading" :ci="ci" />
        </v-col>
        <v-col cols>
          <Historic-main
            :isLoading="isLoading"
            :rawData="rawData"
            @selectedProduct="selectedProduct = $event"
          />
        </v-col>
      </v-row>
    </template>
  </Expansive-panel>
</template>

<script>
import ExpansivePanel from "../common/expansive-panel.vue";
import DisclaimerMain from "./components/disclaimer-main.vue";
import HistoricMain from "./components/historic/historic-main.vue";
import ThermometerAverage from "./components/thermometer/thermometer-average-main.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
export default {
  props: {
    isOpen: { type: Boolean, default: false },
  },
  components: {
    ExpansivePanel,
    DisclaimerMain,
    ThermometerAverage,
    HistoricMain,
  },
  data() {
    return {
      isDisabled: true,
      isLoading: false,
      rawData: null,
      selectedProduct: null,
      service: new PredimonitorService(),
    };
  },
  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
    refreshComponentsFlag() {
      return this.$store.state.PredimonitorStore.refreshComponentsFlag;
    },
    showDisclaimer() {
      return !this.$store.getters.checkExistenceOfSomePrice;
    },
    ci() {
      return (
        this.$store.getters.cIByProductName(this.selectedProduct)
          ?.competitivenessIndex ??
        this.rawData?.averageCompetitiveness ??
        0
      );
    },
    refreshCIFlag() {
      return (
        this.$store?.state?.PredimonitorStore
          ?.ProductTableExternalPaginationStore?.refreshCIFlag ?? false
      );
    },
  },
  watch: {
    lastRequest: {
      handler(value) {
        if (!value) return;
        this.isDisabled = false;
        this.refreshData();
      },
      deep: true,
    },
    refreshComponentsFlag() {
      this.refreshData();
    },

    isOpen() {
      this.fetchData();
    },
    refreshCIFlag() {
      this.refreshData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.isOpen || this.rawData != null || this.isLoading) return;
      this.isLoading = true;
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Índice de competitividade",
        name: "searchCompetitivenessIndexData",
        payload: this.lastRequest,
      });
      try {
        const response = await this.service.searchCompetitivenessIndexData(
          this.lastRequest
        );
        this.rawData = response?.data ?? null;
        this.$store.commit("endRequest", { id: requestId, response });
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, this.$i18n.t("TXT_SEARCH_CI_DATA_ERROR"));
      } finally {
        this.isLoading = false;
      }
    },
    refreshData() {
      this.rawData = null;
      this.fetchData();
    },
    reStartComponent() {
      this.isDisabled = true;
      this.isLoading = false;
      this.rawData = null;
      this.selectedProduct = null;
    },
  },
};
</script>
