var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody"}}):_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.items,"id":"competitiveness-index-table"},scopedSlots:_vm._u([{key:"item.latestPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.latestPrice))+" ")]}},{key:"item.avgPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.avgPrice))+" ")]}},{key:"item.competitivenessIndex",fn:function(ref){
var item = ref.item;
return [_c('td',{class:[
        'text-center',
        'font-weight-bold',
        'pa-0',
        _vm.setBackgroundColor(item.competitivenessIndex) ]},[_vm._v(" "+_vm._s(_vm.setCI(item.competitivenessIndex))+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }