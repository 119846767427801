<template>
  <div>
    <PrdTextField
      v-model="decimalValue"
      title="Casas decimais"
      class="my-6"
      type="number"
      style="max-width: 150px"
    />

    <v-row no-gutters>
      <v-col cols="6">
        <Header-table />
        <Line-table
          :originalList="values"
          :decimalValue="decimalValue"
          @updatePsycologicPriceConditions="updatePsycologicPriceConditions"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import HeaderTable from "./components/header-table.vue";
import LineTable from "./components/line-table.vue";
export default {
  components: { HeaderTable, PrdTextField, LineTable },
  props: ["values"],
  data: () => ({
    decimalValue: 2,
  }),

  watch: {
    values: {
      handler(values) {
        const hasDecimal = values.find(
          (el) => el.stringValue == "decimalPlaces"
        );
        this.decimalValue = hasDecimal?.decimalValue ?? 2;
      },
      immediate: true,
    },
    decimalValue() {
      this.emitConditions(this.values);
    },
  },

  methods: {
    updatePsycologicPriceConditions(data) {
      const newList = this.values;
      const foundedItem = newList.find(
        (item) => item.idConditionValue == data.id
      );
      foundedItem.decimalValue = parseFloat(data.value);

      this.emitConditions(newList);
    },

    emitConditions(list) {
      this.$emit("updateConditionValues", {
        formatedValue: list,
        conditionType: 6,
        decimalValue: this.decimalValue,
      });
    },
  },

  created() {
    this.emitConditions(this.values);
  },
};
</script>
