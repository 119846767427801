<template>
  <v-expansion-panels multiple flat>
    <Product-panel v-for="i in quantity" :key="i" :data="{ isLoading: true }" />
  </v-expansion-panels>
</template>

<script>
import ProductPanel from "../product-panel-main.vue";
export default {
  props: { quantity: { type: Number, default: 0 } },

  components: {
    ProductPanel,
  },
};
</script>
