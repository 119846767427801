<template>
  <div>
    <v-row no-gutters class="my-4">
      <v-col cols="12" md="3"
        ><Prd-text-field
          v-model="model.MinVariation"
          :title="$t('TXT_MIN_VARIATION')"
          :type="'number'"
          hide-spin-buttons
      /></v-col>
    </v-row>
    <v-checkbox
      v-model="model.AdjustPrice"
      :label="$t('TXT_ADJUST_PRICE')"
      hide-details
      dense
      class="ma-0 pa-0"
    ></v-checkbox>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  props: ["values"],
  components: { PrdTextField },
  data() {
    return {
      model: {
        AdjustPrice: false,
        MinVariation: 0,
      },
    };
  },

  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "AdjustPrice",
          decimalValue: 0,
          booleanValue: inputs.AdjustPrice,
        },
        {
          stringValue: "MinVariation",
          decimalValue: parseFloat(inputs.MinVariation) ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "InvertBlock",
          decimalValue: 0,
          booleanValue: false,
        },
      ];

      this.$emit("updateConditionValues", { formatedValue, conditionType: 14 });
    },
    setInputs(conditionList) {
      const AdjustPrice = conditionList.find(
        (condition) => condition.stringValue == "AdjustPrice"
      ).booleanValue;
      this.model.AdjustPrice = AdjustPrice;

      const MinVariation = conditionList.find(
        (condition) => condition.stringValue == "MinVariation"
      ).decimalValue;
      this.model.MinVariation = MinVariation;
    },
  },

  created() {
    if (this.values.length > 0) this.setInputs(this.values);
  },
};
</script>
