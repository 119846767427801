<template>
  <v-row no-gutters align="center">
    <v-icon :color="color" small class="mr-1">{{ icon }}</v-icon>

    <v-skeleton-loader v-if="isLoading" type="button" height="20" />
    <span v-else class="font-weight-bold">{{ formatMonetary(price) }}</span>
  </v-row>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
export default {
  props: {
    icon: { type: String, default: "mdi-circle" },
    color: {
      type: String,
      default: function () {
        return this.$prdStyles("color-primary");
      },
    },
    price: { type: Number, require: true },
    isLoading: { type: Boolean, default: false },
  },
  methods: {
    formatMonetary,
  },
};
</script>
