import { render, staticRenderFns } from "./informative-table.vue?vue&type=template&id=bde63c1a"
import script from "./informative-table.vue?vue&type=script&lang=js"
export * from "./informative-table.vue?vue&type=script&lang=js"
import style0 from "./informative-table.vue?vue&type=style&index=0&id=bde63c1a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VDataTable,VIcon,VTextField})
