import { render, staticRenderFns } from "./copilot-header.vue?vue&type=template&id=77d51a1c&scoped=true"
import script from "./copilot-header.vue?vue&type=script&lang=js"
export * from "./copilot-header.vue?vue&type=script&lang=js"
import style0 from "./copilot-header.vue?vue&type=style&index=0&id=77d51a1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d51a1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDivider,VIcon,VRow})
