<template>
  <div>
    <v-row
      v-for="(type, index) in sellerTypes"
      :key="index"
      no-gutters
      align="center"
    >
      <v-icon :color="randomColors[index]" class="mr-1"
        >mdi-circle-medium</v-icon
      >
      <p class="text-captalize ma-0">{{ type }}</p>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    sellerTypes: { type: Array, default: () => [] },
    randomColors: { type: Array, default: () => [] },
  },
};
</script>
