import { v4 as uuidV4 } from "uuid";
const RequestViewerModalStore = {
  state: { list: [] },
  getters: {
    getSectionList: (state) => state.list.map((item) => item.section) ?? [],
    getRequestNamesOfSection: (state) => (section) =>
      state.list
        .filter((item) => item.section == section)
        .map((item) => item.name) ?? [],
    getAllRequestOfSelection: (state) => (section, endPoint) =>
      state.list
        .filter((item) => item.section == section && item.name == endPoint)
        .sort((a, b) => b.startTime - a.startTime),
  },
  mutations: {
    resetRequestList(state) {
      state.list = [];
    },
    startRequest(state, payload) {
      const request = {
        ...payload,
        isLoading: true,
        startTime: new Date(),
        duration: Date.now(),
      };
      state.list.push(request);
    },
    endRequest(state, { id, response }) {
      const request = state.list.find((request) => request.id == id);
      if (!request) return;

      request.isLoading = false;
      request.url = response?.config?.url ?? "-";
      request.code = response?.status ?? response?.response?.status ?? "-";
      request.data = response?.data ?? response?.response?.data ?? "-";
      request.duration = `${(Date.now() - request.duration) / 1000} s`;
    },
  },
  actions: {
    startRequest({ commit }, payload) {
      const id = uuidV4();
      commit("startRequest", { ...payload, id });
      return id;
    },
  },
};

export default RequestViewerModalStore;
