import { render, staticRenderFns } from "./save-filters-modal.vue?vue&type=template&id=b1b28d2c&scoped=true"
import script from "./save-filters-modal.vue?vue&type=script&lang=js"
export * from "./save-filters-modal.vue?vue&type=script&lang=js"
import style0 from "./save-filters-modal.vue?vue&type=style&index=0&id=b1b28d2c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1b28d2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VDialog,VRow})
