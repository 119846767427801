import { render, staticRenderFns } from "./product-body.vue?vue&type=template&id=2cd72f25&scoped=true"
import script from "./product-body.vue?vue&type=script&lang=js"
export * from "./product-body.vue?vue&type=script&lang=js"
import style0 from "./product-body.vue?vue&type=style&index=0&id=2cd72f25&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cd72f25",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDataTable,VExpansionPanelContent,VIcon,VRow})
