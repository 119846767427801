const ProductTableExternalPaginationStore = {
  state: {
    pages: [],
    current: [],
    refreshCIFlag: false,
  },
  getters: {
    checkExistenceOfPageExt: (state) => (page) => {
      return state.pages.some((content) => content.page == page);
    },
    checkExistenceOfSomePrice: (state) => {
      return state.pages
        .flatMap((content) => content.data)
        .some((item) => item.latestPrice > 0 && item.latestPrice != null);
    },
    listOfProductsHaveLatestPrice: (state) => {
      return state.pages
        .flatMap((content) => content.data)
        .filter((item) => item.latestPrice > 0 && item.latestPrice != null);
    },
    cIByProductName: (state) => (name) => {
      if (!name) return null;
      return (
        state.pages
          .flatMap((content) => content.data)
          .find((item) => item.name == name) ?? null
      );
    },
  },
  mutations: {
    setCurrentExt(state, list) {
      state.current = list || [];
    },
    updatePagesExt(state, pages) {
      if (pages) state.pages.push(pages);
      else state.pages = [];
    },
    setExistentPageExt(state, page) {
      state.current =
        state.pages.find((content) => content.page == page).data || [];
    },
    updateLastPrice(state, { price, productIds }) {
      const item = state.pages
        .flatMap((item) => item.data)
        .find(
          (item) =>
            JSON.stringify(item.productIds) == JSON.stringify(productIds)
        );
      if (item) item.latestPrice = price;
    },
    updateProductExt(state, product) {
      if (!product.productIds) return;

      const updateItem = (item, product) => {
        if (!item) return;
        item.minPrice = product?.minPrice ?? 0;
        item.avgPrice = product?.avgPrice ?? 0;
        item.maxPrice = product?.maxPrice ?? 0;
        item.latestPrice = product?.latestPrice ?? 0;
        item.competitivenessIndex = product?.competitivenessIndex ?? 0;
      };

      const historyItem = state.pages
        .flatMap((item) => item.data)
        .find(
          (item) =>
            JSON.stringify(item.productIds) ===
            JSON.stringify(product.productIds)
        );
      updateItem(historyItem, product);

      const currentItem = state.current.find(
        (item) =>
          JSON.stringify(item.productIds) === JSON.stringify(product.productIds)
      );
      updateItem(currentItem, product);
    },
    toogleRefreshCIFlag(state) {
      state.refreshCIFlag = !state.refreshCIFlag;
    },
    cleanDatasExt(state) {
      state.page = [];
      state.curren = [];
      state.refreshCIFla = false;
    },
  },
  actions: {},
};

export default ProductTableExternalPaginationStore;
