var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-thead,table-tbody"}}):_c('v-data-table',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"headers":_vm.headers,"footer-props":_vm.footerProps,"hide-default-footer":_vm.items.length == 0 || _vm.isLoading,"no-data-text":_vm.$t('TXT_NO_DATA_FOUND_DISPLAY'),"no-results-text":_vm.$t('TXT_NO_DATA_FOUND_DISPLAY'),"items":_vm.items,"page":_vm.page,"items-per-page":_vm.take,"server-items-length":_vm.totalItems},on:{"update:sort-by":function($event){return _vm.$emit('update:sort-by', $event)},"update:page":function($event){return _vm.$emit('update:page', $event)},"update:items-per-page":function($event){return _vm.$emit('update:items-per-page', $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.$prdStyles('color-primary'),"disabled":_vm.isLoading,"dense":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyText(item.name)}}},[_vm._v(" mdi-content-copy ")]),(_vm.checkLink(item.link))?_c('a',{attrs:{"target":"_blank","href":item.link}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.price))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date, _vm.formatForDate))+" ")]}},{key:"item.disregarded",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":_vm.disableCheckbox},model:{value:(item.disregarded),callback:function ($$v) {_vm.$set(item, "disregarded", $$v)},expression:"item.disregarded"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }