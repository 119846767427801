const ProductTableInternalPaginationStore = {
  state: {
    pages: [],
  },
  getters: {
    checkExistenceOfPageInt: (state) => (productIds, page) => {
      return state.pages.some(
        (content) =>
          JSON.stringify(content.productIds) == JSON.stringify(productIds) &&
          content.page == page
      );
    },
    haveHistorical: (state) => (productIds) => {
      return (
        state.pages.filter(
          (content) =>
            JSON.stringify(content.productIds) == JSON.stringify(productIds)
        )?.length > 0 ?? false
      );
    },
    listProductsForSaveChanges: (state) => (productIds) => {
      return (
        state.pages
          .filter(
            (content) =>
              JSON.stringify(content.productIds) == JSON.stringify(productIds)
          )
          .flatMap((item) => item.data) ?? []
      );
    },
    currentPageByHistorical: (state) => (productIds, page) => {
      return (
        state.pages.find(
          (content) =>
            JSON.stringify(content.productIds) == JSON.stringify(productIds) &&
            content.page == page
        )?.data ?? []
      );
    },
    totalItemsByProductIds: (state) => (productIds) => {
      return (
        state.pages.find(
          (content) =>
            JSON.stringify(content.productIds) == JSON.stringify(productIds) &&
            content.page == 1
        )?.totalItems ?? 0
      );
    },
  },
  mutations: {
    updatePagesInt(state, { productIds, page, data, totalItems }) {
      if (page) state.pages.push({ productIds, page, data, totalItems });
      else
        state.pages = state.pages.filter(
          (content) =>
            JSON.stringify(content.productIds) != JSON.stringify(productIds)
        );
    },
    cleanDataInt(state) {
      state.pages = [];
    },
  },
  actions: {},
};

export default ProductTableInternalPaginationStore;
