<template>
  <v-card class="pa-4">
    <v-row no-gutters class="mb-6 gap-4" align="end">
      <h3>{{ $t("TXT_FILTER_CUSTOMIZATION") }}</h3>
      <Custom-filters-modal
        :customFilters="originalCustomFilters"
        :isLoading="isLoading"
      />
    </v-row>

    <Crawler-select
      ref="crawlerSelect"
      :idCompany="selectedCompany.idEmpresa"
      @update:crawler="crawler = $event"
      @update:crawlerList="crawlerList = $event"
    />

    <Filter-select
      ref="filterSelect"
      :crawler="crawler"
      :externalLoading="isLoading"
      @update:filter="filter = $event"
    />

    <div class="mb-6" v-if="showEditionFields">
      <Simple-filter
        v-if="isSimpleFilter"
        :companyId="selectedCompany.idEmpresa"
        :crawlerId="crawler.crawlerId"
        :filter="filter"
        :customFilters="customFilters"
        @handleSimpleFilter="handleSimpleFilter"
      />

      <Comparator-filter
        v-else
        :filter="filter"
        :currentCustomFilter="currentCustomFilter"
        @handleComparatorFilter="handleComparatorFilter"
      />

      <v-row no-gutters justify="end" class="gap-4">
        <Prd-btn
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          @click="resetValues(true)"
        />
        <Save-changes-modal
          :notHasChanges="notHasChanges"
          :crawlerList="crawlerList"
          :changedFilterList="changedFilterList"
          :companyId="selectedCompany.idEmpresa"
          :crawlerId="crawler.crawlerId"
          :isSimpleFilter="isSimpleFilter"
          @update:originalCustomFilter="updateOriginalCustomFilter"
        />
      </v-row>
    </div>
  </v-card>
</template>

<script>
import CustomFiltersModal from "./components/custom-filters-modal.vue";
import CrawlerSelect from "./components/crawler-select.vue";
import FilterSelect from "./components/filter-select.vue";
import SimpleFilter from "./components/simple-filter.vue";
import ComparatorFilter from "./components/comparator/comparator-filter.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ConfigFiltersPredimonitorService from "@/service/predimonitor-v3/config-filters-predimonitor-service-v3";
import SaveChangesModal from "./components/save-changes-modal.vue";

export default {
  props: { selectedCompany: { type: Object, default: () => ({}) } },
  components: {
    CustomFiltersModal,
    CrawlerSelect,
    FilterSelect,
    SimpleFilter,
    ComparatorFilter,
    PrdBtn,
    SaveChangesModal,
  },
  data() {
    return {
      crawlerList: [],
      crawler: null,
      filter: null,
      isLoading: false,
      service: new ConfigFiltersPredimonitorService(),
      customFilters: [],
      originalCustomFilters: [],
    };
  },
  computed: {
    lang() {
      const langByLocalStorage = localStorage.getItem("lang");
      const lang =
        langByLocalStorage != "undefined" ? langByLocalStorage : "pt-BR";
      return lang;
    },
    showEditionFields() {
      return this.crawler && this.filter;
    },
    isSimpleFilter() {
      return this.filter?.model == "simple" ?? false;
    },
    currentCustomFilter() {
      return (
        this.customFilters.find(
          (filter) => filter.field == this.filter.value
        ) ?? {}
      );
    },
    notHasChanges() {
      return (
        JSON.stringify(this.originalCustomFilters) ==
        JSON.stringify(
          this.customFilters.filter((filter) => filter.operator != null)
        )
      );
    },
    changedFilterList() {
      return this.customFilters.filter((modified) => {
        const original = this.originalCustomFilters.find(
          (original) => original.field === modified.field
        );
        return (
          original &&
          (modified.operator !== original.operator ||
            JSON.stringify(modified.values) !== JSON.stringify(original.values))
        );
      });
    },
  },
  watch: {
    crawler: {
      handler(value) {
        if (value != null) this.getCompanyCustomFilters();
      },
      immediate: true,
      deep: true,
    },
    selectedCompany: {
      handler(value) {
        if (value != null) this.resetValues();
      },
      immediate: true,
      deep: true,
    },
    filter: {
      handler() {
        this.fillCurrentCustomFilter();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    resetValues(byCancelChanges = false) {
      this.crawler = null;
      this.filter = null;
      if (!byCancelChanges) {
        this.crawlerList = [];
        this.customFilters = [];
        this.originalCustomFilters = [];
      }
      if (this.$refs.crawlerSelect)
        this.$refs.crawlerSelect.resetValues(byCancelChanges);
      if (this.$refs.filterSelect) this.$refs.filterSelect.resetValues();
    },
    async getCompanyCustomFilters() {
      this.isLoading = true;
      try {
        const response = await this.service.getCompanyCustomFilters(
          this.selectedCompany.idEmpresa,
          this.crawler.crawlerId,
          this.lang
        );

        const data =
          response.status == 204 ? [] : structuredClone(response?.data ?? []);

        this.customFilters = data;
        this.originalCustomFilters = data;
      } catch (error) {
        this.$handleError(
          error,
          this.$i18n.t("TXT_FETCH_CUSTOM_FILTERS_ERROR")
        );
      } finally {
        this.isLoading = false;
      }
    },
    fillCurrentCustomFilter() {
      const exitentFilter = this.customFilters.find((filter) => {
        return filter.field == this.filter?.value;
      });

      if (exitentFilter) return;
      else {
        const newFilter = {
          field: this.filter?.value ?? null,
          text: this.filter?.text ?? null,
          operator: null,
          values: [],
        };

        if (newFilter.field != null) this.customFilters.push(newFilter);
      }
    },
    handleSimpleFilter(values) {
      this.currentCustomFilter.values = values;
    },
    updateOriginalCustomFilter() {
      this.originalCustomFilters = structuredClone(this.customFilters);
      this.resetValues(true);
    },
    handleComparatorFilter({ operator, values }) {
      this.currentCustomFilter.values = values ?? [];
      this.currentCustomFilter.operator = operator ?? null;
    },
  },
};
</script>
