import { render, staticRenderFns } from "./value-item.vue?vue&type=template&id=3b639191&scoped=true"
import script from "./value-item.vue?vue&type=script&lang=js"
export * from "./value-item.vue?vue&type=script&lang=js"
import style0 from "./value-item.vue?vue&type=style&index=0&id=3b639191&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b639191",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
