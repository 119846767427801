import axiosInstance, {
  API_URL,
  API_URL_PREDIMONITOR,
} from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class PredimonitorService extends ServiceBase {
  async AvgInfoByProduct(data) {
    return await axiosInstance.post(
      API_URL + "/api/V2/PrediMonitor/AvgInfoByProduct",
      data
    );
  }

  async AvgInfoByProductMaskedSeller(data) {
    return await axiosInstance.post(
      API_URL + "/api/V2/PrediMonitor/AvgInfoByProductMaskedSeller",
      data
    );
  }

  async AvgInfoByProductV2(data) {
    return await axiosInstance.post(
      API_URL + "/api/V2/PrediMonitor/SearchV3",
      data
    );
  }

  async BoxPlotGraph(data) {
    return await axiosInstance.post(
      API_URL + "api/V2/PrediMonitor/BoxPlotGraph",
      data
    );
  }

  async TemporalEvolutionChart(data) {
    return await axiosInstance.post(
      API_URL + "api/V2/PrediMonitor/TemporalEvolutionGraph",
      data
    );
  }

  async tableResultsDisregarded(data) {
    return await axiosInstance.post(
      API_URL + "api/V2/PrediMonitor/SaveIgnoredMonitoringItems",
      data
    );
  }

  async getPriceProjection(data) {
    return await axiosInstance.post(
      API_URL + "api/V2/PrediMonitor/CalculateProjection",
      data
    );
  }

  async setPriceAlert(data) {
    return await axiosInstance.post(
      API_URL + "api/V2/PrediMonitor/SaveMonitoringItemPriceAlerts",
      data
    );
  }

  async getResourceGroup(idCompany) {
    return await axiosInstance.get(
      API_URL + `api/V2/Access/ResourceGroup/List?idCompany=${idCompany}`
    );
  }

  async getUsers(idCompany) {
    return await axiosInstance.get(
      API_URL + `api/Usuario/GetusuariosByIdEmpresa?idEmpresa=${idCompany}`
    );
  }

  async getStoresAddress() {
    return await axiosInstance.get(
      API_URL + "api/V2/PrediMonitor/GetAllAddresses"
    );
  }

  async getAffiliateMapList(request) {
    return await axiosInstance.post(
      API_URL + "api/V2/PrediMonitor/GetAffiliateMapList",
      request
    );
  }

  async getRentalReport(date) {
    return await axiosInstance.get(
      API_URL_PREDIMONITOR + `api/DynamicFilter/GetRentalReport?date=${date}`,
      { responseType: "blob" }
    );
  }

  async searchAverageProductPrice(request) {
    return await axiosInstance.post(
      API_URL_PREDIMONITOR + "api/ProductPrice/SearchAverageProductPrice",
      request
    );
  }
}
