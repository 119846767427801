<template>
  <v-skeleton-loader v-if="isLoading" type="table-thead,table-tbody" />
  <v-data-table
    v-else
    :headers="headers"
    :footer-props="footerProps"
    :hide-default-footer="items.length == 0 || isLoading"
    :no-data-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
    :no-results-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
    :items="items"
    :page="page"
    :items-per-page="take"
    :server-items-length="totalItems"
    @update:sort-by="$emit('update:sort-by', $event)"
    @update:page="$emit('update:page', $event)"
    @update:items-per-page="$emit('update:items-per-page', $event)"
    v-ripple
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-icon
        :color="$prdStyles('color-primary')"
        :disabled="isLoading"
        dense
        @click.stop="copyText(item.name)"
        class="mr-2"
      >
        mdi-content-copy
      </v-icon>
      <a v-if="checkLink(item.link)" target="_blank" :href="item.link">
        {{ item.name }}
      </a>
      <span v-else>{{ item.name }}</span>
    </template>

    <template v-slot:[`item.price`]="{ item }">
      {{ formatMonetary(item.price) }}
    </template>

    <template v-slot:[`item.date`]="{ item }">
      {{ formatDate(item.date, formatForDate) }}
    </template>

    <template v-slot:[`item.disregarded`]="{ item }">
      <v-simple-checkbox
        v-model="item.disregarded"
        :disabled="disableCheckbox"
      />
    </template>
  </v-data-table>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
import { formatDate } from "@/utils/format-date";
import { copyText } from "@/utils/copy-text";
export default {
  props: {
    items: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    disableCheckbox: { type: Boolean, default: false },
    isDisregardedType: { type: Boolean, default: false },
    page: { type: Number, default: 1 },
    take: { type: Number, default: 10 },
    totalItems: { type: Number, default: -1 },
  },
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          value: "name",
          width: "300px",
        },
        {
          text: this.$i18n.t("TXT_BRAND"),
          align: "center",
          value: "brand",
          width: "170px",
        },
        {
          text: this.$i18n.t("TXT_ORIGIN"),
          align: "center",
          value: "origin",
          width: "200px",
        },
        {
          text: this.$i18n.t("TXT_NETWORK"),
          align: "center",
          value: "seller",
          width: "180px",
        },

        {
          text: this.$i18n.t("TXT_PRICE"),
          align: "center",
          value: "price",
          width: "120px",
        },

        {
          text: this.$i18n.t("TXT_COLECT_DATE"),
          align: "center",
          value: "date",
          width: "180px",
        },
        {
          text: this.$i18n.t("TXT_DISREGARD"),
          align: "center",
          value: "disregarded",
          width: "180px",
        },
      ],
    };
  },
  computed: {
    lang() {
      const langByLocalStorage = localStorage.getItem("lang");
      const lang =
        langByLocalStorage != "undefined" ? langByLocalStorage : "pt-BR";
      return lang;
    },
    formatForDate() {
      return this.lang != "en-US" ? "dd/MM/yyyy" : "yyyy/MM/dd";
    },
    footerProps() {
      const itemsPerPageOptions = [5, 10, 15];
      if (!this.isDisregardedType) itemsPerPageOptions.push(-1);
      return {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
        itemsPerPageOptions,
      };
    },
  },

  methods: {
    formatMonetary,
    formatDate,
    copyText,
    reorganizeHeaderForTable() {
      const machineryColumns = [
        {
          text: this.$i18n.t("TXT_VEHICLE_YEAR_MODEL"),
          value: "productVehicleYearModel",
          position: 2,
        },
        {
          text: this.$i18n.t("TXT_MODEL"),
          value: "productModel",
          position: 3,
        },
        {
          text: this.$i18n.t("TXT_USED_HOURS"),
          value: "productUsedHours",
          position: 4,
        },
        {
          text: this.$i18n.t("TXT_PRODUCT_AXLES"),
          value: "productAxles",
          position: 5,
        },
        {
          text: this.$i18n.t("TXT_MILEAGE"),
          value: "productMileage",
          position: 6,
        },
        {
          text: this.$i18n.t("TXT_FUEL_TANK_CAPACITY"),
          value: "productFuelTankCapacity",
          position: 7,
        },
        {
          text: this.$i18n.t("TXT_TRUCK_BODY_TYPE"),
          value: "productTruckBodyType",
          position: 8,
        },
        {
          text: this.$i18n.t("TXT_PRODUCT_MANUFACTURER"),
          value: "productManufacturer",
          position: 9,
        },
      ];

      this.addNewColumns(machineryColumns, "marketplace.machinery");

      const predimonitorPriceFrom = [
        {
          text: this.$i18n.t("TXT_PRICE_FROM"),
          value: "priceFrom",
          position: 5,
        },
        {
          text: this.$i18n.t("TXT_PAYMENT"),
          value: "paymentType",
          position: 6,
        },
      ];

      this.addNewColumns(predimonitorPriceFrom, "predimonitor.priceFrom");
    },
    addNewColumns(columns, resourceKey) {
      if (this.hasResource(resourceKey)) {
        columns.forEach((column) => {
          const newColumn = this.buildBodyToHeaders(column.text, column.value);
          this.headers.splice(column.position, 0, newColumn);
        });
      }
    },
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },
    buildBodyToHeaders(title, value) {
      return {
        text: title,
        align: "center",
        value: value,
        width: "120px",
      };
    },
    checkLink(link) {
      return (
        link != null &&
        link != "-" &&
        (link.startswith("http") || link.startswith("https"))
      );
    },
  },
  mounted() {
    this.reorganizeHeaderForTable();
  },
};
</script>
