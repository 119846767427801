<template>
  <p class="text-center ma-0">
    {{ $t("TXT_CI_FIRST_PERIOD") }}

    <span class="font-weight-bold"
      >{{ position }}
      {{
        ci > 100
          ? $t("TXT_CI_MIDDLE_PERIOD_HIGHER")
          : $t("TXT_CI_MIDDLE_PERIOD_LOWER")
      }}
    </span>
    {{ $t("TXT_CI_LAST_PERIOD") }}
  </p>
</template>

<script>
export default {
  props: { ci: { type: Number, default: 0 } },
  computed: {
    position() {
      return Math.abs(this.ci - 100).toFixed(2) + "%";
    },
  },
};
</script>
