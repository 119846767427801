<template>
  <div class="mb-6">
    <v-row no-gutters align="end" class="gap-4">
      <v-col cols="3" :class="[{ 'pb-6': hasFilledOneComparator }]">
        <Prd-combo-box
          v-model="comparator"
          :title="$t('TXT_COMPARATOR')"
          :useTooltipInformation="true"
          :textTooltipInformation="$t('TXT_EXPLANATION_COMPARATOR')"
          :items="comparatorList"
          @change="handleComparatorFilter(true)"
        />
        <v-row
          no-gutters
          align="center"
          v-if="hasComparatorDescription"
          class="mt-1"
        >
          <span class="text-caption">{{ $t("TXT_COMPARATOR_HINT") }}</span>
          <Prd-tooltip :text="comparator.description" />
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        :class="[
          { 'pb-6': hasFilledOneComparator || hasComparatorDescription },
        ]"
      >
        <Prd-text-field
          v-model="value"
          :title="$t('TXT_VALUE')"
          :tooltip="$t('TXT_EXPLANATION_VALUE')"
          :type="typeValueField"
          :disabled="hasFilledOneComparator"
        />
        <span v-if="hasFilledOneComparator" class="text-caption">
          {{ $t("TXT_COMPARATOR_ALERT") }}
        </span>
      </v-col>
      <v-col
        cols="auto"
        :class="[
          { 'pb-12': hasFilledOneComparator && hasComparatorDescription },
          { 'pb-6': hasFilledOneComparator || hasComparatorDescription },
        ]"
      >
        <div :class="['add-value-btn', { disabled: disableAddButton }]">
          <v-icon
            color="white"
            size="30"
            :disabled="disableAddButton"
            @click="addValue"
          >
            mdi-plus
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <span class="mr-2">{{ $t("TXT_VALUES") }}:</span>
      <Value-item
        v-for="(item, index) in values"
        :key="index"
        :name="item"
        @removeValue="removeValue(index)"
      />
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import ValueItem from "./value-item.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdTooltip from "@/components/common/prd-tooltip.vue";

export default {
  props: {
    currentCustomFilter: { type: Object, default: null },
    filter: { type: Object, default: null },
  },
  components: {
    PrdComboBox,
    ValueItem,
    PrdTextField,
    PrdTooltip,
  },
  data() {
    return {
      value: null,
      values: [],
      comparator: null,
      comparatorList: [
        {
          text: "Igualdade '='",
          operator: "=",
          description: this.$i18n.t("TXT_COMPARATOR_EQUALITY_TOOLTIP"),
          type: "general",
          quantity: "one",
        },
        {
          text: "Diferente '<>'",
          operator: "<>",
          description: this.$i18n.t("TXT_COMPARATOR_DIFFERENT_TOOLTIP"),
          type: "general",
          quantity: "one",
        },
        {
          text: "Menor que '<'",
          operator: "<",
          description: this.$i18n.t("TXT_COMPARATOR_SMALLER_THAN_TOOLTIP"),
          type: "number",
          quantity: "one",
        },
        {
          text: "Maior que '>'",
          operator: ">",
          description: this.$i18n.t("TXT_COMPARATOR_BIGGER_THAN_TOOLTIP"),
          type: "number",
          quantity: "one",
        },
        {
          text: "Menor ou igual '<='",
          operator: "<=",
          description: this.$i18n.t(
            "TXT_COMPARATOR_SMALLER_EQUAL_THAN_TOOLTIP"
          ),
          type: "number",
          quantity: "one",
        },
        {
          text: "Maior ou igual '>='",
          operator: ">=",
          description: this.$i18n.t("TXT_COMPARATOR_BIGGER_EQUAL_THAN_TOOLTIP"),
          type: "number",
          quantity: "one",
        },
        {
          text: "Em uma lista 'IN'",
          operator: "IN",
          description: this.$i18n.t("TXT_COMPARATOR_IN_TOOLTIP"),
          type: "general",
          quantity: "list",
        },
        {
          text: "Não está em uma lista 'NOT IN'",
          operator: "NOT IN",
          description: this.$i18n.t("TXT_COMPARATOR_NOT_IN_TOOLTIP"),
          type: "general",
          quantity: "list",
        },
        {
          text: "Corresponde a um padrão 'LIKE'",
          operator: "LIKE",
          description: this.$i18n.t("TXT_COMPARATOR_LIKE_TOOLTIP"),
          type: "general",
          quantity: "one",
        },
        {
          text: "Não corresponde a um padrão 'NOT LIKE'",
          operator: "NOT LIKE",
          description: this.$i18n.t("TXT_COMPARATOR_NOT_LIKE_TOOLTIP"),
          type: "general",
          quantity: "one",
        },
      ],
      changeList: [],
    };
  },
  computed: {
    typeValueField() {
      return this.comparator?.type ?? "";
    },
    hasFilledOneComparator() {
      return this.comparator?.quantity == "one" && this.values.length == 1;
    },
    disableAddButton() {
      if (this.hasFilledOneComparator) return true;
      else return this.value == null || this.value == "";
    },
    hasComparatorDescription() {
      return this.comparator?.description != undefined ?? false;
    },
  },
  watch: {
    filter: {
      handler() {
        this.fillFields();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fillFields() {
      const currentOperator = this.currentCustomFilter?.operator ?? null;
      const comparatorObj = this.comparatorList.find(
        ({ operator }) => operator === currentOperator
      );
      this.comparator = comparatorObj;
      this.values = this.currentCustomFilter?.values ?? [];
    },
    addValue() {
      this.values.push(this.value);
      this.value = null;
      this.handleComparatorFilter();
    },
    removeValue(index) {
      this.values.splice(index, 1);
    },
    handleComparatorFilter(byComparator = false) {
      if (byComparator) this.values = [];
      this.$emit("handleComparatorFilter", {
        operator: this.comparator?.operator ?? null,
        values: this.values ?? [],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-value-btn {
  background-color: $feedback-color-success-pure;
  border-radius: 4px;
  margin-bottom: 1px;
  cursor: pointer;
}
.disabled {
  background-color: $neutral-color-high-medium;
  cursor: default;
}
</style>
