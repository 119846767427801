/**
 * @param {Date} date
 * @param {"dd/MM/yyyy - hh:mm:ss" | "dd/MM/yyyy" | "yyyy/MM/dd - hh:mm:ss" | "yyyy/MM/dd"} format
 * @returns {string}
 */
export function formatDate(date, format) {
  const objectDate =
    date instanceof Date && !isNaN(date) ? date : new Date(date);
  const day = objectDate.getDate().toString().padStart(2, "0");
  const month = (objectDate.getMonth() + 1).toString().padStart(2, "0");
  const year = objectDate.getFullYear().toString().padStart(4, "0");
  const hours = objectDate.getHours().toString().padStart(2, "0");
  const minutes = objectDate.getMinutes().toString().padStart(2, "0");
  const seconds = objectDate.getSeconds().toString().padStart(2, "0");

  switch (format) {
    case "dd/MM/yyyy - hh:mm:ss":
      return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
    case "dd/MM/yyyy":
      return `${day}/${month}/${year}`;
    case "yyyy/MM/dd - hh:mm:ss":
      return `${year}/${month}/${day} - ${hours}:${minutes}:${seconds}`;
    case "yyyy/MM/dd":
      return `${year}/${month}/${day}`;
    default:
      throw new Error("Unknown date format");
  }
}
