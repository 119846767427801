<template>
  <v-expansion-panel-content>
    <Table
      :isDisregardedType="true"
      :isLoading="isLoading"
      :disableCheckbox="disableCheckbox"
      :items="currentPage"
      :page="page"
      :take="take"
      :totalItems="totalItems"
      @update:sort-by="handleSortBy"
      @update:page="page = $event"
      @update:items-per-page="take = $event"
      class="my-4"
    />
    <v-row no-gutters justify="end" class="gap-4">
      <Disregarded-items-table
        ref="DisregardedItemsTable"
        :productIds="productIds"
        :externalDisable="disableCheckbox"
        @refreshList="refreshHeader"
      />
      <Save-changes
        :list="listProductsForSaveChanges"
        @refreshList="handleSaveChanges"
        @disableCheckbox="disableCheckbox = $event"
      />
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
import DisregardedItemsTable from "./components/disregarded-items-table.vue";
import SaveChanges from "./components/save-changes.vue";
import Table from "./components/table-main.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";
export default {
  props: { notHavePrices: { type: Boolean, default: false } },
  components: { Table, SaveChanges, DisregardedItemsTable },
  data() {
    return {
      productIds: null,
      isLoading: false,
      disableCheckbox: false,
      totalItems: 0,
      page: 1,
      take: 10,
      service: new PredimonitorService(),
      currentPage: [],
      orderBy: "",
      orderType: 0,
    };
  },
  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
    listProductsForSaveChanges() {
      return this.$store.getters.listProductsForSaveChanges(this.productIds);
    },
  },
  watch: {
    page: {
      handler(value) {
        this.handlePriceList(value);
      },
    },
    take() {
      this.refreshList(false);
    },
  },

  methods: {
    getInitialPage(productIds) {
      const haveHistorical = this.$store.getters.haveHistorical(productIds);

      if (haveHistorical) {
        this.currentPage = this.$store.getters.currentPageByHistorical(
          productIds,
          this.page
        );
        this.totalItems =
          this.$store.getters.totalItemsByProductIds(productIds);
      } else {
        this.productIds = productIds;
        this.cleanData(true);
        this.fetchProducts();
      }
    },
    cleanData(isInitial) {
      this.$store.commit("updatePagesInt", {
        productIds: this.productIds,
        page: null,
      });

      this.currentPage = [];

      if (isInitial) {
        this.page = 1;
      }
    },
    async fetchProducts() {
      if (this.isLoading || this.notHavePrices) return;

      this.isLoading = true;
      const payload = this.prepareRequest();
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Produtos",
        name: "Paginação Interna",
        payload,
      });
      try {
        const response = await this.service.getSellerListPaginated(payload);
        
        this.$store.commit("endRequest", { id: requestId, response });

        const sellers =
          response?.data?.sellerPrices?.map((seller) => ({
            ...seller,
            disregarded: false,
          })) ?? [];

        this.$store.commit("updatePagesInt", {
          productIds: this.productIds,
          page: this.page,
          data: sellers,
          totalItems: this.page == 1 ? response.data.totalCount : 0,
        });

        this.currentPage = sellers;

        if (this.page == 1) this.totalItems = response.data.totalCount;
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, this.$i18n.t("TXT_FETCH_SELLERS_ERROR"));
      } finally {
        this.isLoading = false;
      }
    },
    prepareRequest() {
      const request = structuredClone(this.lastRequest);
      delete request.productNames;
      return {
        ...request,
        productIds: this.productIds,
        page: this.page,
        take: this.take,
        orderBy: this.orderBy,
        orderType: this.orderType,
      };
    },
    handlePriceList(page) {
      const existsPage = this.$store.getters.checkExistenceOfPageInt(
        this.productIds,
        page
      );

      if (existsPage) {
        this.currentPage = this.$store.getters.currentPageByHistorical(
          this.productIds,
          page
        );
      } else this.refreshList();
    },
    refreshList(isInitial) {
      if (this.isLoading) return;
      this.cleanData(isInitial);
      this.fetchProducts();

      if (isInitial) this.$store.commit("refreshComponents");
    },

    handleSortBy(value) {
      if (value !== this.orderBy) {
        this.orderType = 1;
      } else {
        this.orderType =
          this.orderType === 1 ? -1 : this.orderType === -1 ? 0 : 1;
      }

      this.orderBy = value || "";
      this.refreshList(false);
    },
    handleSaveChanges() {
      if (this.$refs.DisregardedItemsTable)
        this.$refs.DisregardedItemsTable.reStartIsInitialCall();
      this.refreshHeader();
    },

    refreshHeader() {
      this.$emit("refreshHeader");
      this.refreshList(true);
    },
  },
};
</script>
