import { render, staticRenderFns } from "./privacy-policy-terms-modal.vue?vue&type=template&id=6e8e42f2&scoped=true"
import script from "./privacy-policy-terms-modal.vue?vue&type=script&lang=js"
export * from "./privacy-policy-terms-modal.vue?vue&type=script&lang=js"
import style0 from "./privacy-policy-terms-modal.vue?vue&type=style&index=0&id=6e8e42f2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8e42f2",
  null
  
)

export default component.exports