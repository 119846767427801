<template>
  <v-dialog v-model="isOpen" transition="dialog-top-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <Prd-btn
        v-bind="attrs"
        v-on="on"
        :title="$t('TXT_ADD_FILTER')"
        :disabled="!hasSelectedCrawler || externalLoading"
        class="mb-4"
      />
    </template>

    <v-card class="pa-4">
      <Filter-select
        @updateFilter="filter = $event"
        :customFilters="customFilters"
      />

      <div v-if="hasSelectedFilter">
        <Simple-filter
          v-if="isSimpleFilter"
          :companyId="companyId"
          :crawlerId="crawlerId"
          :filter="filter"
          :customFilters="customFilters"
        />
        <Comparator-filter v-else />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import FilterSelect from "./components/filter-select.vue";
import SimpleFilter from "./components/simple-filter.vue";
import ComparatorFilter from "./components/comparator/comparator-filter.vue";
export default {
  props: {
    companyId: { type: Number, default: 0 },
    crawlerId: { type: Number, default: 0 },
    customFilters: { type: Array, default: () => [] },
    externalLoading: { type: Boolean, default: false },
  },
  components: {
    PrdBtn,
    FilterSelect,
    SimpleFilter,
    ComparatorFilter,
  },
  data() {
    return {
      isOpen: false,
      filter: null,
    };
  },
  computed: {
    hasSelectedFilter() {
      return this.filter != null;
    },
    isSimpleFilter() {
      return this.filter?.type == "simple" ?? false;
    },
    hasSelectedCrawler() {
      return this.crawlerId != 0;
    },
  },
};
</script>
