<template>
  <div>
    <v-row no-gutters align="end">
      <v-col cols="3" class="pr-2">
        <Prd-combo-box
          v-model="currentConfig.base"
          :title="'Base'"
          :placeholder="$t('TXT_SELECT_A_BASE')"
          :label="$t('TXT_SELECT_A_BASE')"
          :items="bases"
          :itemText="'name'"
          :disabled="isLoadingBases"
          :loading="isLoadingBases"
          @change="handleSelectBase"
        />
      </v-col>
      <v-col cols="3" class="px-2">
        <Prd-combo-box
          v-model="currentConfig.segments"
          :title="$t('TXT_SEGMENTS')"
          :placeholder="$t('TXT_SELECT_SEGMENT')"
          :label="$t('TXT_SELECT_SEGMENT')"
          :items="availableSegments"
          :disabled="!hasSelectedBase || isLoadingBases"
          :loading="isLoadingBases"
          :itemText="'name'"
          multiple
          deletable-chips
          small-chips
          clearable
          @change="updateConfigField('segments', $event)"
        />
      </v-col>
      <v-col cols="3" class="px-2">
        <Prd-combo-box
          v-model="currentConfig.regions"
          :title="$t('TXT_REGIONS')"
          :placeholder="$t('TXT_SELECT_A_REGION')"
          :label="$t('TXT_SELECT_A_REGION')"
          :items="regions"
          :disabled="!hasSelectedBase || isLoadingRegions"
          :loading="isLoadingRegions"
          :itemText="'name'"
          multiple
          deletable-chips
          small-chips
          clearable
          @change="setValidStates"
        />
      </v-col>
      <v-col cols="3" class="pl-2">
        <Prd-combo-box
          v-model="currentConfig.states"
          :title="$t('TXT_STATES')"
          :placeholder="$t('TXT_SELECT_A_STATE')"
          :label="$t('TXT_SELECT_A_STATE')"
          :items="availableStates"
          :disabled="
            !hasSelectedBase || !hasSelectedRegions || isLoadingRegions
          "
          :loading="isLoadingRegions"
          multiple
          deletable-chips
          small-chips
          clearable
          @change="updateConfigField('states', $event)"
        />
      </v-col>
    </v-row>
    <Prd-loading-msg
      v-if="isLoadingConfigs"
      :text="$t('TXT_LOADING_CONFIG_WAIT')"
      class="mt-4"
    />
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import ConfigFiltersPredimonitorService from "@/service/predimonitor-v3/config-filters-predimonitor-service-v3";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import { getBrazilianStateData } from "@/views/main/predimonitor-v3/components/annoucement-map/utils/brazilian-states";

export default {
  props: {
    companyId: { type: Number, default: 0 },
  },
  components: {
    PrdComboBox,
    PrdLoadingMsg,
  },
  data() {
    return {
      isLoadingBases: false,
      isLoadingRegions: false,
      isLoadingConfigs: false,
      service: new ConfigFiltersPredimonitorService(),
      currentConfig: {
        base: null,
        segments: [],
        regions: [],
        states: [],
      },
      regions: [],
      bases: [],
      configs: [],
      originalConfigs: [],
    };
  },
  computed: {
    availableSegments() {
      return (
        this.bases.find((base) => this.currentConfig?.base?.name == base.name)
          ?.children ?? []
      );
    },
    availableStates() {
      return this.regions
        .filter((region) =>
          this.currentConfig.regions
            .map((reg) => reg.name ?? reg)
            .includes(region.name)
        )
        .flatMap((region) => region.children);
    },
    hasSelectedBase() {
      return this.currentConfig.base != null && this.currentConfig.base != "";
    },
    hasSelectedSegments() {
      return this.hasSelectedOneItem(this.currentConfig.segments);
    },
    hasSelectedRegions() {
      return this.hasSelectedOneItem(this.currentConfig.regions);
    },
    hasSelectedStates() {
      return this.hasSelectedOneItem(this.currentConfig.states);
    },
    disableAddButton() {
      const obrigatory = !this.hasSelectedBase || !this.hasSelectedSegments;

      if (!this.currentConfig?.base?.isOnline) {
        return (
          obrigatory || !this.hasSelectedRegions || !this.hasSelectedStates
        );
      }

      return obrigatory;
    },

    lang() {
      const langByLocalStorage = localStorage.getItem("lang");
      const lang =
        langByLocalStorage != "undefined" ? langByLocalStorage : "pt-BR";
      return lang;
    },
    updatedConfigs() {
      return this.configs.filter((upd) => {
        const org = this.originalConfigs.find(
          (org) => org.sourceId == upd.sourceId
        );
        return (
          org &&
          (JSON.stringify(upd.segments) != JSON.stringify(org.segments) ||
            JSON.stringify(upd.regions) != JSON.stringify(org.regions) ||
            JSON.stringify(upd.states) != JSON.stringify(org.states))
        );
      });
    },
  },

  watch: {
    companyId: {
      handler() {
        this.refreshVariables();
        this.getBases();
      },
      immediate: true,
    },
    "currentConfig.base": {
      handler(value) {
        if (value?.id != null) {
          this.getRegions(value.id);
          this.getConfigs(value.id);
        }
      },
      immediate: true,
    },
    updatedConfigs: {
      handler(value) {
        this.$emit("updatedConfigs", value);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    hasSelectedOneItem(array) {
      return array?.length > 0 ?? false;
    },

    setValidStates(regions) {
      this.updateConfigField("regions", regions);

      const states = this.availableStates.map(
        (state) => getBrazilianStateData(state.name).name
      );
      this.currentConfig.states = states;
      this.updateConfigField("states", states);
    },

    async getBases() {
      this.isLoadingBases = true;
      try {
        const response = await this.service.getCompanyFilterOptions(
          this.companyId,
          "source"
        );
        this.bases = response.status == 204 ? [] : response?.data ?? [];
      } catch (error) {
        this.$handleError(
          error,
          this.$i18n.t("TXT_FETCH_CUSTOM_FILTERS_ERROR")
        );
      } finally {
        this.isLoadingBases = false;
      }
    },

    refreshVariables() {
      this.currentConfig = {
        base: null,
        segments: [],
        regions: [],
        states: [],
      };
      this.regions = [];
      this.bases = [];
      this.configs = [];
      this.originalConfigs = [];
    },

    async getRegions(sourceId) {
      this.isLoadingRegions = true;
      try {
        const response = await this.service.getCompanyFilterOptions(
          this.companyId,
          "region",
          sourceId
        );
        const mappedResponse = response?.data?.map((region) => ({
          ...region,
          children: region.children.map((children) => ({
            ...children,
            text: getBrazilianStateData(children.name).name,
          })),
        }));
        this.regions = mappedResponse ?? [];
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_FETCH_REGIONS_ERROR"));
      } finally {
        this.isLoadingRegions = false;
      }
    },

    async getConfigs(sourceId) {
      if (this.verifyExistentConfig(sourceId)) this.fillCurrentConfig(sourceId);
      else this.callGetCompanyCustomFilters(sourceId);
    },
    verifyExistentConfig(sourceId) {
      return this.configs.some((config) => config.sourceId == sourceId);
    },
    fillCurrentConfig(sourceId, newData = null) {
      const config =
        newData ?? this.configs.find((config) => config.sourceId == sourceId);
      this.currentConfig.regions = config?.regions ?? [];
      this.currentConfig.segments = config?.segments ?? [];
      this.currentConfig.states = config?.states ?? [];
    },
    async callGetCompanyCustomFilters(sourceId) {
      this.isLoadingConfigs = true;
      try {
        const response = await this.service.getCompanyCustomFilters(
          this.companyId,
          sourceId,
          this.lang
        );
        this.handleConfigs(sourceId, response);
      } catch (error) {
        this.$handlerError(error, this.$i18n.t("TXT_SEARCH_FILTERS_ERROR"));
      } finally {
        this.isLoadingConfigs = false;
      }
    },
    handleConfigs(sourceId, response) {
      const body = {
        base: this.bases.find((base) => base.id == sourceId),
        sourceId,
        segments: [],
        regions: [],
        states: [],
      };

      if (response.status != 204) {
        const data = response?.data ?? [];

        const regions =
          data.find((item) => item.field == "Region")?.values ?? [];
        body.regions = regions;

        const segments =
          data.find((item) => item.field == "Segment")?.values ?? [];
        body.segments = segments;

        const states =
          data
            .find((item) => item.field == "UF")
            ?.values.map((state) => getBrazilianStateData(state).name) ?? [];
        body.states = states;

        this.fillCurrentConfig(sourceId, { regions, segments, states });
      }

      this.configs.push(structuredClone(body));
      this.originalConfigs.push(structuredClone(body));
    },

    updateConfigField(field, value) {
      const index = this.configs.findIndex(
        (config) => config.sourceId == this.currentConfig.base.id
      );
      if (index != -1) {
        this.configs[index][field] = value.map((item) => item.name ?? item);
      }
    },
    cancelEditions() {
      this.currentConfig = {
        base: null,
        segments: [],
        regions: [],
        states: [],
      };
      this.configs = structuredClone(this.originalConfigs);
    },
    confirmChanges() {
      this.currentConfig = {
        base: null,
        segments: [],
        regions: [],
        states: [],
      };
      this.originalConfigs = structuredClone(this.configs);
    },
    handleSelectBase() {
      this.currentConfig.segments = [];
      this.currentConfig.regions = [];
      this.currentConfig.states = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.add-value-btn {
  background-color: $feedback-color-success-pure;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-bottom: 1px;
  cursor: pointer;
}
.disabled {
  background-color: $neutral-color-high-medium;
  cursor: default;
}
</style>
