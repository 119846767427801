<template>
  <div class="item-box gap-2 ma-1">
    <span>{{ name }}</span>
    <div class="remove-item-box">
      <v-icon color="white" small @click="$emit('removeValue')"
        >mdi-close</v-icon
      >
    </div>
  </div>
</template>

<script>
export default {
  props: { name: { type: String, default: "-" } },
};
</script>

<style lang="scss" scoped>
.item-box {
  display: flex;
  border-radius: 100px;
  background-color: $neutral-color-high-medium;
  padding: 8px 8px 8px 12px;
}
.remove-item-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $brand-color-secondary-pure;
  border-radius: 50%;
  padding: 3px;
}
</style>
