import store from "@/store";
import i18n from "@/i18n";

export function copyText(text) {
  var textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
  setTimeout(() => {}, 1000);
  store.commit("snackbarV2/set", {
    message: i18n.t("TXT_COPY"),
    type: "info",
  });
}
