<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-top-transition"
    max-width="600"
    :persistent="isLoading"
  >
    <template v-slot:activator="{ on, attrs }">
      <Prd-btn
        v-bind="attrs"
        v-on="on"
        :title="$t('TXT_SAVE_CHANGES')"
        :disabled="notHasChanges"
      />
    </template>

    <v-card class="pa-4">
      <v-row no-gutters justify="center" class="mb-3">
        <v-icon :color="$prdStyles('color-primary')" size="50">
          mdi-information-outline
        </v-icon>
        <v-icon
          :color="$prdStyles('color-primary')"
          :disabled="isLoading"
          @click="isOpen = false"
          class="close-button"
        >
          mdi-close
        </v-icon>
      </v-row>

      <h2 class="text-center mb-6">{{ $t("TXT_CONFIRM_CHANGES") }}</h2>

      <div class="content">
        <Prd-tabs
          :configs="tabConfig"
          @changedTab="tabIndex = $event"
          class="mb-4"
        />
        <p>
          {{ $t("TXT_SEGMENTS") }}:
          <span class="font-weight-bold">{{ prepareArray(segments) }}</span>
        </p>

        <p>
          {{ $t("TXT_REGIONS") }}:
          <span class="font-weight-bold">{{ prepareArray(regions) }}</span>
        </p>

        <p class="ma-0">
          {{ $t("TXT_STATES") }}:
          <span class="font-weight-bold">{{ prepareArray(states) }}</span>
        </p>
      </div>
      <v-row no-gutters justify="end" class="mt-6 gap-4">
        <Prd-btn
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          :disabled="isLoading"
          @click="isOpen = false"
        />
        <Prd-btn
          :title="$t('TXT_CONFIRM')"
          :loading="isLoading"
          @click="saveChanges"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdTabs from "@/components/common/prd-tabs.vue";
import ConfigFiltersPredimonitorService from "@/service/predimonitor-v3/config-filters-predimonitor-service-v3";
import { getBrazilianStateData } from "@/views/main/predimonitor-v3/components/annoucement-map/utils/brazilian-states";
export default {
  props: {
    updatedConfigs: { type: Array, default: () => [] },
    notHasChanges: { type: Boolean, default: false },
    companyId: { type: Number, default: 0 },
  },

  components: {
    PrdBtn,
    PrdTabs,
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      tabIndex: 0,
      service: new ConfigFiltersPredimonitorService(),
    };
  },
  computed: {
    tabConfig() {
      return {
        title: this.$i18n.t("TXT_BASES"),
        centerActive: true,
        showArrows: true,
        backgroundColor: "#F5F5F5",
        tabs: this.updatedConfigs.map((config) => ({
          name: config.base.name,
        })),
      };
    },
    currentConfig() {
      return this.updatedConfigs[this.tabIndex];
    },
    regions() {
      return this.currentConfig?.regions ?? [];
    },
    segments() {
      return this.currentConfig?.segments ?? [];
    },
    states() {
      return this.currentConfig?.states ?? [];
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (!value) {
          this.tabIndex = 0;
        }
      },
      immediate: true,
    },
  },
  methods: {
    prepareArray(array) {
      return !array.length ? "-" : array.join(" , ");
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        const request = this.prepareRequest();
        await this.service.saveCompanyCrawlerFilters(request);
        this.$emit("confirmChanges");
        this.isOpen = false;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_UPDATE_SETTINGS_1"),
          type: "success",
        });
      } catch (error) {
        this.$handleError(
          error,
          this.$i18n.t("TXT_SAVE_CHANGES_CONFIG_BASES_ERROR")
        );
      } finally {
        this.isLoading = false;
      }
    },
    prepareRequest() {
      const list = [];

      this.updatedConfigs.forEach((config) => {
        const bodys = this.bodysToRequest(config);
        list.push(...bodys);
      });

      return list;
    },
    bodysToRequest(config) {
      const list = [];
      const keys = { regions: "Region", segments: "Segment", states: "UF" };
      const fields = Object.keys(keys);

      fields.forEach((field) => {
        const values =
          field == "states"
            ? config[field].map(
                (item) => getBrazilianStateData(item).abbreviation
              )
            : config[field] ?? [];
        const body = {
          companyId: this.companyId,
          sourceId: config.sourceId,
          field: keys[field],
          operator: "IN",
          values,
        };
        list.push(body);
      });

      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  position: absolute;
  right: 16px;
}
.content {
  background-color: $neutral-color-high-light;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
}
</style>
