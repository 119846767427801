import { render, staticRenderFns } from "./product-monitoring-dialog.vue?vue&type=template&id=951542ea&scoped=true"
import script from "./product-monitoring-dialog.vue?vue&type=script&lang=js"
export * from "./product-monitoring-dialog.vue?vue&type=script&lang=js"
import style0 from "./product-monitoring-dialog.vue?vue&type=style&index=0&id=951542ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "951542ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VIcon,VSpacer})
