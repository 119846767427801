<template>
  <v-skeleton-loader v-if="isLoading" type="table-thead, table-tbody" />
  <v-data-table
    v-else
    :headers="header"
    :items="items"
    id="competitiveness-index-table"
  >
    <template v-slot:[`item.latestPrice`]="{ item }">
      {{ formatMonetary(item.latestPrice) }}
    </template>
    <template v-slot:[`item.avgPrice`]="{ item }">
      {{ formatMonetary(item.avgPrice) }}
    </template>
    <template v-slot:[`item.competitivenessIndex`]="{ item }">
      <td
        :class="[
          'text-center',
          'font-weight-bold',
          'pa-0',
          setBackgroundColor(item.competitivenessIndex),
        ]"
      >
        {{ setCI(item.competitivenessIndex) }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      header: [
        {
          value: "name",
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
        },
        {
          value: "latestPrice",
          text: this.$i18n.t("TXT_CURRENT_PRICE"),
          align: "center",
        },
        {
          value: "avgPrice",
          text: this.$i18n.t("TXT_COMPETITOR_PRICE"),
          align: "center",
        },
        {
          value: "competitivenessIndex",
          text: this.$i18n.t("TXT_COMPETITIVENESS_INDEX"),
          align: "center",
        },
      ],
      items: [],
    };
  },

  methods: {
    formatMonetary,
    setBackgroundColor(ci) {
      if (ci <= 85 || ci > 115) return "red";
      else if (ci > 95 && ci <= 105) return "green";
      else return "yellow";
    },
    setCI(ci) {
      return `${ci?.toFixed(2) ?? "-"} %`;
    },
    fillItems(selectedProduct) {
      this.items = [];
      if (!selectedProduct)
        this.items = this.$store?.getters?.listOfProductsHaveLatestPrice ?? [];
      else this.items = [this.$store.getters.cIByProductName(selectedProduct)];
    },
  },
  mounted() {
    this.fillItems(null);
  },
};
</script>

<style lang="scss">
#competitiveness-index-table {
  .green {
    background-color: rgba(29, 133, 39, 0.25) !important;
  }
  .yellow {
    background-color: rgba(255, 200, 0, 0.5) !important;
  }
  .red {
    background-color: rgba(176, 0, 32, 0.25) !important;
  }
}
</style>
