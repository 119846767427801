<template>
  <v-row no-gutters justify="end" class="gap-4">
    <Prd-btn
      :outlined="true"
      :title="$t('TXT_CANCEL')"
      :disabled="notHasChanges"
      @click="$emit('cancelEditions')"
    />

    <Save-changes-modal
      :notHasChanges="notHasChanges"
      :crawlerList="crawlerList"
      :crawlerId="crawlerId"
      :companyId="companyId"
      :changedFilterList="changedFilterList"
      :customFilters="customFilters"
      @parOriginalCustomFilter="$emit('parOriginalCustomFilter')"
    />
  </v-row>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import SaveChangesModal from "./save-changes-modal.vue";

export default {
  props: {
    notHasChanges: { type: Boolean, default: false },
    crawlerList: { type: Array, default: () => [] },
    crawlerId: { type: Number, default: 0 },
    companyId: { type: Number, default: 0 },
    changedFilterList: { type: Array, default: () => [] },
    customFilters: { type: Array, default: () => [] },
  },
  components: {
    PrdBtn,
    SaveChangesModal,
  },
};
</script>
