import { render, staticRenderFns } from "./copilot-message-actions.vue?vue&type=template&id=1e86a612&scoped=true"
import script from "./copilot-message-actions.vue?vue&type=script&lang=js"
export * from "./copilot-message-actions.vue?vue&type=script&lang=js"
import style0 from "./copilot-message-actions.vue?vue&type=style&index=0&id=1e86a612&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e86a612",
  null
  
)

export default component.exports