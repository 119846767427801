<template>
  <v-expansion-panel class="standard-panel" v-bind="$attrs">
    <v-expansion-panel-header>
      <div class="mr-4">
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="auto">
            <v-row no-gutters>
              <h4>{{ $t(title).toUpperCase() }}</h4>
              <Prd-tooltip v-if="tooltipText" :text="tooltipText" />
            </v-row>
          </v-col>
          <v-col cols="auto">
            <slot name="header-right-actions"></slot>
          </v-col>
        </v-row>
        <v-row no-gutters :class="`${hasSecondLineSlot ? 'mt-4' : ''}`">
          <slot name="second-line"></slot>
        </v-row>
      </div>
      <template v-slot:actions>
        <v-icon color="#3c5ca7"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot name="content"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import PrdTooltip from "@/components/common/prd-tooltip.vue";
export default {
  name: "Standard-Expansive-Panel-Predimonitor",
  props: {
    title: { type: String, required: true },
    tooltipText: { type: String },
  },
  components: { PrdTooltip },
  computed: {
    hasSecondLineSlot() {
      return this.$slots["second-line"] != undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.standard-panel {
  width: 100%;
  margin-bottom: 24px;
  padding: 8px 0px 8px 0px;
}
</style>
