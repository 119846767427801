<template>
  <h4 v-if="showNotFoundMessage && !isLoading">
    {{ $t("TXT_NOT_FOUND_DATA_FOR_THIS_AGROUPMENT") }}
  </h4>
  <High-charts-main
    v-else
    :type="'spline'"
    :isLoading="isLoading"
    :personalizedOptions="personalizedOptions"
  />
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
import HighChartsMain from "../../common/high-charts-main.vue";
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      required: true,
    },
    isLoading: { type: Boolean, default: false },
  },
  components: {
    HighChartsMain,
  },
  data() {
    return {
      personalizedOptions: {
        chart: { height: "40%" },
        xAxis: {
          title: {
            text: this.$i18n.t("TXT_DAY_TIME"),
          },
          labels: {
            rotation: -45,
          },
        },
        yAxis: [
          {
            title: {
              text: this.$i18n.t("TXT_AVERAGE_PRICE"),
            },
            labels: {
              formatter: function () {
                return formatMonetary(this.value);
              },
            },
            crosshair: true,
            allowDecimals: false,
          },
          {
            title: {
              text: this.$i18n.t("TXT_ANNOUCEMENT"),
            },
            opposite: true,
            allowDecimals: false,
          },
        ],
        tooltip: {
          shared: true,
          formatter() {
            let tooltipHtml = `<b>${this.x}</b><br/>`;
            this.points.forEach((point) => {
              const valueText =
                point.y !== null ? `${formatMonetary(point.y)}` : "Nulo";
              tooltipHtml += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${valueText}</b><br/>`;
            });
            return tooltipHtml;
          },
        },
      },
    };
  },
  computed: {
    showNotFoundMessage() {
      return (
        !this.data.length ||
        this.data?.[0]?.prices?.every((item) => item == null)
      );
    },
  },
  watch: {
    data: {
      handler(newData) {
        this.fillChart(newData);
      },
    },
  },
  methods: {
    fillChart(newData) {
      const categories = this.dates;
      const series = this.processData(newData).map((item) => ({
        name: item.name,
        color: item.color,
        data: item.prices.map((point) => ({
          y: point.y,
          marker: {
            fillColor: point.isOriginal ? item.color : "white",
            lineColor: item.color,
            lineWidth: 1,
            radius: 4,
          },
        })),
        zoneAxis: "x",
        zones: [{ value: item.indexStartProjection }, { dashStyle: "dot" }],
      }));

      this.personalizedOptions = {
        ...this.personalizedOptions,
        xAxis: {
          ...this.personalizedOptions.xAxis,
          categories,
        },
        series,
      };
    },
    processData(data) {
      return data.map((item) => {
        let lastNonNullValue = null;
        let processedPrices = item.prices.map((price) => {
          if (price !== null) lastNonNullValue = price;

          return { y: price ?? lastNonNullValue, isOriginal: price !== null };
        });

        processedPrices.push(
          ...item.projection.map((price) => {
            return { y: price, isOriginal: true };
          })
        );

        return {
          name:
            this.activeTab == 3 ? this.$i18n.t("TXT_CONSOLIDATED") : item.name,
          prices: processedPrices,
          indexStartProjection: item.prices.length - 1,
        };
      });
    },
  },
};
</script>
