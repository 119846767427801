<template>
  <v-row no-gutters align="end" class="group-box">
    <v-col v-if="title" cols="auto" class="mr-2">
      <span>{{ title }} :</span>
    </v-col>
    <v-col cols="auto">
      <v-row no-gutters v-if="isLoading" class="gap-4">
        <v-skeleton-loader
          type="button"
          height="20"
          v-for="(item, i) in loaderLength"
          :key="i"
        />
      </v-row>
      <v-tabs
        v-else
        :background-color="backgroundColor"
        color="black"
        :slider-color="$prdStyles('color-secundary')"
        height="25px"
        v-model="tab"
        :center-active="centerActive"
        :show-arrows="showArrows"
      >
        <v-tab v-for="(tab, index) in tabs" :key="index">
          <v-row no-gutters align="center" justify="center">
            <span class="capitalize">{{ tab.name }}</span>
            <Prd-tooltip v-if="tab.tooltip" :text="tab.tooltip" />
          </v-row>
        </v-tab>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import PrdTooltip from "@/components/common/prd-tooltip.vue";
export default {
  props: {
    configs: { type: Object, required: true },
    isLoading: { type: Boolean, default: false },
  },
  components: {
    PrdTooltip,
  },
  data() {
    return { tab: 0 };
  },
  computed: {
    title() {
      return this.configs?.title;
    },
    loaderLength() {
      return this.configs?.tabs?.length ?? 0;
    },
    backgroundColor() {
      return this.configs?.backgroundColor ?? "white";
    },
    centerActive() {
      return this.configs?.centerActive ?? false;
    },
    showArrows() {
      return this.configs?.showArrows ?? false;
    },
    tabs() {
      return this.configs?.tabs ?? [];
    },
  },
  watch: {
    tab: {
      handler(value) {
        this.$emit("changedTab", value);
      },
    },
    immediate: true,
  },
};
</script>

<style lang="scss" scoped>
.group-box {
  font-size: 14px;
  font-weight: 500;
}
.capitalize {
  text-transform: capitalize;
}
.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}
</style>
