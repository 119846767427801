import { render, staticRenderFns } from "./confirm-ticket-modal.vue?vue&type=template&id=810d56d4&scoped=true"
import script from "./confirm-ticket-modal.vue?vue&type=script&lang=js"
export * from "./confirm-ticket-modal.vue?vue&type=script&lang=js"
import style0 from "./confirm-ticket-modal.vue?vue&type=style&index=0&id=810d56d4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "810d56d4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VDialog,VIcon})
