<template>
  <v-row no-gutters class="mb-6">
    <v-col cols="3">
      <Prd-combo-box
        v-model="selectedFilter"
        :title="$t('TXT_FILTER_2')"
        :items="items"
        :disabled="!hasSelectedCrawler || isLoading || externalLoading"
        :loading="isLoading || externalLoading"
      />
    </v-col>
  </v-row>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import ConfigFiltersPredimonitorService from "@/service/predimonitor-v3/config-filters-predimonitor-service-v3";
export default {
  props: {
    crawler: { type: Object, default: null },
    externalLoading: { type: Boolean, default: false },
  },
  components: {
    PrdComboBox,
  },

  data() {
    return {
      isLoading: false,
      service: new ConfigFiltersPredimonitorService(),
      items: [],
      selectedFilter: null,
    };
  },
  computed: {
    lang() {
      const langByLocalStorage = localStorage.getItem("lang");
      const lang =
        langByLocalStorage != "undefined" ? langByLocalStorage : "pt-BR";
      return lang;
    },
    hasSelectedCrawler() {
      return this.crawler != null ?? true;
    },
  },
  watch: {
    selectedFilter: {
      handler(value) {
        this.$emit("update:filter", value);
      },
      immediate: true,
      deep: false,
    },
  },
  methods: {
    async getFilterFields() {
      this.isLoading = true;
      try {
        const response = await this.service.getFilterFields(this.lang);
        this.items = this.prepareResponse(response);
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_FETCH_TYPE_FILTERS_ERROR"));
      } finally {
        this.isLoading = false;
      }
    },
    prepareResponse(response) {
      if (!response?.data) return [];

      const simpleFilters = [
        {
          value: "Region",
          text: this.$i18n.t("TXT_REGION"),
          model: "simple",
        },
        {
          value: "Segment",
          text: this.$i18n.t("TXT_SEGMENT"),
          model: "simple",
        },
      ];

      const customFilters = response?.data?.map((filter) => {
        return { ...filter, model: "custom" };
      });

      const list = [...simpleFilters, ...customFilters];

      list.sort((a, b) => a.text.localeCompare(b.text));
      return list;
    },
    resetValues() {
      this.selectedFilter = null;
    },
  },
  mounted() {
    this.getFilterFields();
  },
};
</script>
