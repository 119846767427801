<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
    :loading="isLoading"
  >
    <template v-slot:item.actions="{ item }">
      <v-row no-gutters class="gap-2">
        <v-icon
          @click="$emit('edit', item)"
          :color="$prdStyles('color-primary')"
          >mdi-pencil-box</v-icon
        >
        <v-icon
          @click="$emit('remove', item)"
          :color="$prdStyles('color-warning')"
          >mdi-trash-can</v-icon
        >
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    isLoading: { type: Boolean, default: false },
    items: { tye: Array, default: () => [] },
  },
  data() {
    return {
      headers: [
        {
          text: "Filtro",
          align: "center",
          value: "text",
        },
        {
          text: "Operador",
          align: "center",
          value: "operator",
        },
        {
          text: "Valores",
          align: "center",
          value: "values",
        },
        {
          text: "",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
