<template>
  <div class="mt-4">
    <v-row no-gutters class="mb-4">
      <v-col cols="12" md="2" class="mr-4">
        <Prd-combo-box
          v-model="model.TypeMode"
          :items="modeItems"
          :title="$t('TXT_MODE')"
        />
      </v-col>
      <v-col cols="12" md="3" class="mr-4">
        <Prd-combo-box
          v-model="model.TypeReference"
          :items="referenceItems"
          :title="$t('TXT_REFERENCE')"
        />
      </v-col>
      <v-col cols="12" md="2" class="mr-4">
        <Prd-text-field
          v-model="model.Margin"
          :title="$t('TXT_RANGE')"
          prefix="%"
          type="number"
          hide-spin-buttons
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-4">
      <v-checkbox
        v-model="model.AdjustMargin"
        :label="$t('TXT_ADJUSTABLE_TO_RANGE')"
        dense
        hide-details
        class="ma-0 pa-0 mr-4"
      ></v-checkbox>

      <v-checkbox
        v-model="model.BlockToLastPrice"
        :disabled="model.AdjustMargin"
        :indeterminate="model.AdjustMargin"
        :label="$t('TXT_RETURN_TO_LAST_PRICE_REPROVATION')"
        dense
        hide-details
        class="ma-0 pa-0"
      ></v-checkbox>
    </v-row>

    <v-row no-gutters align="start">
      <v-checkbox
        v-model="model.BlockIfNull"
        :label="$t('TXT_BLOCK_NO_MARKET_PRICE')"
        dense
        hide-details
        class="ma-0 pa-0 mr-4"
      ></v-checkbox>
      <Prd-text-field
        v-model="model.MinBlockMarket"
        :title="$t('TXT_MIN_VALUE_TO_BLOCK')"
        :disabled="!model.BlockIfNull"
        :filled="!model.BlockIfNull"
        type="number"
        hide-spin-buttons
      />
    </v-row>

    <v-row no-gutters align="end">
      <v-col cols="auto">
        <Prd-autocomplete-chip
          :originalValue="model.competitors"
          :title="$t('TXT_COMPETITOR') + ':'"
          :items="sellerList"
          :itemText="'value'"
          :externalDisabled="showAlert || sellerListLoading"
          :isLoading="sellerListLoading"
          @updates="model.competitors = $event"
        />
      </v-col>
      <v-col cols="auto" class="pb-2">
        <Prd-tooltip-informations
          v-if="showAlert && !sellerListLoading"
          class="mt-4"
          :icon="'mdi-alert'"
          :sizeIcon="25"
          :colorIcon="'warning'"
          :text="$t('TXT_INFO_COMPETITORS')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdAutocompleteChip from "@/components/common/prd-autocomplete-chip.vue";
import PrdTooltipInformations from "@/components/common/prd-tooltip.vue";
export default {
  props: ["values"],
  components: {
    PrdComboBox,
    PrdTextField,
    PrdAutocompleteChip,
    PrdTooltipInformations,
  },
  data() {
    return {
      modeItems: [
        {
          text: this.$i18n.t("TXT_RANGE"),
          value: 0,
        },
        {
          text: this.$i18n.t("TXT_BIGGER_THAN"),
          value: 1,
        },
        {
          text: this.$i18n.t("TXT_LESS_THAN"),
          value: 2,
        },
      ],

      referenceItems: [
        {
          text: this.$i18n.t("TXT_LOWER_PRICE_COMPETITOR"),
          value: 0,
        },
        {
          text: this.$i18n.t("TXT_AVAREGE_PRICE_COMPETITOR"),
          value: 1,
        },
        {
          text: this.$i18n.t("TXT_HIGHER_PRICE_COMPETITOR"),
          value: 2,
        },
        {
          text: `${this.$i18n.t("TXT_SECOND")}  ${this.$i18n.t(
            "TXT_HIGHER_PRICE_COMPETITOR"
          )}`,
          value: 3,
        },
        {
          text: `${this.$i18n.t("TXT_SECOND")} ${this.$i18n.t(
            "TXT_LOWER_PRICE_COMPETITOR"
          )}`,
          value: 4,
        },
      ],

      model: {
        TypeMode: null,
        TypeReference: null,
        Margin: 0,
        MinBlockMarket: null,
        AdjustMargin: false,
        BlockToLastPrice: false,
        BlockIfNull: false,
        competitors: [],
      },
    };
  },

  computed: {
    sellerList() {
      return this.$store?.state?.pricingRuleV2.sellerList ?? [];
    },

    showAlert() {
      return !this.sellerList || !this.sellerList.length;
    },

    sellerListLoading() {
      return this.$store?.state?.pricingRuleV2?.sellerListLoading ?? false;
    },
  },

  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
  },

  methods: {
    handlerBehavior(inputs) {
      if (inputs.AdjustMargin) this.model.BlockToLastPrice = false;
      if (!inputs.BlockIfNull) this.model.MinBlockMarket = null;
    },

    updateConditions(inputs) {
      this.handlerBehavior(inputs);
      let formatedValue = [
        {
          stringValue: "TypeMode",
          decimalValue: inputs?.TypeMode?.value ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "TypeReference",
          decimalValue: inputs?.TypeReference?.value ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "Margin",
          decimalValue: inputs.Margin ? parseFloat(inputs.Margin) : 0,
          booleanValue: false,
        },
        {
          stringValue: "MinBlockMarket",
          decimalValue: inputs.MinBlockMarket
            ? parseFloat(inputs.MinBlockMarket)
            : 0,
          booleanValue: false,
        },
        {
          stringValue: "AdjustMargin",
          booleanValue: inputs.AdjustMargin,
          decimalValue: 0,
        },
        {
          stringValue: "BlockToLastPrice",
          booleanValue: inputs.BlockToLastPrice,
          decimalValue: 0,
        },
        {
          stringValue: "BlockIfNull",
          booleanValue: inputs.BlockIfNull,
          decimalValue: 0,
        },
      ];

      const competitors = this.prepareCompetitorsForUpdateConditions(inputs);
      formatedValue.push(...competitors);

      this.$emit("updateConditionValues", { formatedValue, conditionType: 1 });
    },

    prepareCompetitorsForUpdateConditions(inputs) {
      const competitors = structuredClone(inputs.competitors);
      return competitors.map((comp) => ({
        stringValue: comp.key,
        decimalValue: 0,
        booleanValue: false,
      }));
    },

    setInputs(conditionList) {
      const TypeMode = conditionList.find(
        (condition) => condition.stringValue == "TypeMode"
      ).decimalValue;
      const TypeModeItem = this.modeItems.find(
        (item) => item.value == TypeMode
      );
      this.model.TypeMode = TypeModeItem;

      const TypeReference = conditionList.find(
        (condition) => condition.stringValue == "TypeReference"
      ).decimalValue;
      const TypeReferenceItem = this.referenceItems.find(
        (item) => item.value == TypeReference
      );
      this.model.TypeReference = TypeReferenceItem;

      const Margin = conditionList.find(
        (condition) => condition.stringValue == "Margin"
      ).decimalValue;
      this.model.Margin = Margin;

      const MinBlockMarket = conditionList.find(
        (condition) => condition.stringValue == "MinBlockMarket"
      ).decimalValue;
      this.model.MinBlockMarket = MinBlockMarket;

      const AdjustMargin = conditionList.find(
        (condition) => condition.stringValue == "AdjustMargin"
      ).booleanValue;
      this.model.AdjustMargin = AdjustMargin;

      const BlockToLastPrice = conditionList.find(
        (condition) => condition.stringValue == "BlockToLastPrice"
      ).booleanValue;
      this.model.BlockToLastPrice = BlockToLastPrice;

      const BlockIfNull = conditionList.find(
        (condition) => condition.stringValue == "BlockIfNull"
      ).booleanValue;
      this.model.BlockIfNull = BlockIfNull;

      this.model.competitors = this.setCompetitors(conditionList);
    },

    setCompetitors(conditionList) {
      const filteredCondition = conditionList.filter((condition) =>
        condition.stringValue.includes("SELLERNAME")
      );
      const cloneArray = structuredClone(filteredCondition);
      const competitors = cloneArray.reduce((acc, comp) => {
        const foundedItem = this.sellerList.find(
          (compItemList) => compItemList.key === comp.stringValue
        );
        if (foundedItem) {
          acc.push(foundedItem);
        }
        return acc;
      }, []);
      return competitors;
    },
  },

  created() {
    if (this.values.length > 0) this.setInputs(this.values);
  },
};
</script>
