<template>
  <Expansive-panel
    :title="'TXT_PRICE_TRENDS'"
    :tooltipText="$t('TXT_PRICE_TRENDS_TOOLTIP')"
    :disabled="isDisabled"
  >
    <template v-slot:content>
      <Prd-tabs
        :configs="tabConfigs"
        :isLoading="isLoading"
        @changedTab="activeTab = $event"
      />
      <Chart-main
        :data="filteredData"
        :dates="dates"
        :activeTab="activeTab"
        :isLoading="isLoading"
        class="mt-4"
      />
    </template>
  </Expansive-panel>
</template>

<script>
import ExpansivePanel from "../common/expansive-panel.vue";
import ChartMain from "./components/chart-main.vue";
import PrdTabs from "@/components/common/prd-tabs.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";

export default {
  props: {
    isOpen: { type: Boolean, default: false },
  },
  components: {
    ExpansivePanel,
    ChartMain,
    PrdTabs,
  },
  data() {
    return {
      service: new PredimonitorService(),
      isDisabled: true,
      isLoading: false,
      activeTab: 0,
      tabConfigs: {
        title: this.$i18n.t("TXT_GROUP_BY"),
        centerActive: true,
        showArrows: true,
        tabs: [
          {
            name: this.$t("TXT_PRODUCT"),
            tooltip: this.$t("TXT_TEMPORAL_TOOLTIP_PRODUCT"),
          },
          {
            name: this.$t("TXT_ORIGIN"),
            tooltip: this.$t("TXT_TEMPORAL_TOOLTIP_ORIGIN"),
          },
          {
            name: this.$t("TXT_NETWORK"),
            tooltip: this.$t("TXT_TEMPORAL_TOOLTIP_RETAILER"),
          },
          {
            name: this.$t("TXT_CONSOLIDATED"),
            tooltip: this.$t("TXT_TEMPORAL_TOOLTIP_CONSOLIDATED"),
          },
        ],
      },
      rawData: null,
    };
  },
  watch: {
    lastRequest: {
      handler(value) {
        if (!value) return;
        this.rawData = null;
        this.isDisabled = false;
        this.fetchInitialData();
      },
      deep: true,
    },
    refreshComponentsFlag() {
      this.rawData = null;
      this.fetchInitialData();
    },
    isOpen() {
      this.fetchInitialData();
    },
  },
  computed: {
    lastRequest() {
      return this.$store.state.PredimonitorStore.lastRequest;
    },
    refreshComponentsFlag() {
      return this.$store.state.PredimonitorStore.refreshComponentsFlag;
    },
    dates() {
      return this.rawData?.dates ?? [];
    },
    filteredData() {
      const tabKeys = ["product", "origin", "network", "consolidated"];

      const tabKey = tabKeys[this.activeTab ?? 0];

      return this.rawData?.[tabKey] ?? [];
    },
  },
  methods: {
    async fetchInitialData() {
      if (!this.isOpen || this.rawData != null || this.isLoading) return;
      this.isLoading = true;
      const requestId = await this.$store.dispatch("startRequest", {
        section: "Tendência de Preços",
        name: "searchPriceTrend",
        payload: this.lastRequest,
      });
      try {
        const response = await this.service.searchPriceTrend(this.lastRequest);
        response.data.consolidated = [response?.data?.consolidated ?? []];
        this.rawData = response?.data ?? null;
        this.$store.commit("endRequest", { id: requestId, response });
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(error, this.$i18n.t("TXT_ERROR_PRICE_TRENDS"));
      } finally {
        this.isLoading = false;
      }
    },
    reStartComponent() {
      this.isDisabled = true;
      this.isLoading = false;
      this.activeTab = 0;
      this.rawData = null;
    },
  },
};
</script>
