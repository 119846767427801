<template>
  <v-container fluid>
    <Title
      :title="'PREDIMONITOR (BETA)'"
      :breadCrumbs="[{ text: 'Predimonitor' }]"
      class="mb-8"
    />

    <Search :ref="refs[0]" />

    <v-row no-gutters justify="space-between" align="center" class="my-6">
      <p class="font-weight-bold">{{ $t("TXT_RESULTS_UPPERCASE") }}</p>
      <router-link to="/main/tags-config">
        <Prd-btn :title="$t('TXT_TAG_CONFIGURATION')" :outlined="true" />
      </router-link>
    </v-row>

    <v-expansion-panels v-model="panels" multiple>
      <Product-table :ref="refs[1]" :isOpen="thisPanelIsOpen(0)" />
      <Price-trends :ref="refs[2]" :isOpen="thisPanelIsOpen(1)" />
      <Competitiveness-index :ref="refs[3]" :isOpen="thisPanelIsOpen(2)" />
      <Product-comparision :ref="refs[4]" :isOpen="thisPanelIsOpen(3)" />
      <Announcement-map :ref="refs[5]" :isOpen="thisPanelIsOpen(4)" />
    </v-expansion-panels>
    <Request-viewer-modal :ref="refs[6]" />
  </v-container>
</template>

<script>
import Title from "@/components/common/title.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import Search from "./components/search/search-main.vue";
import ProductTable from "./components/product-table/product-table-main.vue";
import PriceTrends from "./components/price-trends/price-trends-main.vue";
import CompetitivenessIndex from "./components/competitiveness-index/competitiveness-index-main.vue";
import ProductComparision from "./components/product-comparision/product-comparision-main.vue";
import AnnouncementMap from "./components/annoucement-map/announcement-map-main.vue";
import RequestViewerModal from "./components/request-viewer-modal.vue";

export default {
  components: {
    Title,
    PrdBtn,
    Search,
    ProductTable,
    PriceTrends,
    CompetitivenessIndex,
    ProductComparision,
    AnnouncementMap,
    RequestViewerModal,
  },
  data() {
    return {
      panels: [],
      refs: [
        "search",
        "productTable",
        "priceTrends",
        "competitivenessIndex",
        "productComparision",
        "announcementMap",
        "modal",
      ],
    };
  },
  computed: {
    isCostPredimonitor() {
      return this.$route.query.type?.includes("cost") ?? false;
    },
  },
  watch: {
    isCostPredimonitor() {
      this.refs.forEach((ref) => {
        if (this.$refs?.[ref]) this.$refs[ref].reStartComponent();
      });
      this.panels = [];
    },
  },
  methods: {
    handleKeyPress(event) {
      if (event.ctrlKey && event.key === "i") this.$refs.modal.open();
    },
    initPanels() {
      this.panels = [0];
      setTimeout(() => {
        this.panels = [];
      }, 1);
    },
    thisPanelIsOpen(index) {
      return this.panels.some((panel) => panel == index);
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
    this.initPanels();
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>
