import { render, staticRenderFns } from "./premissas-input.vue?vue&type=template&id=74812478&scoped=true"
import script from "./premissas-input.vue?vue&type=script&lang=js"
export * from "./premissas-input.vue?vue&type=script&lang=js"
import style0 from "./premissas-input.vue?vue&type=style&index=0&id=74812478&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74812478",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCol,VRow,VTextField})
