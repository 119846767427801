<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <Prd-btn
        :outlined="true"
        :title="$t('TXT_SEE_DISREGARD')"
        :disabled="externalDisable"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-card class="pa-4">
      <v-row no-gutters justify="space-between">
        <h4>{{ $t("TXT_DISREGARD_PRODUCTS") }}</h4>
        <v-icon
          :color="$prdStyles('color-primary')"
          :disabled="isLoading || disableCheckbox"
          @click="isOpen = false"
        >
          mdi-close
        </v-icon>
      </v-row>
      <Table
        :isDisregardedType="false"
        :isLoading="isLoading"
        :disableCheckbox="disableCheckbox"
        :items="items"
        class="my-4"
      />
      <v-row no-gutters justify="end" class="gap-4">
        <Prd-btn
          v-if="hasChanges"
          :disabled="disableCheckbox"
          :outlined="true"
          :title="$t('TXT_CANCEL')"
          @click="isOpen = false"
        />
        <Save-changes
          :isDisregardedType="false"
          :list="items"
          @refreshList="refreshList"
          @disableCheckbox="disableCheckbox = $event"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import Table from "./table-main.vue";
import SaveChanges from "./save-changes.vue";
import PredimonitorService from "@/service/predimonitor-v3/predimonitor-service-v3";

export default {
  props: {
    productIds: { type: Array, default: () => [] },
    externalDisable: { type: Boolean, default: false },
  },
  components: {
    PrdBtn,
    Table,
    SaveChanges,
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      disableCheckbox: false,
      service: new PredimonitorService(),
      items: [],
      isInitialCall: true,
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    hasChanges() {
      return this.items.filter((item) => item.disregarded == false)?.length > 0;
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (value) this.getDisregardedList();
        else this.cancelChanges();
      },
    },
  },
  methods: {
    async getDisregardedList() {
      if (!this.isInitialCall) return;

      const payload = {
        companyid: this.idCompany,
        productIds: this.productIds,
      };

      const requestId = await this.$store.dispatch("startRequest", {
        section: "Produtos",
        name: "Produtos Desconsiderados",
        payload,
      });

      try {
        this.isLoading = true;
        const response = await this.service.getDisregardedList(payload);

        this.items = response.data.map((item) => ({
          ...item,
          disregarded: true,
        }));
        this.isInitialCall = false;
        this.$store.commit("endRequest", { id: requestId, response });
      } catch (error) {
        this.$store.commit("endRequest", { id: requestId, response: error });
        this.$handleError(
          error,
          this.$i18n.t("TXT_FETCH_DISREGARDED_PRICES_ERROR")
        );
      } finally {
        this.isLoading = false;
      }
    },
    cancelChanges() {
      this.items = this.items.map((item) => ({ ...item, disregarded: true }));
    },
    refreshList() {
      this.items = [];
      this.isInitialCall = true;
      this.isOpen = false;
      this.$emit("refreshList");
    },
    reStartIsInitialCall() {
      this.isInitialCall = true;
    },
  },
};
</script>
